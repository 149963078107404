import React, { Component } from 'react';
import AppServices from '../service/AppServices';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { ProgressSpinner } from 'primereact/progressspinner';
import { SplitButton } from 'primereact/splitbutton';
import { Panel } from 'primereact/panel';
import { Ripple } from 'primereact/ripple';
import { InputNumber } from 'primereact/inputnumber';
import { Toolbar } from 'primereact/toolbar';
import { Dialog } from 'primereact/dialog';
import { Row } from 'primereact/row';
import { ColumnGroup } from 'primereact/columngroup';

export default class FormNote extends Component {
    constructor() {
        super();
        this.state = {
            liste_data: [],
            liste_note: [],
            liste_ecole: [],
            liste_classe: [],
            liste_matiere: [],
            liste_selected: [],
            liste_insc: [],
            selectedData: null,
            selectedInsc: null,
            selectedNote: null,
            liste_crd: [],
            pidClasse: null,
            pidCrd: null,
            pidEcole: null,
            pidMatiere: null,
            liste_item_export: [
                {
                    label: 'Exporter en CVS',
                    icon: 'pi pi-download',
                    command: (e) => {
                        this.exportCSV();
                    }
                },
                {
                    label: 'Exporter en XLS',
                    icon: 'pi pi-file-excel',
                    command: (e) => {
                        this.exportExcel();
                    }
                },
                {
                    label: 'Exporter en PDF',
                    icon: 'pi pi-file-pdf',
                    command: (e) => {
                        this.exportPdf();
                    }
                },
            ],
            moy: 0,
            ////////////////////////////
            showindicator: false, // pour afficher l'état de chargement
            submit: false // pour indiquer si le bouton Valider a été cliqueé et vérifier les champs obligatoires qui seront surlignés en rouge
            ////////////////////////////
        };
        this.toogleDlg = this.toogleDlg.bind(this);
        this.itemTemplate = this.itemTemplate.bind(this);
        this.appservice = new AppServices();
    }

    componentDidMount() {
        this.appservice.get_liste_commune().then(data => {
            if (data != null && data != undefined) {
                let liste = data.map(x => {
                    return { value: x.id, label: x.libelle, prefecture: x.prefectureId.libelle }
                });
                this.setState({ liste_crd: liste });
                if (this.appservice.get_userconnected().profil == 'Gestionnaire') {
                    this.setState({ pidCrd: 'Tous' }, () => {
                        this.get_liste_ecole();
                    })
                }
            }
        });
        this.get_liste_classe();
    }

    get_liste_data() {
        this.setState({ showindicator: true });
        this.appservice.get_liste_note(this.state.pidClasse, this.state.pidMatiere, this.state.pidEcole).then(data => this.setState({ liste_data: data, showindicator: false }));
    }

    get_liste_inscription() {
        this.setState({ showindicator: true });
        this.appservice.get_liste_inscription(this.state.pidCrd == null ? 'Tous' : this.state.pidCrd, this.state.pidEcole == null ? 'Tous' : this.state.pidEcole, this.state.pidClasse == null ? 'Tous' : this.state.pidClasse).then(data => this.setState({ liste_insc: data, showindicator: false }));
    }



    get_liste_note() {
        this.setState({ showindicator: true });
        this.appservice.get_liste_note_eleve(this.state.selectedInsc.id).then(data => this.setState({ liste_note: data, showindicator: false }, () => {
            let moy = this.appservice.calculer_moyenne(data);
            this.setState({ moy: moy });
        }));
    }

    //Liste des écoles
    get_liste_ecole() {
        this.setState({ showindicator: true, liste_ecole: [] });
        this.appservice.get_liste_ecole(this.state.pidCrd).then(data => {
            this.setState({ showindicator: false });
            if (data != undefined) {
                if (data.length > 0) {
                    let liste = data.map(x => {
                        return { value: x.id, label: x.nom }
                    });
                    this.setState({ liste_ecole: liste });
                    if (this.appservice.get_userconnected().profil == 'Gestionnaire') {
                        this.setState({ pidEcole: liste[0].value });
                    }
                }
            }
        });
    }

    actionTemplate(rowData, props) {
        return <Button label='Attestation' icon="pi pi-print" onClick={(e) => {
            this.setState({ showindicator: true, selectedInsc: rowData });
            this.appservice.get_liste_note_eleve(rowData.id).then(data => this.setState({ liste_note: data, showindicator: false }, () => {
                let moy = this.appservice.calculer_moyenne(data);
                this.setState({ moy: moy });
                if (moy >= 10) {
                    this.appservice.generer_attestation(rowData, this.state.liste_note);
                } else {
                    this.toast.show({ severity: 'warn', summary: 'Attention', detail: "Cet élève n'est pas admis " });
                }
            }));
        }} style={{ width: 130 }} />
    }

    /* Zone gauche de la toolbar */
    leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <Button label="Saisie notes" icon="pi pi-plus" className="p-button-success mr-2" onClick={this.toogleDlg} style={{ width: 125 }} />
                </div>
            </React.Fragment>
        )

    }

    /* Zone droite de la toolbar */
    rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : <Button label="Export" icon="pi pi-upload" className="p-button-help" onClick={this.exportCSV} />}
            </React.Fragment>
        )
    }
    //Liste des classes
    get_liste_classe() {
        this.setState({ showindicator: true });
        this.appservice.get_liste_classe().then(data => {
            this.setState({ showindicator: false });
            if (data != undefined) {
                if (data.length > 0) {
                    let liste = data.map(x => {
                        return { value: x.id, label: x.libelle + ' ' + x.option_classe + ' ' + x.enseignement }
                    });
                    this.setState({ liste_classe: liste });
                }
            }
        });
    }

    /* Affichage de la boite de dialogue pour l'ajout d'un nouveau quartier */
    toogleDlg() {
        this.annuler();
        this.setState({ visible_dlg_form: !this.state.visible_dlg_form });
    }

    //Liste des classes
    get_liste_matiere() {
        this.setState({ showindicator: true });
        this.appservice.get_liste_matieres(this.state.pidClasse).then(data => {
            this.setState({ showindicator: false, liste_matiere: [] });
            if (data != undefined) {
                if (data.length > 0) {
                    let liste = data.map(x => {
                        return { value: x.id, label: x.libelle }
                    });
                    this.setState({ liste_matiere: liste });
                }
            }
        });
    }

    /* Annuler l'opération en cours et vider le formulaire */
    annuler() {
        this.setState({ showindicator: false });
        this.setState({
            pidMatiere: null,
            liste_selected: [],
            liste_data: [],
        });
    }


    /* Enregistrer les informations du formulaire */
    enreg_data() {
        if (this.state.liste_data.length <= 0) {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez choisir la classe et saisir les moyennes' });
        } else {
            let liste = this.state.liste_data;
            for (var i = liste.length; i--;) {
                liste[i].modif = 1;
            }
            this.setState({ showindicator: true });
            this.appservice.update_note_bulk(liste).then(reponse => {
                this.setState({ showindicator: false });
                if (reponse != undefined && reponse != null) {
                    this.resultat(reponse);
                }
            });
        }
    }

    /* Fonction d'affichage des messages retournés suite aux actions serveurs */
    resultat(rep) {
        if (rep.success == true) {
            this.toast.show({ severity: 'success', summary: 'Success', detail: rep.message });
            this.annuler();
        } else {
            this.toast.show({ severity: 'error', summary: 'Error', detail: rep.message });
        }
        this.setState({ submit: false, showindicator: false });
    }


    // Permet d'afficher la liste des salariés sous forme de tableau lors de la sélection dans le formulaire
    itemTemplate(option) {
        if (!option.value) {
            return option.label;
        }
        else {
            return (
                <div className="grid" style={{ padding: 10 }}>
                    <div className="col-8">
                        <label>{option.label}</label>
                    </div>
                    <div className="col-4">
                        <label>{option.ecole}</label>
                    </div>
                </div>
            );
        }
    }


    /* Exportation du tableau contenant la liste des inscriptions en fichier CSV */
    exportCSV = () => {
        //ref={(el) => { this.dt = el; }}
        this.dt.exportCSV("Liste_moyenne");
    }

    /* Exportation du tableau  en fichier PDF */
    exportPdf = () => {
        const cols = [
            { field: 'MATRICULE', header: 'MATRICULE' },
            { field: 'NOM', header: 'NOM' },
            { field: 'PRENOM', header: 'PRENOM' },
            { field: 'CLASSE', header: 'CLASSE' },
            { field: 'MATIERE', header: 'MATIERE' },
            { field: 'MOYENNE', header: 'MOYENNE' },
        ];
        let liste_data = this.state.liste_data.length > 0 ? this.state.liste_data.map(x => {
            return { 'MATRICULE': x.eleveId.matricule, 'NOM': x.eleveId.nom, 'PRENOM': x.eleveId.prenom, 'MATIERE': x.matiereId.libelle, 'MOYENNE': x.moy }
        }) : [];
        const exportColumns = cols.map(col => ({ title: col.header, dataKey: col.field }));
        import('jspdf').then(jsPDF => {
            import('jspdf-autotable').then(() => {
                var doc = new jsPDF.default(0, 0);
                doc.autoTable(exportColumns, liste_data);
                doc.save('LISTE_MOYENNE.pdf');
            })
        })
    }

    onCellEditComplete(e) {
        let { rowData, newValue, field, originalEvent: event } = e;
        let val = newValue + '';
        if (val.length > 0) {
            rowData[field] = newValue;
        } else {
            event.preventDefault();
        }
    }

    moyEditor(options) {
        return <InputNumber min={0} max={20} minFractionDigits={2} maxFractionDigits={2} value={options.value} keyfilter="pnum" onChange={(e) => options.editorCallback(e.value)} />
    }

    /* Exportation du tableau  en fichier XLSX */
    exportExcel = () => {
        let liste_data = this.state.liste_data.length > 0 ? this.state.liste_data.map(x => {
            return { 'MATRICULE': x.eleveId.matricule, 'NOM': x.eleveId.nom, 'PRENOM': x.eleveId.prenom, 'CLASSE': x.classeId.libelle, 'MATIERE': x.matiereId.libelle, 'MOYENNE': x.moy }
        }) : [];
        import('xlsx').then(xlsx => {
            const worksheet = xlsx.utils.json_to_sheet(liste_data);
            const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
            const excelBuffer = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
            this.saveAsExcelFile(excelBuffer, 'LISTE_MOYENNE_');
        });
    }

    /* Enregistrer le fichier sur le client */
    saveAsExcelFile = (buffer, fileName) => {
        import('file-saver').then(module => {
            if (module && module.default) {
                let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                let EXCEL_EXTENSION = '.xlsx';
                const data = new Blob([buffer], {
                    type: EXCEL_TYPE
                });

                module.default.saveAs(data, fileName + new Date().getTime() + EXCEL_EXTENSION);
            }
        });
    }

    render() {
        const template = (options) => {
            const toggleIcon = options.collapsed ? 'pi pi-filter-fill' : 'pi pi-chevron-up';
            const className = `${options.className} justify-content-start`;
            const titleClassName = `${options.titleClassName} pl-1`;

            return (
                <a onClick={options.onTogglerClick} style={{ cursor: 'pointer' }}>
                    <div className={className}>
                        <button className={options.togglerClassName} onClick={options.onTogglerClick}>
                            <span className={toggleIcon}></span>
                            <Ripple />
                        </button>
                        <span className={titleClassName}>
                            Filtre avancé
                        </span>

                    </div>
                </a>
            )
        }

        let footerGroup = <ColumnGroup>
            <Row>
                <Column footer="MOYENNE" colSpan={3} footerStyle={{ textAlign: 'center', fontWeight: 'bold' }} />
                <Column footer={this.state.moy} style={{ color: this.state.moy < 10 ? 'red' : '#000000', fontWeight: 'bold' }} />
            </Row>
        </ColumnGroup>;

        return (
            <div className="card p-fluid col-12" style={{ height: 1200 }}>
                <Toast ref={(el) => this.toast = el} />

                {/* Toolbar contenant les boutons d'ajout, de suppression et d'exportation */}
                <Toolbar className="mb-4" left={this.leftToolbarTemplate} right={this.rightToolbarTemplate} style={{ paddingTop: 5, paddingBottom: 5 }} />

                <div className="grid" style={{ marginTop: 8 }}>
                    <div className="col-6 md:col-2" style={{ marginTop: 8 }}>
                        <i className="pi pi-search"></i> Options de recherche
                    </div>
                    {this.appservice.get_userconnected().profil == 'Gestionnaire' ? null :
                        <div className="col-6 md:col-3" >
                            <span className="p-float-label" style={{ marginTop: 8 }}>
                                <Dropdown id='pidCrd' options={[{ value: 'Tous', label: 'Tous' }].concat(this.state.liste_crd)} value={this.state.pidCrd} onChange={(e) => this.setState({ pidCrd: e.value, liste_ecole: [],  pidClasse: 'Tous', pidEcole: 'Tous' }, () => {
                                    this.get_liste_ecole();
                                })} autoWidth={false} style={{ width: '100%' }} filter={true} filterBy='label' filterMatchMode='contains' />
                                <label htmlFor="pidCrd" >DCE/DPE</label>
                            </span>
                        </div>}
                    <div className="col-6 md:col-3" >
                        <span className="p-float-label" style={{ marginTop: 8 }}>
                            <Dropdown id='pidEcole' options={[{ value: 'Tous', label: 'Tous' }].concat(this.state.liste_ecole)} value={this.state.pidEcole} onChange={(e) => this.setState({ pidEcole: e.value, pidClasse: 'Tous' }, () => {
                                // this.get_liste_classe();
                            })} autoWidth={false} style={{ width: '100%' }} filter={true} filterBy='label' filterMatchMode='contains' />
                            <label htmlFor="pidEcole" >Ecole</label>
                        </span>
                    </div>
                    <div className="col-6 md:col-2" >
                        <span className="p-float-label" style={{ marginTop: 8 }}>
                            <Dropdown id='pidClasse' options={[{ value: 'Tous', label: 'Tous' }].concat(this.state.liste_classe)} value={this.state.pidClasse} onChange={(e) => this.setState({ pidClasse: e.value }, () => {
                                this.get_liste_inscription();
                                this.get_liste_matiere();
                            })} autoWidth={false} style={{ width: '100%' }} filter={true} filterBy='label' filterMatchMode='contains' />
                            <label htmlFor="pidClasse" >Classe</label>
                        </span>
                    </div>

                    <div className="col-6 md:col-2" style={{ marginTop: 8 }}>
                        <SplitButton label="Rechercher" icon="pi pi-search" onClick={(e) => this.get_liste_inscription()} model={this.state.liste_item_export} />
                    </div>

                </div>


                <div className="grid">
                    <div className="col-12 md:col-7 lg:col-7" style={{}}>
                        <i className="pi pi-users"></i> Liste des inscrits
                        <hr style={{ marginTop: 2 }} />
                        {/* Tableau contenant la liste des inscriptions */}
                        <DataTable ref={(el) => { this.dt = el; }} value={this.state.liste_insc} paginatorPosition="bottom" selectionMode="single" paginator={true} rows={15}
                            alwaysShowPaginator={false} onRowClick={e => this.setState({ selectedInsc: e.data }, () => {
                                this.get_liste_note();
                            })} onRowSelect={e => this.setState({ selectedInsc: e.data })}
                            dataKey='id' responsiveLayout='scroll'
                            responsive={true} selection={this.state.selectedInsc} className="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-customers" >
                            <Column header="Action" body={this.actionTemplate.bind(this)} style={{ width: '7%' }} />
                            <Column header="No" body={(rowData, props) => {
                                return <div>
                                    <span >{props.rowIndex + 1}</span>
                                </div>
                            }} style={{ width: '5%' }} />
                            <Column field="eleveId.matricule" filter={true} filterMatchMode='contains' header="Matricule" style={{ width: '10%' }} />
                            <Column field="eleveId.nom" filter={true} filterMatchMode='contains' header="Nom" style={{ width: '12%' }} />
                            <Column field="eleveId.prenom" filter={true} filterMatchMode='contains' header="Prenom" />
                            <Column field="classeId.libelle" filter={true} filterMatchMode='contains' header="Classe" style={{ width: '15%' }} />
                            <Column field="eleveId.pv" filter={true} filterMatchMode='contains' header="PV" style={{ width: '8%' }} />
                        </DataTable>
                    </div>

                    <div className="col-12 md:col-5 lg:col-5" style={{}}>
                        <i className="pi pi-book"></i> Notes
                        <hr style={{ marginTop: 2 }} />
                        <DataTable ref={(el) => { this.dt = el; }} value={this.state.liste_note} paginatorPosition="bottom" selectionMode="single" paginator={true} rows={15}
                            onSelectionChange={e => this.setState({ selectedNote: e.value })} selection={this.state.selectedNote}
                            footerColumnGroup={footerGroup} alwaysShowPaginator={false}
                            dataKey='id' responsiveLayout='scroll' editMode="cell" responsive={true} className="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-customers" >
                            <Column header="No" body={(rowData, props) => {
                                return <div>
                                    <span >{props.rowIndex + 1}</span>
                                </div>
                            }} style={{ width: '5%' }} />
                            <Column field="matiereId.libelle" header="Matière" filter={true} filterMatchMode='contains' />
                            <Column field="matiereId.coef" header="Coef." style={{ width: '10%' }} sortable />
                            <Column field="moy" body={(rowData, props) => {
                                return <div>
                                    <span style={{ color: rowData.moy < 10 ? 'red' : 'blacl' }} >{rowData.moy}</span>
                                </div>
                            }} header='Moyenne' style={{ width: '10%' }} sortable />
                        </DataTable>
                    </div>
                </div>



                {/* Boite de dialogue d'ajout et de modification d'un quartier  */}
                <Dialog visible={this.state.visible_dlg_form} style={{ width: '85%' }} header={this.state.modif_data == true ? "Modification" : "Ajout"} modal className="p-fluid" onHide={(e) => this.setState({ visible_dlg_form: false })}
                    footer={<>
                        <Button label="Fermer" icon="pi pi-times" className="p-button-secondary" style={{ width: 120 }} onClick={(e) => this.setState({ visible_dlg_form: false })} />
                        <Button label="Valider" loading={this.state.showindicator} icon="pi pi-check" onClick={(e) => this.enreg_data()} style={{ width: 120 }} />
                    </>}>
                    <div className="p-fluid">
                        <Panel headerTemplate={template} collapsed={true} toggleable style={{ marginBottom: 10 }}>
                            <div className="grid">
                                {this.appservice.get_userconnected().profil == 'Gestionnaire' ? null :
                                    <>
                                        <div className="col-6 md:col-3" >
                                            <span className="p-float-label" style={{ marginTop: 8 }}>
                                                <Dropdown id='pidCrd' options={this.state.liste_crd} value={this.state.pidCrd} onChange={(e) => this.setState({ pidCrd: e.value, liste_ecole: [], pidClasse: 'Tous', pidEcole: 'Tous' }, () => {
                                                    this.get_liste_ecole();
                                                })} autoWidth={false} style={{ width: '100%' }} filter={true} filterBy='label' filterMatchMode='contains' />
                                                <label htmlFor="pidCrd" >CRD</label>
                                            </span>
                                        </div>
                                    </>}

                                <div className="col-6 md:col-3" >
                                    <span className="p-float-label" style={{ marginTop: 8 }}>
                                        <Dropdown id='pidEcole' options={this.state.liste_ecole} value={this.state.pidEcole} onChange={(e) => this.setState({ pidEcole: e.value, pidClasse: 'Tous' }, () => {
                                            // this.get_liste_classe();
                                        })} autoWidth={false} style={{ width: '100%' }} filter={true} filterBy='label' filterMatchMode='contains' />
                                        <label htmlFor="pidEcole" >Ecole</label>
                                    </span>
                                </div>
                                <div className="col-6 md:col-3" >
                                    <span className="p-float-label" style={{ marginTop: 8 }}>
                                        <Dropdown id='pidClasse' options={this.state.liste_classe} value={this.state.pidClasse} onChange={(e) => this.setState({ pidClasse: e.value }, () => {
                                            this.get_liste_matiere();
                                        })} autoWidth={false} style={{ width: '100%' }} filter={true} filterBy='label' filterMatchMode='contains' />
                                        <label htmlFor="pidClasse" >Classe</label>
                                    </span>
                                </div>
                                <div className="col-6 md:col-3" >
                                    <span className="p-float-label" style={{ marginTop: 8 }}>
                                        <Dropdown id='pidMatiere' options={this.state.liste_matiere} value={this.state.pidMatiere} onChange={(e) => this.setState({ pidMatiere: e.value }, () => {
                                            this.get_liste_data();
                                        })} autoWidth={false} style={{ width: '100%' }} filter={true} filterBy='label' filterMatchMode='contains' />
                                        <label htmlFor="pidClasse" >Matière</label>
                                    </span>
                                </div>

                                <div className="col-6 md:col-2" style={{ marginTop: 8 }}>
                                    <SplitButton label="Rechercher" disabled={this.state.pidClasse == 'Tous' || this.state.pidClasse == null || this.state.pidMatiere == 'Tous' || this.state.pidMatiere == null || this.state.pidEcole == 'Tous' || this.state.pidEcole == null} icon="pi pi-search" onClick={(e) => this.get_liste_data()} model={this.state.liste_item_export} />
                                </div>
                                <div className="col-6 md:col-1" style={{ marginTop: 8 }}>
                                    {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                                </div>
                            </div>
                        </Panel>
                        {/* Tableau contenant la liste des notes */}
                        <DataTable ref={(el) => { this.dt = el; }} value={this.state.liste_data} paginatorPosition="bottom" selectionMode="single" paginator={true} rows={15}
                            onSelectionChange={e => this.setState({ selectedData: e.value })} selection={this.state.selectedData}
                            dataKey='id' responsiveLayout='scroll' editMode="cell" responsive={true} className="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-customers" >
                            <Column header="No" body={(rowData, props) => {
                                return <div>
                                    <span >{props.rowIndex + 1}</span>
                                </div>
                            }} style={{ width: '4%' }} />
                            <Column field="inscriptionId.eleveId.matricule" filter={true} filterMatchMode='contains' header="Matricule" style={{ width: '10%' }} />
                            <Column field="inscriptionId.eleveId.nom" filter={true} filterMatchMode='contains' header="Nom" style={{ width: '10%' }} />
                            <Column field="inscriptionId.eleveId.prenom" filter={true} filterMatchMode='contains' header="Prenom" />
                            <Column field="inscriptionId.eleveId.telephone" filter={true} filterMatchMode='contains' header="Téléphone" style={{ width: '7%' }} />
                            <Column field="inscriptionId.eleveId.pere" filter={true} filterMatchMode='contains' header="Père" style={{ width: '12%' }} />
                            <Column field="inscriptionId.eleveId.mere" filter={true} filterMatchMode='contains' header="Mère" style={{ width: '20%' }} />
                            <Column field="moy" header='Moyenne' style={{ width: '10%' }} filter={true} filterMatchMode='contains' editor={(options) => this.moyEditor(options)} onCellEditComplete={this.onCellEditComplete} />
                        </DataTable>
                    </div>
                </Dialog>

            </div >
        );
    }
}