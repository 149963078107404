import React, { Component } from 'react';
import AppServices from '../service/AppServices';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Toolbar } from 'primereact/toolbar';
import 'moment';
import 'moment/locale/fr';
import moment from 'moment';
import { classNames } from 'primereact/utils';
import { InputMask } from 'primereact/inputmask';
import { MultiSelect } from 'primereact/multiselect';
import { TabView, TabPanel } from 'primereact/tabview';
import { SplitButton } from 'primereact/splitbutton';
import { Panel } from 'primereact/panel';
import { Ripple } from 'primereact/ripple';
import { InputNumber } from 'primereact/inputnumber';
import { data } from 'jquery';

export default class FormInscription extends Component {
    constructor() {
        super();
        this.state = {
            liste_data: [],
            liste_insc: [],
            liste_selected_insc: [],
            liste_ecole: [],
            liste_parcours: [],
            liste_classe: [],
            session: '',
            classeId: null,
            newclasseId: null,
            liste_crd: [],
            liste_quartier: [],
            liste_ecole_rech: [],
            liste_classe_rech: [],
            liste_dce: [],
            pidQuartier: 'Tous',
            pidClasse: 'Tous',
            pidCrd: 'Tous',
            pidEcole: 'Tous',

            rsession: '',
            rdce: '',
            rpv: '',

            liste_item_export: [
                {
                    label: 'Exporter en CVS',
                    icon: 'pi pi-download',
                    command: (e) => {
                        this.exportCSV();
                    }
                },
                {
                    label: 'Exporter en XLS',
                    icon: 'pi pi-file-excel',
                    command: (e) => {
                        this.exportExcel();
                    }
                },
                {
                    label: 'Exporter en PDF',
                    icon: 'pi pi-file-pdf',
                    command: (e) => {
                        this.exportPdf();
                    }
                },
            ],
            selectedInsc: null,
            selectedData: null,
            formData: {
                id: 0,
                nom: '',
                prenom: '',
                matricule: '',
                photo: '',
                sexe: '',
                telephone: '',
                date_naiss: '',
                lieu_naiss: '',
                email: '',
                pere: '',
                mere: '',
                tel_parent: '',
                iduser: '',
                extrait_naiss: '',

            },
            formInsc: {
                id: '',
                date_insc: '',
                photo: '',
                statut: 'P',
                statut_academique: 'Normal',
                iduser: '',
                id_classe: 0,
                classeId: { id: 0 },
                ecoleId: { id: 0 },
                eleveId: { id: 0 },
                annee_insc: '2023-2024',
                moy: 0,
            },
            file: null,
            urlfile: null,

            fileExn: null,
            urlfileExn: null,

            ////////////////////////////
            checkPhone: true, //Véfifier si le numéro existe déja
            visible_dlg_insc: false,
            modif_data: false, // pour vérifier s'il s'agit d'une modification ou un ajout
            modif_insc: false, // pour vérifier s'il s'agit d'une modification ou un ajout
            visible: false, // pour afficher la boite de confirmation de suppression
            visible_del_bloc: false, // pour afficher la boite de confirmation de suppression
            visible_dlg_form: false, // pour afficher la boite de dialogue du formation de saisie
            visible_dlg_form_reinsc: false,
            visible_dlg_detail: false,
            visible_dlg_delete_insc: false,
            showindicator: false, // pour afficher l'état de chargement
            submit: false // pour indiquer si le bouton Valider a été cliqueé et vérifier les champs obligatoires qui seront surlignés en rouge
            ////////////////////////////
        };
        this.edit_data = this.edit_data.bind(this);
        this.conf_delete_data = this.conf_delete_data.bind(this);
        this.conf_delete_insc_bloc = this.conf_delete_insc_bloc.bind(this);
        this.edit_insc = this.edit_insc.bind(this);
        this.conf_delete_insc = this.conf_delete_insc.bind(this);
        this.itemTemplate = this.itemTemplate.bind(this);
        this.toogleDlg = this.toogleDlg.bind(this);
        this.toogleDlgReinsc = this.toogleDlgReinsc.bind(this);
        this.appservice = new AppServices();
    }

    componentDidMount() {
        //this.get_liste_data();
        //this.get_liste_ecole();
        this.get_liste_classe();
        this.appservice.get_liste_commune().then(data => {
            if (data != null && data != undefined) {
                let liste = data.map(x => {
                    return { value: x.id, label: x.libelle, prefecture: x.prefectureId.libelle }
                });
                this.setState({ liste_crd: liste });
                if (this.appservice.get_userconnected().profil == 'Gestionnaire') {
                    this.setState({ pidCrd: 'Tous' }, () => {
                        this.get_liste_ecole();
                    })
                }
            }
        });
    }

    get_liste_dce() {
        this.appservice.get_liste_dce(this.state.rsession).then(data => this.setState({ liste_dce: data }));
    }

    search() {
        this.setState({ showindicator: true });
        this.appservice.find_eleve(this.state.rsession, this.state.rpv, this.state.rdce).then(rep => {
            this.setState({ showindicator: false });
            let trouve = false;
            let data=rep.data;
            if (data !== null && data !== undefined) {
                if (data.length > 0) {
                    trouve = true;
                    this.toast.show({ severity: 'success', summary: 'Success', detail: rep.msg });
                    if (data[0].no_photo !== null && data[0].no_photo !== '') {
                        data[0].photo=data[0].no_photo+'.jpg';
                        this.setState({ urlfile: this.appservice.download_file(data[0].no_photo + '.jpg') })
                    } else {
                        this.setState({ urlfile: null })
                    }
                    this.setState({ formData: data[0] });
                }
            }
            if (trouve == false) {
                this.setState({
                    formData: {
                        id: 0,
                        nom: '',
                        prenom: '',
                        matricule: '',
                        photo: '',
                        sexe: '',
                        telephone: '',
                        date_naiss: '',
                        lieu_naiss: '',
                        email: '',
                        pere: '',
                        mere: '',
                        tel_parent: '',
                        iduser: '',
                        extrait_naiss: ''
                    },
                    formInsc: {
                        id: '',
                        date_insc: '',
                        photo: '',
                        statut: 'P',
                        statut_academique: 'Normal',
                        iduser: '',
                        id_classe: this.state.formInsc.id_classe,
                        ecoleId: this.state.formInsc.ecoleId,
                        classeId: this.state.formInsc.classeId,
                        eleveId: { id: 0 },
                        annee_insc: this.appservice.get_selected_session(),
                        moy: 0,
                    },
                });
                this.toast.show({ severity: 'warn', summary: 'Echec', detail: rep.msg  });
            }

        }); 
    }

    get_liste_data() {
        this.setState({ showindicator: true });
        this.appservice.get_liste_inscription(this.state.pidCrd==null?'Tous':this.state.pidCrd, this.state.pidEcole==null?'Tous':this.state.pidEcole, this.state.pidClasse==null?'Tous':this.state.pidClasse).then(data => this.setState({ liste_data: data, showindicator: false }));
    }

    get_liste_reinsc() {
        this.setState({ showindicator: true });
        this.appservice.get_liste_inscription_classe_session(this.state.classeId, this.state.session).then(data => this.setState({ liste_insc: data, showindicator: false }));
    }

    //Liste des écoles
    get_liste_ecole() {
        this.setState({ showindicator: true });
        this.appservice.get_liste_ecole(this.state.pidCrd).then(data => {
            this.setState({ showindicator: false });
            if (data != undefined) {
                if (data.length > 0) {
                    let liste = data.map(x => {
                        return { value: x.id, label: x.nom }
                    });
                    this.setState({ liste_ecole: liste });
                    if (this.appservice.get_userconnected().profil == 'Gestionnaire') {
                        this.setState({ pidEcole: liste[0].value })
                    }
                }
            }
        });
    }


    //Liste des classes
    get_liste_classe() {
        this.setState({ showindicator: true, liste_classe: [] });
        this.appservice.get_liste_classe().then(data => {
            console.log(data);
            this.setState({ showindicator: false });
            if (data != undefined) {
                if (data.length > 0) {
                    let liste = data.map(x => {
                        return { value: x.id, label: x.libelle + ' ' + x.option_classe + ' ' + x.enseignement }
                    });
                    this.setState({ liste_classe: liste });
                }
            }
        });
    }



    /* Annuler l'opération en cours et vider le formulaire */
    annuler() {
        this.setState({
            formData: {
                id: 0,
                nom: '',
                prenom: '',
                matricule: '',
                photo: '',
                sexe: '',
                telephone: '',
                date_naiss: '',
                lieu_naiss: '',
                email: '',
                pere: '',
                mere: '',
                tel_parent: '',
                iduser: '',
                extrait_naiss: ''
            },
            formInsc: {
                id: '',
                date_insc: '',
                photo: '',
                statut: 'P',
                statut_academique: 'Normal',
                iduser: '',
                id_classe: this.state.formInsc.id_classe,
                ecoleId: this.state.formInsc.ecoleId,
                classeId: this.state.formInsc.classeId,
                eleveId: { id: 0 },
                annee_insc: this.appservice.get_selected_session(),
                moy: 0,
            },
        });
        if (this.state.modif_data == true) {
            this.setState({ visible_dlg_form: false });
        } else {
            //this.inputNom.focus();
        }
        this.setState({ showindicator: false });
        this.setState({
            modif_data: false,
            file: null,
            urlfile: null,
            fileExn: null,
            urlfileExn: null
        });
        this.setState({ visible_dlg_delete: false });
    }

    /* Remplissage du formulaire avec les informations de la ligne sélectionnée dans le cadre de la modification  */
    edit_data(data) {
        if (data !== null && data !== undefined) {
            let eleveId = data.eleveId;
            let ldate = data.eleveId.date_naiss.split("-");
            eleveId.date_naiss = ldate[2] + '/' + ldate[1] + '/' + ldate[0];
            this.setState({ formData: eleveId });
            this.setState({ formInsc: data });
            this.setState({ pidCrd: data.ecoleId.quartierId.communeId.id }, () => {
                this.get_liste_ecole();
                this.setState({ pidEcole: data.ecoleId.id }, () => {
                    this.get_liste_classe();
                })
            });
            this.setState({ modif_data: true });
            this.setState({ visible_dlg_form: true });
            this.setState({ selectedData: data });

            if (data.photo !== null && data.photo !== '') {
                this.setState({ urlfile: this.appservice.download_file(data.photo) })
            } else {
                this.setState({ urlfile: null })
            }
            if (data.eleveId.extrait_naiss !== null && data.eleveId.extrait_naiss !== '') {
                this.setState({ urlfileExn: 'OK' })
            } else {
                this.setState({ urlfileExn: null })
            }
        }
    }

    /* Remplissage du formulaire avec les informations de la ligne sélectionnée dans le cadre de la modification  */
    edit_insc(data) {
        if (data !== null && data !== undefined) {
            this.setState({ formInsc: data });
            let eleveId = data.eleveId;
            let ldate = data.eleveId.date_naiss.split("-");
            eleveId.date_naiss = ldate[2] + '/' + ldate[1] + '/' + ldate[0];
            this.setState({ formData: eleveId });
            this.setState({ modif_insc: true });
            this.setState({ visible_dlg_insc: true });
            this.setState({ selectedInsc: data });

            if (data.photo !== null && data.photo !== '') {
                this.setState({ urlfile: this.appservice.download_file(data.photo) })
            } else {
                this.setState({ urlfile: null })
            }
        }
    }

    enreg_reiensc_enbloc() {
        if (this.state.liste_selected_insc.length <= 0) {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner les lignes' });
        } else if (this.state.newclasseId == null || this.state.newclasseId == undefined) {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner la classe de destination' });
        } else {
            this.setState({ showindicator: true });
            let iduser = this.appservice.get_userconnected().id;
            let annee_insc = this.appservice.get_selected_session();
            let liste = this.state.liste_selected_insc.map(x => {
                return {
                    iduser: iduser,
                    modif: 0,
                    statut: 'P',
                    annee_insc: annee_insc,
                    id_classe: this.state.newclasseId,
                    id_eleve: x.eleveId.id,
                    moy: 0
                }
            });
            this.appservice.add_inscription_bulk(liste).then(reponse => {
                this.resultat(reponse);
                if (reponse.success == true) {
                    this.get_liste_data();
                }
            });
        }
    }

    /* Enregistrer les informations du formulaire region */
    enreg_data() {
        this.setState({ submit: true });
        if (this.state.formData.nom == "" || this.state.formData.prenom == "" || this.state.formData.telephone == "" || this.state.formData.date_naiss == "" || this.state.formData.lieu_naiss == "" || this.state.formData.mere == "" || this.state.formData.pere == "") {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez renseigner tous les champs obligatoires' });
        } else {

            let form = this.state.formInsc;
            form.eleveId = this.state.formData;
            let ldate = form.eleveId.date_naiss.split("/");
            if (ldate.length == 3) {
                form.eleveId.date_naiss = ldate[2] + '-' + ldate[1] + '-' + ldate[0];
            }

            let nomfic = this.state.formInsc.photo;
            let upload = false;
            if (this.state.file != null && this.state.file != undefined) {
                let name = this.state.file.name;
                nomfic = 'PHOTO_U' + this.appservice.get_userconnected().id + moment(new Date()).format('DDMMYYYYHHmmss') + '.' + name.slice((name.lastIndexOf(".") - 1 >>> 0) + 2);
                upload = true;
                form.photo = nomfic;
                form.eleveId.photo = nomfic;
            }

            let uploadExtn = false;
            let nomficExtn = this.state.formData.extrait_naiss;
            if (this.state.fileExn != null && this.state.fileExn != undefined) {
                let name = this.state.fileExn.name;
                nomficExtn = 'EXTN_U' + this.appservice.get_userconnected().id + moment(new Date()).format('DDMMYYYYHHmmss') + '.' + name.slice((name.lastIndexOf(".") - 1 >>> 0) + 2);
                uploadExtn = true;
                form.eleveId.extrait_naiss = nomficExtn;
            }

            this.appservice.save_inscription(form).then(reponse => {
                if (reponse != undefined && reponse != null) {
                    if (reponse.success == true) {
                        if (upload === true) {
                            this.fileUpload(this.state.file, nomfic);
                        }
                        if (uploadExtn === true) {
                            this.fileUpload(this.state.fileExn, nomficExtn);
                        }
                        this.get_liste_data();
                    }
                    this.resultat(reponse);
                }
            });
        }
    }

    /* Fonction d'affichage des messages retournés suite aux actions serveurs */
    resultat(rep) {
        if (rep.success == true) {
            this.toast.show({ severity: 'success', summary: 'Success', detail: rep.message });
            this.annuler();
        } else {
            this.toast.show({ severity: 'error', summary: 'Error', detail: rep.message });
        }
        this.setState({ submit: false });
    }


    enreg_insc() {
        this.setState({ submit: true });
        if (this.state.formInsc.date_insc == '' || this.state.formInsc.annee_insc == '' || this.state.formInsc.classeId.id == 0 || this.state.formInsc.statut == '') {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez saisir tous les champs obligatoires' });
        } else {
            let nomfic = this.state.formInsc.photo;
            let upload = false;
            if (this.state.file != null && this.state.file != undefined) {
                let name = this.state.file.name;
                nomfic = 'PHOTO_' + this.appservice.get_userconnected().id + moment(new Date()).format('DDMMYYYYHHmmss') + '.' + name.slice((name.lastIndexOf(".") - 1 >>> 0) + 2);
                upload = true;
            }

            let form = this.state.formInsc;
            form.photo = nomfic;

            this.appservice.save_inscription(form).then(reponse => {
                if (reponse != undefined && reponse != null) {
                    if (reponse.success == true) {
                        if (upload === true) {
                            this.fileUpload(this.state.file, nomfic);
                        }
                        this.get_liste_data();
                    }
                    this.resultat(reponse);
                }
            });
        }
    }

    actionTemplate(rowData, props) {
        let items = [

        ]
        if (this.appservice.getProfil() == 'Administrateur') {
            items.push(
                {
                    label: 'Modifier',
                    icon: 'pi pi-pencil',
                    command: (e) => {
                        this.edit_data(rowData);
                    }
                },
                {
                    label: 'Supprimer',
                    icon: 'pi pi-times',
                    command: (e) => {
                        this.conf_delete_data(rowData);
                    }
                },
            )
        }

        items.push(
            {
                label: 'Parcours',
                icon: 'pi pi-eye',
                command: (e) => {
                    this.setState({ selectedData: rowData, visible_dlg_detail: true }, () => {
                        this.getInscriptions();
                    });
                }
            },
            {
                label: 'Attestation',
                icon: 'pi pi-print',
                command: (e) => {
                    this.setState({ showindicator: true,selectedData: rowData });
                    this.appservice.get_liste_note_eleve(rowData.id).then(data => this.setState({showindicator: false }, () => {
                        let moy = this.appservice.calculer_moyenne(data);
                        if(moy>=10){
                            this.appservice.generer_attestation(rowData, data);
                        }else{
                            this.toast.show({ severity: 'warn', summary: 'Attention', detail: "Cet élève n'est pas admis " });
                        }
                    }));
                }
            },
        )

        return <SplitButton label='Modifier' icon="pi pi-pencil"
            onClick={(e) => {
                this.setState({ selectedData: rowData }, () => {
                    this.edit_data(rowData);
                });
            }} model={items} style={{ width: 115 }} />;
    }


    /* Colonne Action du tableau contenant la liste des classes */
    actionTemplateInsc(rowData, column) {
        let classe = 'p-button-info';
        const items = [
            {
                label: 'Modifier',
                icon: 'pi pi-pencil',
                command: (e) => {
                    this.edit_insc(rowData);
                }
            },
            {
                label: 'Supprimer',
                icon: 'pi pi-trash',
                command: (e) => {
                    this.conf_delete_insc(rowData);
                }
            },
        ]
        return <SplitButton label="Modifier" icon="pi pi-pencil" onClick={(e) => this.edit_insc(rowData)} model={items} />
    }

    // Permet d'afficher la liste des salariés sous forme de tableau lors de la sélection dans le formulaire
    itemTemplate(option) {
        if (!option.value) {
            return option.label;
        }
        else {
            return (
                <div className="grid" style={{ padding: 10 }}>
                    <div className="col-8">
                        <label>{option.label}</label>
                    </div>
                    <div className="col-4">
                        <label>{option.ecole}</label>
                    </div>
                </div>
            );
        }
    }

    /* Fonction de suppression de la ligne sélectionnée */
    delete_data() {
        if (this.state.visible_dlg_delete_insc == true) {
            let iduser = this.appservice.get_userconnected().id;
            let liste = this.state.liste_selected_insc.map(x => {
                return {
                    iduser: iduser,
                    id: x.id,
                }
            });
            this.appservice.delete_inscription_bloc(liste).then(reponse => {
                this.resultat(reponse);
                if (reponse.success == true) {
                    this.get_liste_reinsc();
                }
            });
        } else {
            if (this.state.selectedData == null || this.state.selectedData == undefined) {
                this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner la ligne à supprimer' });
            } else {
                this.appservice.delete_inscription(this.state.selectedData).then(reponse => {
                    this.resultat(reponse);
                    if (reponse.success == true) {
                        this.get_liste_data();
                    }
                });
            }
        }

    }


    conf_delete_data(data) {
        if (data != null && data != undefined) {
            this.setState({ selectedData: data });
            this.setState({ msg: data.eleveId.nom + ' ' + data.eleveId.prenom + ' - ' + data.classeId.libelle });
            this.setState({ visible: true });
        }
    }

    conf_delete_insc_bloc() {
        this.setState({ visible_dlg_delete_insc: true });
    }

    conf_delete_insc(data) {
        if (data != null && data != undefined) {
            this.setState({ selectedInsc: data });
            this.setState({ msg: data.classeId.libelle });
            this.setState({ visible_dlg_delete_insc: true });
        }
    }

    /* Affichage de la boite de dialogue pour l'ajout */
    toogleDlg() {
        this.annuler();
        this.setState({ visible_dlg_form: !this.state.visible_dlg_form });
    }

    toogleDlgReinsc() {
        this.setState({ visible_dlg_form_reinsc: !this.state.visible_dlg_form_reinsc });
    }

    /* Zone gauche de la toolbar */
    leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <Button label="Inscrire" icon="pi pi-plus" className="p-button-success mr-2" onClick={this.toogleDlg} style={{ width: 115 }} />
                    <Button icon="pi pi-trash" className="p-button-danger  mr-2" onClick={(e) => this.conf_delete_insc_bloc()} style={{}} />
                    <Button label="Réinscrire" icon="pi pi-pencil" className="p-button-warning" onClick={this.toogleDlgReinsc} style={{ width: 115, marginRight: 10 }} />
                    {/* <Button label="Supprimer" disabled={this.state.selectedData == null} icon="pi pi-trash" className="p-button-danger" onClick={(e) => this.conf_delete_data(this.state.selectedData)} style={{ width: 115 }} /> */}
                </div>
            </React.Fragment>
        )

    }

    /* Zone droite de la toolbar */
    rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> :
                        <Button label="Export" icon="pi pi-download" className="p-button-help  mr-2" onClick={this.exportCSV} />}
                </div>
            </React.Fragment>
        )
    }

    /* Exportation du tableau contenant la liste des inscriptions en fichier CSV */
    exportCSV = () => {
        //ref={(el) => { this.dt = el; }}
        this.dt.exportCSV("Liste_inscription");
    }

    /* Exportation du tableau  en fichier PDF */
    exportPdf = () => {
        const cols = [
            { field: 'MATRICULE', header: 'MATRICULE' },
            { field: 'NOM', header: 'NOM' },
            { field: 'PRENOM', header: 'PRENOM' },
            { field: 'SEXE', header: 'SEXE' },
            { field: 'TELEPHONE', header: 'TELEPHONE' },
            { field: 'DATE_NAISS', header: 'DATE_NAISS' },
            { field: 'LIEU_NAISS', header: 'LIEU_NAISS' },
            { field: 'PERE', header: 'PERE' },
            { field: 'MERE', header: 'MERE' },
            { field: 'CLASSE', header: 'CLASSE' },
            { field: 'ECOLE', header: 'ECOLE' },
            { field: 'QUARTIER', header: 'QUARTIER' },
            { field: 'CRD', header: 'CRD' },
            { field: 'PREFECTURE', header: 'PREFECTURE' },
            { field: 'REGION', header: 'REGION' },
        ];
        let liste_data = this.state.liste_data.length > 0 ? this.state.liste_data.map(x => {
            return { 'MATRICULE': x.eleveId.matricule, 'NOM': x.eleveId.nom, 'PRENOM': x.eleveId.prenom, 'SEXE': x.eleveId.sexe, 'TELEPHONE': x.eleveId.telephone, 'DATE_NAISS': x.eleveId.date_naiss, 'LIEU_NAISS': x.eleveId.lieu_naiss, 'PERE': x.eleveId.pere, 'MERE': x.eleveId.mere, 'CLASSE': x.classeId.libelle, 'ECOLE': x.classeId.ecoleId.nom, 'QUARTIER': x.classeId.ecoleId.quartierId.libelle, 'CRD': x.classeId.ecoleId.quartierId.communeId.libelle, 'PREFECTURE': x.classeId.ecoleId.quartierId.communeId.prefectureId.libelle, 'CRD': x.classeId.ecoleId.quartierId.communeId.prefectureId.regionId.libelle }
        }) : [];
        const exportColumns = cols.map(col => ({ title: col.header, dataKey: col.field }));
        import('jspdf').then(jsPDF => {
            import('jspdf-autotable').then(() => {
                var doc = new jsPDF.default(0, 0);
                doc.autoTable(exportColumns, liste_data);
                doc.save('LISTE_INSCRIPTION.pdf');
            })
        })
    }

    /* Exportation du tableau  en fichier XLSX */
    exportExcel = () => {
        let liste_data = this.state.liste_data.length > 0 ? this.state.liste_data.map(x => {
            return { 'MATRICULE': x.eleveId.matricule, 'NOM': x.eleveId.nom, 'PRENOM': x.eleveId.prenom, 'SEXE': x.eleveId.sexe, 'TELEPHONE': x.eleveId.telephone, 'DATE_NAISS': x.eleveId.date_naiss, 'LIEU_NAISS': x.eleveId.lieu_naiss, 'PERE': x.eleveId.pere, 'MERE': x.eleveId.mere, 'CLASSE': x.classeId.libelle, 'ECOLE': x.classeId.ecoleId.nom, 'QUARTIER': x.classeId.ecoleId.quartierId.libelle, 'CRD': x.classeId.ecoleId.quartierId.communeId.libelle, 'PREFECTURE': x.classeId.ecoleId.quartierId.communeId.prefectureId.libelle, 'CRD': x.classeId.ecoleId.quartierId.communeId.prefectureId.regionId.libelle }
        }) : [];
        import('xlsx').then(xlsx => {
            const worksheet = xlsx.utils.json_to_sheet(liste_data);
            const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
            const excelBuffer = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
            this.saveAsExcelFile(excelBuffer, 'LISTE_INSCRIPTION_');
        });
    }

    /* Enregistrer le fichier sur le client */
    saveAsExcelFile = (buffer, fileName) => {
        import('file-saver').then(module => {
            if (module && module.default) {
                let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                let EXCEL_EXTENSION = '.xlsx';
                const data = new Blob([buffer], {
                    type: EXCEL_TYPE
                });

                module.default.saveAs(data, fileName + new Date().getTime() + EXCEL_EXTENSION);
            }
        });
    }


    openFileUpload() {
        document.getElementById("hiddenFile").click();
    }

    openFileUploadExn() {
        document.getElementById("hiddenFileExn").click();
    }

    /* Uploader sur le serveur le logo */
    fileUpload(file, namefile) {
        this.appservice.upload_file(file, namefile).then(data => {
            if (data.success == true) {
                this.toast.show({ severity: 'success', summary: 'Success', detail: 'Chargement du fichier sur le serveur effectué avec succès' });
            } else {
                this.toast.show({ severity: 'error', summary: 'Error', detail: 'Echec upload du fichier sur le serveur' });
            }
        });
    }

    get_liste_insc_comp() {
        return <>
            <div className="p-fluid" >
                <div className="grid">

                    {this.appservice.get_userconnected().profil == 'Gestionnaire' ? null :
                        <div className="col-6 md:col-3" >
                            <Dropdown id='pidCrd' options={this.state.liste_crd} placeholder="CRD" value={this.state.pidCrd} onChange={(e) => this.setState({ pidCrd: e.value, liste_ecole: [], liste_classe: [], pidClasse: 'Tous', pidEcole: 'Tous' }, () => {
                                this.get_liste_ecole();
                            })} autoWidth={false} style={{ width: '100%' }} filter={true} filterBy='label' filterMatchMode='contains' />
                        </div>
                    }
                    <div className="col-6 md:col-3" >
                        <Dropdown id='pidEcole' options={this.state.liste_ecole} placeholder="Ecole" value={this.state.pidEcole} onChange={(e) => this.setState({ pidEcole: e.value, liste_classe: [], pidClasse: 'Tous' }, () => {
                            this.get_liste_classe();
                        })} autoWidth={false} style={{ width: '100%' }} filter={true} filterBy='label' filterMatchMode='contains' />
                    </div>
                    <div className="col-6 md:col-2" >
                        <Dropdown id='pidClasse' options={this.state.liste_classe} placeholder="Classe" value={this.state.classeId} onChange={(e) => this.setState({ classeId: e.value }, () => {
                            //this.get_liste_reinsc();
                        })} autoWidth={false} style={{ width: '100%' }} filter={true} filterBy='label' filterMatchMode='contains' />
                    </div>
                    <div className="col-6 md:col-2" >
                        <Dropdown id='pession' options={this.appservice.get_liste_session()} placeholder="Session" value={this.state.session} onChange={(e) => this.setState({ session: e.value }, () => {
                            if (this.state.classeId != null) {
                                this.get_liste_reinsc();
                            }
                        })} autoWidth={false} style={{ width: '100%' }} filter={true} filterBy='label' filterMatchMode='contains' />
                    </div>

                    <div className="col-6 md:col-2" >
                        <Button label="Rechercher" disabled={this.state.classeId == null || this.state.session == ''} icon="pi pi-search" onClick={(e) => this.get_liste_reinsc()} />
                    </div>

                </div>
                <DataTable ref={(el) => { this.dt = el; }} value={this.state.liste_insc} paginatorPosition="bottom"
                    paginator={true} rows={8} dataKey='id' responsiveLayout='scroll' alwaysShowPaginator={false}
                    selectionMode="multiple" onRowClick={e => this.setState({ liste_selected_insc: e.data })}
                    onSelectionChange={e => this.setState({ liste_selected_insc: e.value })}
                    selection={this.state.liste_selected_insc} responsive={true} className="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-customers" >
                    <Column header="No" body={(rowData, props) => {
                        return <div>
                            <span >{props.rowIndex + 1}</span>
                        </div>
                    }} style={{ width: '4%' }} />
                    <Column selectionMode="multiple" style={{ width: '5%' }} />
                    <Column field="eleveId.matricule" filter={true} filterMatchMode='contains' header="Matricule" style={{ width: '9%' }} />
                    <Column field="eleveId.nom" filter={true} filterMatchMode='contains' header="Nom" style={{ width: '8%' }} />
                    <Column field="eleveId.prenom" filter={true} filterMatchMode='contains' header="Prenom" />
                    <Column field="eleveId.telephone" filter={true} filterMatchMode='contains' header="Téléphone" style={{ width: '7%' }} />
                    <Column field="statut" filter={true} filterMatchMode='contains' header="Statut" style={{ width: '7%' }} />
                    <Column field="eleveId.pv" filter={true} filterMatchMode='contains' header="PV" style={{ width: '5%' }} />
                </DataTable>
            </div>

            <div className="grid" style={{ padding: 5, height: 55, backgroundColor: '#f7f7f7', marginTop: 5 }}>
                <div className="col-12 md:col-3" >
                    <label htmlFor="classe">Classe destination *</label>
                </div>
                <div className="col-12 md:col-5" >
                    <Dropdown options={this.state.liste_classe} value={this.state.newclasseId} onChange={(e) => this.setState({ newclasseId: e.target.value })} autoWidth={false} filter filterBy='label' filterMatchMode='contains' />
                </div>
            </div>
        </>
    }

    is_disable() {
        return this.state.modif_data == false && this.appservice.getProfil() !== 'Administrateur';
    }

    render() {
        const template = (options) => {
            const toggleIcon = options.collapsed ? 'pi pi-filter-fill' : 'pi pi-chevron-up';
            const className = `${options.className} justify-content-start`;
            const titleClassName = `${options.titleClassName} pl-1`;

            return (
                <a onClick={options.onTogglerClick} style={{ cursor: 'pointer' }}>
                    <div className={className}>
                        <button className={options.togglerClassName} onClick={options.onTogglerClick}>
                            <span className={toggleIcon}></span>
                            <Ripple />
                        </button>
                        <span className={titleClassName}>
                            Filtre avancé
                        </span>

                    </div>
                </a>
            )
        }
        const dialogFooter = (
            <div>
                <Button icon="pi pi-check" onClick={() => {
                    this.setState({ visible: false });
                    this.delete_data();
                }} label="Oui" />
                <Button icon="pi pi-times" onClick={() => this.setState({ visible: false })} label="Annuler" className="p-button-secondary" />
            </div>
        );
        return (
            <div className="card p-fluid col-12" style={{ height: 1200 }}>
                <Toast ref={(el) => this.toast = el} />
                <Dialog header="Confirmation" visible={this.state.visible} style={{ width: '250px' }} modal={true} footer={dialogFooter} onHide={() => this.setState({ visible: false })}>
                    <div>
                        <i className="fa fa-warning" style={{ color: '#FFD133', fontSize: 25 }}></i>
                        Voulez vous vraiment supprimer <b>{this.state.msg} </b>?
                    </div>
                </Dialog>

                {/* Toolbar contenant les boutons d'ajout, de suppression et d'exportation */}
                <Toolbar className="mb-4" left={this.leftToolbarTemplate} right={this.rightToolbarTemplate} style={{ paddingTop: 5, paddingBottom: 5 }} />

                <Panel headerTemplate={template} collapsed={true} toggleable style={{ marginBottom: 10 }}>
                    <div className="grid">
                        {this.appservice.get_userconnected().profil == 'Gestionnaire' ? null :
                            <div className="col-6 md:col-3" >
                                <span className="p-float-label" style={{ marginTop: 8 }}>
                                    <Dropdown id='pidCrd' options={[{ value: 'Tous', label: 'Tous' }].concat(this.state.liste_crd)}  value={this.state.pidCrd} onChange={(e) => this.setState({ pidCrd: e.value, liste_ecole: [], liste_classe: [], pidClasse: 'Tous', pidEcole: 'Tous' }, () => {
                                        this.get_liste_ecole();
                                    })} autoWidth={false} style={{ width: '100%' }} filter={true} filterBy='label' filterMatchMode='contains' />
                                    <label htmlFor="pidCrd" >DCE/DPE</label>
                                </span>
                            </div>}
                        <div className="col-6 md:col-3" >
                            <span className="p-float-label" style={{ marginTop: 8 }}>
                                <Dropdown id='pidEcole' options={[{ value: 'Tous', label: 'Tous' }].concat(this.state.liste_ecole)}  value={this.state.pidEcole} onChange={(e) => this.setState({ pidEcole: e.value, liste_classe: [], pidClasse: 'Tous' }, () => {
                                   // this.get_liste_classe();
                                })} autoWidth={false} style={{ width: '100%' }} filter={true} filterBy='label' filterMatchMode='contains' />
                                <label htmlFor="pidEcole" >Ecole</label>
                            </span>
                        </div>
                        <div className="col-6 md:col-3" >
                            <span className="p-float-label" style={{ marginTop: 8 }}>
                                <Dropdown id='pidClasse' options={[{ value: 'Tous', label: 'Tous' }].concat(this.state.liste_classe)}  value={this.state.pidClasse} onChange={(e) => this.setState({ pidClasse: e.value }, () => {
                                    this.get_liste_data();
                                })} autoWidth={false} style={{ width: '100%' }} filter={true} filterBy='label' filterMatchMode='contains' />
                                <label htmlFor="pidClasse" >Classe</label>
                            </span>
                        </div>

                        <div className="col-6 md:col-2" style={{ marginTop: 8 }}>
                            <SplitButton label="Rechercher" icon="pi pi-search" onClick={(e) => this.get_liste_data()} model={this.state.liste_item_export} />
                        </div>

                    </div>
                </Panel>

                {/* Tableau contenant la liste des inscriptions */}
                <DataTable ref={(el) => { this.dt = el; }} value={this.state.liste_data} paginatorPosition="bottom" selectionMode="single" paginator={true} rows={15}
                    alwaysShowPaginator={false} onRowClick={e => this.setState({ selectedData: e.data })} onRowSelect={e => this.setState({ selectedData: e.data })}
                    dataKey='id' responsiveLayout='scroll'
                    responsive={true} selection={this.state.selectedData} className="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-customers" >
                    <Column header="No" body={(rowData, props) => {
                        return <div>
                            <span >{props.rowIndex + 1}</span>
                        </div>
                    }} style={{ width: '4%' }} />
                    <Column header="Action" body={this.actionTemplate.bind(this)} style={{ width: '8%' }} />
                    <Column field="eleveId.matricule" filter={true} filterMatchMode='contains' header="Matricule" style={{ width: '9%' }} />
                    <Column field="eleveId.nom" filter={true} filterMatchMode='contains' header="Nom" style={{ width: '8%' }} />
                    <Column field="eleveId.prenom" filter={true} filterMatchMode='contains' header="Prenom" />
                    <Column field="eleveId.telephone" filter={true} filterMatchMode='contains' header="Téléphone" style={{ width: '7%' }} />
                    <Column field="classeId.libelle" filter={true} filterMatchMode='contains' header="Classe" style={{ width: '10%' }} />
                    <Column field="ecoleId.nom" filter={true} filterMatchMode='contains' header="Ecole" style={{ width: '12%' }} />
                    {/*<Column field="classeId.ecoleId.quartierId.libelle" filter={true} filterMatchMode='contains' header="Quartier" style={{ width: '10%' }} />*/}
                    <Column field="ecoleId.quartierId.communeId.libelle" filter={true} filterMatchMode='contains' header="Commune" style={{ width: '10%' }} />
                    {/*<Column field="annee_insc" header='Année' style={{ width: '7%' }} filter={true} filterMatchMode='contains' />*/}
                    <Column field="statut" filter={true} filterMatchMode='contains' header="Statut" style={{ width: '7%' }} />
                    <Column field="eleveId.pv" filter={true} filterMatchMode='contains' header="PV" style={{ width: '5%' }} />
                </DataTable>

                {/* Boite de dialogue contenant l'historique  */}
                <Dialog visible={this.state.visible_dlg_form_reinsc} style={{ width: '75%' }} header='Réinscription' modal className="p-fluid" onHide={(e) => this.setState({ visible_dlg_form_reinsc: false })}
                    footer={<>
                        <Button label="Fermer" loading={this.state.showindicator} icon="pi pi-times" className="p-button-secondary" style={{ width: 120 }} onClick={(e) => this.setState({ visible_dlg_form_reinsc: false })} />
                        <Button label="Valider" loading={this.state.showindicator} icon="pi pi-check" onClick={(e) => this.enreg_reiensc_enbloc()} style={{ width: 120 }} />
                    </>}>
                    {this.get_liste_insc_comp()}
                </Dialog>

                {/* Boite de dialogue contenant l'historique  */}
                <Dialog visible={this.state.visible_dlg_delete_insc} style={{ width: '75%' }} header='Suppression' modal className="p-fluid" onHide={(e) => this.setState({ visible_dlg_delete_insc: false })}
                    footer={<>
                        <Button label="Fermer" loading={this.state.showindicator} icon="pi pi-times" className="p-button-secondary" style={{ width: 120 }} onClick={(e) => this.setState({ visible_dlg_delete_insc: false })} />
                        <Button label="Supprimer" loading={this.state.showindicator} icon="pi pi-trash" disabled={this.state.liste_selected_insc <= 0} className="p-button-danger" onClick={(e) => {
                            this.setState({ visible: true });
                        }} style={{ width: 120 }} />
                    </>}>
                    {this.get_liste_insc_comp()}
                </Dialog>

                {/* Boite de dialogue contenant l'historique  */}
                <Dialog visible={this.state.visible_dlg_detail} style={{ width: '70%' }} header='Parcours' modal className="p-fluid" onHide={(e) => this.setState({ visible_dlg_detail: false })}
                    footer={<>
                        <Button label="Fermer" loading={this.state.showindicator} icon="pi pi-times" className="p-button-secondary" style={{ width: 120 }} onClick={(e) => this.setState({ visible_dlg_detail: false })} />
                    </>}>
                    <div className="p-fluid" style={{ marginTop: -10, height: 400 }}>
                        <Toolbar className="mb-4" left={(
                            <React.Fragment>
                                <div className="my-2">
                                    <Button label="Ajouter" icon="pi pi-plus" className="p-button-success mr-2" onClick={e => { this.setState({ visible_dlg_insc: true, modif_insc: false }) }} style={{ width: 115 }} />
                                    <Button label="Supprimer" disabled={this.state.selectedInsc == null} icon="pi pi-trash" className="p-button-danger" onClick={(e) => this.conf_delete_data(this.state.selectedInsc)} style={{ width: 115 }} />
                                </div>
                            </React.Fragment>
                        )
                        } right={
                            <React.Fragment>
                                {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                            </React.Fragment>
                        }></Toolbar>
                        <DataTable value={this.state.liste_parcours} paginatorPosition="bottom" selectionMode="single" paginator={true} rows={10}
                            alwaysShowPaginator={false} onRowClick={e => this.setState({ liste_parcours: e.data })} onRowSelect={e => this.setState({ liste_parcours: e.data })}
                            responsive={true} selection={this.state.selectedAbonnement} className="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-customers" >
                            <Column header="Action" body={this.actionTemplateInsc.bind(this)} style={{ width: '8%' }} />
                            <Column field="annee_insc" header='Année' style={{ width: '10%' }} />
                            <Column field="classeId.libelle" filter={true} filterMatchMode='contains' header="Classe" style={{ width: '10%' }} />
                            <Column field="ecoleId.libelle" filter={true} filterMatchMode='contains' header="Ecole" />
                        </DataTable>
                    </div>
                </Dialog>


                {/* Boite de dialogue d'ajout et de modification d'un abonné  */}
                <Dialog visible={this.state.visible_dlg_form} style={{ width: '80%' }} header={this.state.modif_data == true ? "Modification" : "Ajout"} modal className="p-fluid" onHide={(e) => this.setState({ visible_dlg_form: false })}
                    footer={<>
                        <Button label="Fermer" loading={this.state.showindicator} icon="pi pi-times" className="p-button-secondary" style={{ width: 120 }} onClick={(e) => this.setState({ visible_dlg_form: false })} />
                        <Button label="Valider" loading={this.state.showindicator} icon="pi pi-check" onClick={(e) => this.enreg_data()} style={{ width: 120 }} />
                    </>}>
                    <div className="p-fluid">
                        <div className="grid">
                            <div className="col-12 md:col-10">
                                <div style={{ backgroundColor: '#e3edf8', padding: 7, marginBottom: 0, borderBottom: "2px solid #142d5e", width: '100%', paddingRight: 10 }}>
                                    <i style={{ color: '#000' }} className="pi pi-search"></i> Recherche
                                    <div className="formgrid grid" >
                                        <div className="field col-3">
                                            <Dropdown autoWidth={false} value={this.state.rsession} filter={true} filterBy='label' filterMatchMode='contains' placeholder='Session'
                                                options={this.appservice.get_liste_session_exam()} onChange={(e) => this.setState({ rsession: e.value }, () => this.get_liste_dce())} appendTo={document.body} />
                                        </div>
                                        <div className="field col-3">
                                            <Dropdown autoWidth={false} value={this.state.rdce} placeholder='DCE/DPE' filter={true} filterBy='label' filterMatchMode='contains'
                                                options={this.state.liste_dce} onChange={(e) => this.setState({ rdce: e.value })} appendTo={document.body} />
                                        </div>
                                        <div className="field col-3">
                                            <InputText id="rpv" placeholder='PV ou matricule' value={this.state.rpv} onChange={(e) => this.setState({ rpv: e.target.value })} />
                                        </div>
                                        <div className="field col-3">
                                            <Button label="Rechercher" loading={this.state.showindicator} icon="pi pi-search" onClick={(e) => this.search()} style={{ width: 150 }} />

                                        </div>
                                    </div>
                                </div>
                                <div style={{ backgroundColor: '#f3f7fb', height: 320, padding: 20, border: '4px solid #f5f5f5' }}>

                                    <div className="formgrid grid" >

                                        <div className="field col-4" style={{ padding: 4 }}>
                                            <span className="p-float-label">
                                                <InputText id="name" value={this.state.formData.nom} className={classNames({ 'p-invalid': this.state.submit && !this.state.formData.nom })}
                                                    onChange={(e) => this.setState({ formData: this.appservice.onInputChange(e, 'nom', '', this.state.formData) })}
                                                    required ref={(el) => { this.inputNom = el; }} disabled={this.is_disable()} />
                                                <label htmlFor="nomCli">Nom *</label>
                                            </span>
                                            {this.state.submit && !this.state.formData.nom && <small className="p-invalid" style={{ color: 'red' }}>Le nom est obligatoire</small>}
                                        </div>

                                        <div className="field col-4" style={{ padding: 4 }}>
                                            <span className="p-float-label">
                                                <InputText id="name" value={this.state.formData.prenom} className={classNames({ 'p-invalid': this.state.submit && !this.state.formData.prenom })}
                                                    onChange={(e) => this.setState({ formData: this.appservice.onInputChange(e, 'prenom', '', this.state.formData) })}
                                                    required disabled={this.is_disable()} />
                                                <label htmlFor="prenomCli">Prénom *</label>
                                            </span>
                                            {this.state.submit && !this.state.formData.prenom && <small className="p-invalid" style={{ color: 'red' }}>Le prénom est obligatoire</small>}
                                        </div>

                                        <div className="field col-4" style={{ padding: 4 }}>
                                            <span className="p-float-label">
                                                <InputMask mask="999999999" id="name" value={this.state.formData.telephone} className={classNames({ 'p-invalid': this.state.submit && !this.state.formData.telephone })}
                                                    onChange={(e) => this.setState({ formData: this.appservice.onInputChange(e, 'telephone', '', this.state.formData) })}
                                                    required />
                                                <label htmlFor="telCli">N° téléphone *</label>
                                            </span>
                                            {this.state.submit && !this.state.formData.telephone && <small className="p-invalid" style={{ color: 'red' }}>La numéro de téléphone est obligatoire.  </small>}
                                        </div>

                                        <div className="field col-4" style={{ padding: 4 }}>
                                            <span className="p-float-label">
                                                <InputMask mask="99/99/9999" id="name" value={this.state.formData.date_naiss} className={classNames({ 'p-invalid': this.state.submit && !this.state.formData.date_naiss })}
                                                    onChange={(e) => this.setState({ formData: this.appservice.onInputChange(e, 'date_naiss', '', this.state.formData) })}
                                                    required disabled={this.is_disable()} />
                                                <label htmlFor="dateNaissCli">Date naissance  *</label>
                                            </span>
                                            {this.state.submit && !this.state.formData.date_naiss && <small className="p-invalid" style={{ color: 'red' }}>La date de naissance est obligatoire.  </small>}
                                        </div>

                                        <div className="field col-4" style={{ padding: 4 }}>
                                            <span className="p-float-label">
                                                <InputText id="name" value={this.state.formData.lieu_naiss} className={classNames({ 'p-invalid': this.state.submit && !this.state.formData.lieu_naiss })}
                                                    onChange={(e) => this.setState({ formData: this.appservice.onInputChange(e, 'lieu_naiss', '', this.state.formData) })}
                                                    required disabled={this.is_disable()} />
                                                <label htmlFor="lieuNaissCli">Lieu naissance *</label>
                                            </span>
                                            {this.state.submit && !this.state.formData.lieu_naiss && <small className="p-invalid" style={{ color: 'red' }}>Le lieu de naissance est obligatoire.</small>}
                                        </div>

                                        <div className="field col-4" style={{ padding: 4 }}>
                                            <span className="p-float-label">
                                                <Dropdown autoWidth={false} value={this.state.formData.sexe} filter={true} filterBy='label' filterMatchMode='contains'
                                                    options={[
                                                        { label: 'Masculin', value: 'Masculin' },
                                                        { label: 'Feminin', value: 'Feminin' },
                                                    ]}
                                                    onChange={(e) => this.setState({ formData: this.appservice.onInputChange(e, 'sexe', '', this.state.formData) })}
                                                    appendTo={document.body} className={classNames({ 'p-invalid': this.state.submit && !this.state.formData.sexe })} disabled={this.is_disable()} />
                                                <label htmlFor="sexeCli">Sexe *</label>
                                            </span>
                                            {this.state.submit && !this.state.formData.sexe && <small className="p-invalid" style={{ color: 'red' }}>Le sexe est obligatoire </small>}
                                        </div>

                                        {this.appservice.get_userconnected().profil == 'Gestionnaire' ? null :
                                            <div className="field col-4" style={{ padding: 4 }}>
                                                <span className="p-float-label">
                                                    <Dropdown id='pidCrd' options={this.state.liste_crd} placeholder="Sélectionner" value={this.state.pidCrd} onChange={(e) => this.setState({ pidCrd: e.value, liste_ecole: [], pidClasse: null, pidEcole: null }, () => {
                                                        this.get_liste_ecole();
                                                    })} autoWidth={false} style={{ width: '100%' }} filter={true} filterBy='label' filterMatchMode='contains' />
                                                    <label htmlFor="pidCrd">CRD *</label>
                                                </span>
                                            </div>
                                        }

                                        <div className="field col-4" style={{ padding: 4 }}>
                                            <span className="p-float-label">
                                                <Dropdown id='pidEcole' options={this.state.liste_ecole}  value={this.state.formInsc.ecoleId.id}
                                                    onChange={(e) => this.setState({ formInsc: this.appservice.onInputChange(e, 'ecoleId', 'fk', this.state.formInsc) })} autoWidth={false} style={{ width: '100%' }} 
                                                    filter={true} filterBy='label' filterMatchMode='contains' className={classNames({ 'p-invalid': this.state.submit && !this.state.formInsc.ecoleId.id })}/>
                                                <label htmlFor="pidEcole">Ecole *</label>
                                            </span>
                                        </div>

                                        <div className="field col-4" style={{ padding: 4 }}>
                                            <span className="p-float-label">
                                                <Dropdown autoWidth={false} value={this.state.formInsc.classeId.id} filter={true} filterBy='label' filterMatchMode='contains'
                                                    options={this.state.liste_classe}
                                                    onChange={(e) => this.setState({ formInsc: this.appservice.onInputChange(e, 'classeId', 'fk', this.state.formInsc) })}
                                                    appendTo={document.body} className={classNames({ 'p-invalid': this.state.submit && !this.state.formInsc.classeId.id })} />
                                                <label htmlFor="sexeCli">Classe *</label>
                                            </span>
                                            {this.state.submit && !this.state.formInsc.classeId.id && <small className="p-invalid" style={{ color: 'red' }}>Le genre est obligatoire </small>}
                                        </div>

                                        <div className="field col-4" style={{ padding: 4 }}>
                                            <span className="p-float-label">
                                                <Dropdown autoWidth={false} value={this.state.formInsc.statut} filter={true} filterBy='label' filterMatchMode='contains'
                                                    options={[
                                                        { label: 'P', value: 'P' },
                                                        { label: 'R', value: 'R' },
                                                        { label: 'L', value: 'L' },
                                                    ]}
                                                    onChange={(e) => this.setState({ formInsc: this.appservice.onInputChange(e, 'statut', '', this.state.formInsc) })}
                                                    appendTo={document.body} className={classNames({ 'p-invalid': this.state.submit && !this.state.formInsc.statut })} />
                                                <label htmlFor="sexeCli">Statut *</label>
                                            </span>
                                            {this.state.submit && !this.state.formInsc.statut && <small className="p-invalid" style={{ color: 'red' }}>Le statut est obligatoire </small>}
                                        </div>

                                        <div className="field col-4" style={{ padding: 4 }}>
                                            <span className="p-float-label">
                                                <InputText id="name" value={this.state.formData.pere} className={classNames({ 'p-invalid': this.state.submit && !this.state.formData.pere })}
                                                    onChange={(e) => this.setState({ formData: this.appservice.onInputChange(e, 'pere', '', this.state.formData) })}
                                                    required disabled={this.is_disable()} />
                                                <label htmlFor="lieuNaissCli">Père *</label>
                                            </span>
                                            {this.state.submit && !this.state.formData.pere && <small className="p-invalid" style={{ color: 'red' }}>Le nom du père est obligatoire.</small>}
                                        </div>

                                        <div className="field col-4" style={{ padding: 4 }}>
                                            <span className="p-float-label">
                                                <InputText id="name" value={this.state.formData.mere} className={classNames({ 'p-invalid': this.state.submit && !this.state.formData.mere })}
                                                    onChange={(e) => this.setState({ formData: this.appservice.onInputChange(e, 'mere', '', this.state.formData) })}
                                                    required disabled={this.is_disable()} />
                                                <label htmlFor="lieuNaissCli">Mère *</label>
                                            </span>
                                            {this.state.submit && !this.state.formData.mere && <small className="p-invalid" style={{ color: 'red' }}>Le nom de la manan est obligatoire.</small>}
                                        </div>

                                        <div className="field col-4" style={{ padding: 4 }}>
                                            <span className="p-float-label">
                                                <InputMask mask="999999999" id="name" value={this.state.formData.tel_parent} className={classNames({ 'p-invalid': this.state.submit && !this.state.formData.tel_parent })}
                                                    onChange={(e) => this.setState({ formData: this.appservice.onInputChange(e, 'tel_parent', '', this.state.formData) })}
                                                    required />
                                                <label htmlFor="telCli">N° téléphone parent</label>
                                            </span>
                                            {this.state.submit && !this.state.formData.tel_parent && <small className="p-invalid" style={{ color: 'red' }}>La numéro de téléphone des parent est obligatoire.  </small>}
                                        </div>

                                        <div className="field col-4" style={{ padding: 4 }}>
                                            <span className="p-float-label">
                                                <Dropdown autoWidth={false} value={this.state.formInsc.annee_insc} filter={true} filterBy='label' filterMatchMode='contains'
                                                    options={this.appservice.get_liste_session()} disabled
                                                    onChange={(e) => this.setState({ formInsc: this.appservice.onInputChange(e, 'annee_insc', '', this.state.formInsc) })}
                                                    appendTo={document.body} className={classNames({ 'p-invalid': this.state.submit && !this.state.formInsc.annee_insc })} />
                                                <label htmlFor="sexeCli">Année scolaire *</label>
                                            </span>
                                            {this.state.submit && !this.state.formInsc.annee_insc && <small className="p-invalid" style={{ color: 'red' }}>Le statut est obligatoire </small>}
                                        </div>

                                        <div className="field col-4" style={{ padding: 4 }}>
                                            <span className="p-float-label">
                                                <Dropdown autoWidth={false} value={this.state.formInsc.statut_academique} filter={true} filterBy='label' filterMatchMode='contains'
                                                    options={[
                                                        { label: 'Normal', value: 'Normal' },
                                                        { label: 'Transféré', value: 'Transféré' },
                                                        { label: 'Décédé', value: 'Décédé' },
                                                        { label: 'Abandon', value: 'Abandon' },
                                                    ]}
                                                    onChange={(e) => this.setState({ formInsc: this.appservice.onInputChange(e, 'statut_academique', '', this.state.formInsc) })}
                                                    appendTo={document.body} className={classNames({ 'p-invalid': this.state.submit && !this.state.formInsc.statut_academique })} />
                                                <label htmlFor="sexeCli">Statut accadémique *</label>
                                            </span>
                                            {this.state.submit && !this.state.formInsc.statut_academique && <small className="p-invalid" style={{ color: 'red' }}>Le statut accadémique est obligatoire </small>}
                                        </div>


                                        {/* <div className="field col-4" style={{ padding: 4 }}>
                                            <span className="p-float-label">
                                                <InputNumber id="moy" value={this.state.formInsc.moy}
                                                    onChange={(e) => this.setState({ formInsc: this.appservice.onInputChange(e, 'moy', '', this.state.formInsc) })}
                                                />
                                                <label htmlFor="moy">Moyenne annuelle </label>
                                            </span>
                                        </div> */}

                                    </div>


                                </div>
                            </div>
                            <div className="col-12 md:col-2">
                                <center>
                                    <div className='p-fluid' >

                                        <div className="field" style={{ marginBottom: -10 }}>
                                            <label>PHOTO *</label>
                                            <a onClick={() => this.openFileUpload()} style={{ cursor: 'pointer' }}>
                                                <div style={{ height: 100, width: 95, borderRadius: 10 }} >
                                                    <center>
                                                        {this.state.urlfile == null ?
                                                            <div style={{ width: '95px', height: '100px', borderRadius: 10, backgroundColor: '#f3f7fb', display: 'flex', justifyContent: 'center', padding: 30 }} ><label style={{ color: '#003366', fontWeight: 'bold', cursor: 'pointer' }}>
                                                                <i style={{ fontSize: 30, color: '#000' }} className="pi pi-camera"></i>
                                                            </label></div> :
                                                            <img src={this.state.urlfile} style={{ width: '95px', height: '100px', borderRadius: 10, backgroundColor: '#f3f7fb', border: '1px solid #CCC' }} />}
                                                    </center>
                                                </div>
                                            </a>
                                            <input type="file" id="hiddenFile" style={{ visibility: 'hidden' }} onChange={(e) => {
                                                if (e.target.files && e.target.files[0]) {
                                                    this.setState({ file: e.target.files[0] }, () => {
                                                        let url = URL.createObjectURL(this.state.file);
                                                        this.setState({ urlfile: url });
                                                    })
                                                }
                                            }} />
                                        </div>


                                        <div className="field" style={{ marginTop: 20 }}>
                                            <label>EXTRAIT NAISSANCE</label>
                                            <a onClick={() => this.openFileUploadExn()} style={{ cursor: 'pointer' }}>
                                                <div style={{ height: 100, width: 95, borderRadius: 10 }} >
                                                    <center>
                                                        {this.state.urlfileExn == null ?
                                                            <div style={{ width: '95px', height: '100px', borderRadius: 10, backgroundColor: '#f3f7fb', display: 'flex', justifyContent: 'center', padding: 30 }} ><label style={{ color: '#003366', fontWeight: 'bold', cursor: 'pointer' }}>
                                                                <i style={{ fontSize: 30, color: '#000' }} className="pi pi-camera"></i>
                                                            </label></div> :
                                                            <img id="footer-logo" src="assets/layout/images/doc.png" alt="diamond-layout" style={{ width: '120px', height: '100px', borderRadius: 10, backgroundColor: '#f3f7fb', border: '1px solid #CCC' }} />
                                                        }
                                                    </center>
                                                </div>
                                            </a>
                                            <input type="file" id="hiddenFileExn" style={{ visibility: 'hidden' }} onChange={(e) => {
                                                if (e.target.files && e.target.files[0]) {
                                                    this.setState({ fileExn: e.target.files[0], urlfileExn: 'OK' })
                                                }
                                            }} />
                                        </div>

                                    </div>
                                </center>
                            </div>
                        </div>


                    </div>
                </Dialog>
            </div>
        );
    }
}