import React, { useRef, useState, useEffect } from 'react';
import { classNames } from 'primereact/utils';
import { Route, useLocation } from 'react-router-dom';

import AppTopbar from './AppTopbar';
import AppBreadcrumb from './AppBreadcrumb';
import AppFooter from './AppFooter';
import AppMenu from './AppMenu';

import Dashboard from './components/Dashboard';

import FormClasse from './components/FormClasse';
import FormCommune from './components/FormCommune';
import FormRegion from './components/FormRegion';
import FormPrefecture from './components/FormPrefecture';
import FormQuartier from './components/FormQuartier';
import FormEcole from './components/FormEcole';
import FormInscription from './components/FormInscription';
import FormUtlisateur from './components/FormUtlisateur';
import EditionStatEcole from './components/EditionStatEcole';
import EditionStatEffEcole from './components/EditionStatEffEcole';
import EditionStatEffClasse from './components/EditionStatEffClasse';
import FormNote from './components/FormNote';
import FormTransfert from './components/FormTransfert';
import FormMatiere from './components/FormMatiere';

/* 

import EditionStatEffClasse from './components/EditionStatEffClasse';
import FormTransfert from './components/FormTransfert';
import EtatPaye from './components/EtatPaye';

*/


import PrimeReact from 'primereact/api';
import { Tooltip } from 'primereact/tooltip';

import { ProgressBar } from 'primereact/progressbar'

import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import './App.scss';



import AppServices from './service/AppServices';

const App = () => {
    const appservice = new AppServices();
    const [layoutMode, setLayoutMode] = useState('horizontal');
    const [overlayMenuActive, setOverlayMenuActive] = useState(false);
    const [staticMenuDesktopInactive, setStaticMenuDesktopInactive] = useState(false);
    const [staticMenuMobileActive, setStaticMenuMobileActive] = useState(false);
    const [topbarMenuActive, setTopbarMenuActive] = useState(false);
    const [activeTopbarItem, setActiveTopbarItem] = useState(null);
    const [menuActive, setMenuActive] = useState(false);
    const [themeColor, setThemeColor] = useState('blue');
    const [inputStyle, setInputStyle] = useState('outlined');
    const [ripple, setRipple] = useState(false);
    const [scheme, setScheme] = useState('light');
    const copyTooltipRef = useRef();
    const location = useLocation();

    let menuClick;
    let topbarItemClick;

    var menu = [];
    var profil = '';
    var nom = '';
    var userconnected = appservice.get_userconnected();

    if (userconnected !== null && userconnected !== undefined) {
        profil = userconnected.profil;
        nom = userconnected.prenom + ' ' + userconnected.nom;
    }

    if (profil == 'Administrateur') {
        menu = [
            { label: 'Tableau de bord', icon: 'pi pi-fw pi-home', to: '/dashboard' },
            { label: 'Gestion inscription', icon: 'pi pi-fw pi-book', to: '/inscriptions' },
            { label: 'Gestion transferts', icon: 'pi pi-fw pi-arrow-up-right', to: '/transferts' },
            { label: 'Gestion notes', icon: 'pi pi-fw pi-pencil', to: '/notes' },
            {
                label: "Paramétrage", icon: "pi pi-cog",
                items: [
                    { label: "Ecoles", icon: "pi pi-fw pi-building", to: "/ecoles" },
                    { label: "Classes", icon: "pi pi-fw pi-box", to: "/classes" },
                    { label: "Matières", icon: "pi pi-fw pi-box", to: "/matieres" },
                    { label: "Utilisateurs", icon: "pi pi-fw pi-user", to: "/users" },
                    {
                        label: 'Atlas géographique', icon: 'pi pi-fw pi-sitemap',
                        items: [
                            { label: 'Regions', icon: 'pi pi-fw pi-cog', to: '/regions' },
                            { label: 'Préfectures', icon: 'pi pi-fw pi-cog', to: '/prefectures' },
                            { label: 'Communes', icon: 'pi pi-fw pi-cog', to: '/communes' },
                            { label: 'Quartiers', icon: 'pi pi-fw pi-cog', to: '/quartiers' },
                        ]
                    },
                ]
            },
            {
                label: "Reporting", icon: "pi pi-fw pi-chart-line", className: 'rotated-icon',
                items: [
                    { label: "Ecoles par préfecture", icon: "pi pi-fw pi-chart-line", to: '/statecole' },
                    { label: "Inscriptions par ecoles", icon: "pi pi-fw pi-chart-line", to: '/stateffecole' },
                    { label: "Inscriptions par classe", icon: "pi pi-fw pi-chart-line", to: '/stateffclasse' },
                ]
            },
        ];
    }else if (profil == 'Gestionnaire') {
        menu = [
            { label: 'Tableau de bord', icon: 'pi pi-fw pi-home', to: '/dashboard' },
            { label: 'Gestion inscription', icon: 'pi pi-fw pi-pencil', to: '/inscriptions' },
            { label: 'Gestion transferts', icon: 'pi pi-fw pi-arrow-up-right', to: '/transferts' },
            { label: 'Gestion notes', icon: 'pi pi-fw pi-pencil', to: '/notes' },            
        ];
    }else if (profil == 'Inspecteur') {
        menu = [
            { label: 'Tableau de bord', icon: 'pi pi-fw pi-home', to: '/dashboard' },
            { label: 'Gestion inscription', icon: 'pi pi-fw pi-pencil', to: '/inscriptions' },
            {
                label: "Reporting", icon: "pi pi-fw pi-chart-line", className: 'rotated-icon',
                items: [
                    { label: "Ecoles par préfecture", icon: "pi pi-fw pi-chart-line", to: '/statecole' },
                    { label: "Inscriptions par ecoles", icon: "pi pi-fw pi-chart-line", to: '/stateffecole' },
                    { label: "Inscriptions par classe", icon: "pi pi-fw pi-chart-line", to: '/stateffclasse' },
                ]
            },
        ];
    }else if (profil == 'Agent de saisie') {
        menu = [
            { label: 'Tableau de bord', icon: 'pi pi-fw pi-home', to: '/dashboard' },
            { label: 'Gestion inscription', icon: 'pi pi-fw pi-pencil', to: '/inscriptions' },
            { label: 'Gestion moyennes', icon: 'pi pi-fw pi-pencil', to: '/notes' },
        ];
    }
    useEffect(() => {
        copyTooltipRef && copyTooltipRef.current && copyTooltipRef.current.updateTargetEvents();
    }, [location]);

    const onInputStyleChange = (inputStyle) => {
        setInputStyle(inputStyle);
    }

    const onRippleChange = (e) => {
        PrimeReact.ripple = e.value;
        setRipple(e.value);
    }

    const onMenuClick = (event) => {
        menuClick = true;
    }

    const onMenuButtonClick = (event) => {
        menuClick = true;
        setTopbarMenuActive(false);

        if (layoutMode === 'overlay' && !isMobile()) {
            setOverlayMenuActive(prevState => !prevState);
        } else {
            if (isDesktop())
                setStaticMenuDesktopInactive(prevState => !prevState);
            else
                setStaticMenuMobileActive(prevState => !prevState);
        }

        event.preventDefault();
    }

    const onTopbarMenuButtonClick = (event) => {
        topbarItemClick = true;
        setTopbarMenuActive(prevState => !prevState)
        hideOverlayMenu();
        event.preventDefault();
    }

    const onTopbarItemClick = (event) => {
        topbarItemClick = true;

        if (activeTopbarItem === event.item)
            setActiveTopbarItem(null);
        else
            setActiveTopbarItem(event.item);

        event.originalEvent.preventDefault();
    }

    const onMenuItemClick = (event) => {
        if (!event.item.items) {
            hideOverlayMenu();
        }
        if (!event.item.items && (isHorizontal() || isSlim())) {
            setMenuActive(false);
        }
    }

    const onRootMenuItemClick = (event) => {
        setMenuActive(prevState => !prevState);
    }

    const onDocumentClick = (event) => {
        if (!topbarItemClick) {
            setActiveTopbarItem(null)
            setTopbarMenuActive(false)
        }

        if (!menuClick) {
            if (isHorizontal() || isSlim()) {
                setMenuActive(false)
            }

            hideOverlayMenu();
        }

        topbarItemClick = false;
        menuClick = false;
    }

    const isMenuVisible = () => {
        if (isDesktop()) {
            if (layoutMode === 'static')
                return !staticMenuDesktopInactive;
            else if (layoutMode === 'overlay')
                return overlayMenuActive;
            else
                return true;
        }
        else {
            return true;
        }
    };

    const hideOverlayMenu = () => {
        setOverlayMenuActive(false);
        setStaticMenuMobileActive(false)
    }

    const isMobile = () => {
        return window.innerWidth < 1025;
    }

    const isDesktop = () => {
        return window.innerWidth > 1024;
    }

    const isHorizontal = () => {
        return layoutMode === 'horizontal';
    }

    const isSlim = () => {
        return layoutMode === 'slim';
    }

    const changeMenuMode = (event) => {
        setLayoutMode(event.menuMode);
        setStaticMenuDesktopInactive(false);
        setOverlayMenuActive(false);
    }

    const onSchemeChange = (color) => {
        setScheme(color);
        const themeLink = document.getElementById('theme-css');
        const href = themeLink.href;
        const themeFile = href.substring(href.lastIndexOf('/') + 1, href.lastIndexOf('.'));
        const themeTokens = themeFile.split('-');
        const themeName = themeTokens[1];
        changeTheme(themeName + '-' + color);
        changeLogo(color);
    }

    const changeTheme = (theme) => {
        setThemeColor(theme.split('-')[0]);
        changeStyleSheetUrl('layout-css', theme, 'layout');
        changeStyleSheetUrl('theme-css', theme, 'theme');
    }

    const onThemeChange = (theme) => {
        setThemeColor(theme)
        changeTheme(theme + '-' + scheme);
    }

    const changeStyleSheetUrl = (id, value, prefix) => {
        let element = document.getElementById(id);
        let urlTokens = element.getAttribute('href').split('/');
        urlTokens[urlTokens.length - 1] = prefix + '-' + value + '.css';
        let newURL = urlTokens.join('/');

        replaceLink(element, newURL);
    }

    const changeLogo = (scheme) => {
        const invoiceLogoLink = document.getElementById("invoice-logo");
        const logoUrl = `assets/layout/images/logo-${scheme === 'light' ? 'dark' : 'white'}.png`;

        if (invoiceLogoLink) {
            invoiceLogoLink.src = logoUrl;
        }
    };

    const isIE = () => {
        return /(MSIE|Trident\/|Edge\/)/i.test(window.navigator.userAgent)
    }

    const replaceLink = (linkElement, href) => {
        if (isIE()) {
            linkElement.setAttribute('href', href);
        }
        else {
            const id = linkElement.getAttribute('id');
            const cloneLinkElement = linkElement.cloneNode(true);

            cloneLinkElement.setAttribute('href', href);
            cloneLinkElement.setAttribute('id', id + '-clone');

            linkElement.parentNode.insertBefore(cloneLinkElement, linkElement.nextSibling);

            cloneLinkElement.addEventListener('load', () => {
                linkElement.remove();
                cloneLinkElement.setAttribute('id', id);
            });
        }
    }

    const layoutClassName = classNames('layout-wrapper', {
        'layout-horizontal': layoutMode === 'horizontal',
        'layout-overlay': layoutMode === 'overlay',
        'layout-static': layoutMode === 'static',
        'layout-slim': layoutMode === 'slim',
        'layout-static-inactive': staticMenuDesktopInactive && layoutMode !== 'slim',
        'layout-mobile-active': staticMenuMobileActive,
        'layout-overlay-active': overlayMenuActive,
        'p-input-filled': inputStyle === 'filled'
    });

    const menuContainerClassName = classNames('layout-menu-container', { 'layout-menu-container-inactive': !isMenuVisible() })

    return (
        <div className={layoutClassName} onClick={onDocumentClick}>
            <Tooltip ref={copyTooltipRef} target=".block-action-copy" position="bottom" content="Copied to clipboard" event="focus" />


            <AppTopbar topbarMenuActive={topbarMenuActive} activeTopbarItem={activeTopbarItem}
                onMenuButtonClick={onMenuButtonClick}
                onTopbarMenuButtonClick={onTopbarMenuButtonClick}
                onTopbarItemClick={onTopbarItemClick} />

            <div className={menuContainerClassName} onClick={onMenuClick}>
                <div className="layout-menu-content">
                    <AppMenu model={menu} onMenuItemClick={onMenuItemClick}
                        onRootMenuItemClick={onRootMenuItemClick}
                        layoutMode={layoutMode} active={menuActive} />
                    <div className="layout-menu-footer">
                        <div className="layout-menu-footer-title">TASKS</div>

                        {/* <div className="layout-menu-footer-content">
                            <ProgressBar value={50} showValue={false}></ProgressBar>
                            Today
                            <ProgressBar value={80} showValue={false}></ProgressBar>
                            Overall
                        </div> */}
                    </div>
                </div>
            </div>

            <div className="layout-content">
                <AppBreadcrumb />

                <div className="layout-content-container">
                    <Route path="/dashboard" exact render={() => <Dashboard colorMode={scheme} location={location} />} />
                    <Route path="/regions" component={FormRegion} />
                    <Route path="/prefectures" component={FormPrefecture} />
                    <Route path="/communes" component={FormCommune} />
                    <Route path="/quartiers" component={FormQuartier} />
                    <Route path="/ecoles" component={FormEcole} />
                    <Route path="/inscriptions" component={FormInscription} />
                    <Route path="/classes" component={FormClasse} />
                    <Route path="/users" component={FormUtlisateur} />
                    <Route path="/statecole" component={EditionStatEcole} />
                    <Route path="/stateffecole" component={EditionStatEffEcole} />
                    <Route path="/stateffclasse" component={EditionStatEffClasse} />
                    <Route path="/notes" component={FormNote} /> 
                    <Route path="/transferts" component={FormTransfert} /> 
                    <Route path="/matieres" component={FormMatiere} /> 
                    {/* 
                   
                    
                    
                   
                    
                    <Route path="/classes" component={FormMatiere} /> 
                    <Route path="/classes" component={FormUtlisateur} /> 
                    <Route path="/classes" component={FormUtlisateur} /> 
                    <Route path="/classes" component={FormUtlisateur} /> 
                    <Route path="/classes" component={FormUtlisateur} /> 
                    */}

                </div>

                <AppFooter />

                {staticMenuMobileActive && <div className="layout-mask"></div>}
            </div>



        </div>
    );

}

export default App;
