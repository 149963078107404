import React, { Component } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { classNames } from 'primereact/utils';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Toolbar } from 'primereact/toolbar';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import AppServices from '../service/AppServices';
import { Dropdown } from 'primereact/dropdown';
import { SplitButton } from 'primereact/splitbutton';

/* Page de gestion des communes */
export default class FormCommune extends Component {
    constructor() {
        super();
        this.state = {
            selectedData: null,
            liste_data: [],
            formData: {
                id: 0,
                libelle: "",
                iduser: "",
                prefectureId: { id: '' }
            },
            formQuartier: {
                id: 0,
                libelle: "",
                iduser: "",
                communeId: { id: '' }
            },
            liste_prefecture: [],

            showindicator: false,
            modif_data: false,
            submit: false,
            visible_dlg_delete: false,
            visible_dlg_form: false,
            visible_dlg_form_com: false,
            visible_dlg_detail: false,
        };
        this.edit_data = this.edit_data.bind(this);
        this.conf_delete_data = this.conf_delete_data.bind(this);
        this.toogleDlg = this.toogleDlg.bind(this);


        this.appservice = new AppServices();
    }


    /* Natif react js (s'exécute au montage ou chargement de la page )*/
    componentDidMount() {
        this.get_liste_data();
        this.get_liste_prefecture();
    }

    /* Récupération de la liste globale des communes */
    get_liste_data() {
        this.setState({ showindicator: true });
        this.appservice.get_liste_commune().then(data => {
            this.setState({ liste_data: data, showindicator: false });
        });
    }
    /* Récupération de la liste globale des prefectures */
    get_liste_prefecture() {
        this.setState({ showindicator: true });
        this.appservice.get_liste_prefecture().then(data => {
            this.setState({ liste_prefecture: data, showindicator: false });
        });
    }

    /* Annuler l'opération en cours et vider le formulaire */
    annuler() {
        this.setState({
            formData: {
                id: 0,
                libelle: "",
                iduser: "",
                prefectureId: { id: '' }
            },
            formQuartier: {
                id: 0,
                libelle: "",
                iduser: "",
                communeId: { id: '' }
            },
        });

        this.setState({ showindicator: false });
        this.setState({ modif_data: false });
        this.setState({ visible_dlg_delete: false });
        this.setState({ visible_dlg_form: false });
        this.setState({ visible_dlg_form_com: false });
    }

    /* Remplissage du formulaire avec les informations de la ligne sélectionnée dans le cadre de la modification  */
    edit_data(data) {
        if (data !== null && data !== undefined) {
            this.setState({ formData: data });
            this.setState({ modif_data: true });
            this.setState({ visible_dlg_form: true });
            this.setState({ selectedData: data });
        }
    }

    /* Enregistrer les informations du formulaire commune */
    enreg_data() {
        this.setState({ submit: true });
        if (this.state.formData.libelle == "" || this.state.formData.prefectureId.id == 0) {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez renseigner tous les champs obligatoires' });
        } else {
            this.appservice.save_commune(this.state.formData).then(reponse => {
                if (reponse != undefined && reponse != null) {
                    this.resultat(reponse);
                    if (reponse.success == true) {
                        this.get_liste_data();
                    }
                }
            });
        }
    }

    /* Enregistrer les informations du formulaire quartier */
    enreg_data_quartier() {
        this.setState({ submit: true });
        if (this.state.formQuartier.libelle == "" || this.state.formQuartier.code == 0 || this.state.formQuartier.communeId.id == 0) {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez renseigner tous les champs obligatoires' });
        } else {
            this.appservice.save_quartier(this.state.formQuartier).then(reponse => {
                if (reponse != undefined && reponse != null) {
                    this.resultat(reponse);
                }
            });
        }
    }

    /* Affichage de la boite de dialogue de confirmation de suppression */
    conf_delete_data(data) {
        this.setState({ visible_dlg_delete: true });
        this.setState({ selectedData: data });
    }

    /* Affichage de la boite de dialogue pour l'ajout d'une nouvelle commune */
    toogleDlg() {
        this.annuler();
        this.setState({ visible_dlg_form: !this.state.visible_dlg_form });
    }

    /* Zone gauche de la toolbar */
    leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <Button label="Ajouter" icon="pi pi-plus" className="p-button-success mr-2" onClick={this.toogleDlg} style={{ width: 115 }} />
                    <Button label="Supprimer" disabled={this.state.selectedData == null} icon="pi pi-trash" className="p-button-danger" onClick={(e) => this.conf_delete_data(this.state.selectedData)} style={{ width: 115 }} />
                </div>
            </React.Fragment>
        )

    }

    /* Zone droite de la toolbar */
    rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : <Button label="Export" icon="pi pi-upload" className="p-button-help" onClick={this.exportCSV} />}
            </React.Fragment>
        )
    }

    /* Enregistrer le fichier sur le client */
    saveAsExcelFile = (buffer, fileName) => {
        import('file-saver').then(module => {
            if (module && module.default) {
                let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                let EXCEL_EXTENSION = '.xlsx';
                const data = new Blob([buffer], {
                    type: EXCEL_TYPE
                });

                module.default.saveAs(data, fileName + new Date().getTime() + EXCEL_EXTENSION);
            }
        });
    }

    /* Exportation du tableau contenant la liste des quartiers en fichier CSV */
    exportCSV = () => {
        import('xlsx').then(xlsx => {
            const worksheet = xlsx.utils.json_to_sheet(this.state.liste_data);
            const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
            const excelBuffer = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
            this.saveAsExcelFile(excelBuffer, 'LISTE_COMMUNE_');
        });
    }


    /* Fonction d'affichage des messages retournés suite aux actions serveurs */
    resultat(rep) {
        if (rep.success == true) {
            this.toast.show({ severity: 'success', summary: 'Success', detail: rep.message });
            this.annuler();
        } else {
            this.toast.show({ severity: 'error', summary: 'Error', detail: rep.message });
        }
        this.setState({ submit: false });
    }

    /* Colonne Action du tableau contenant la liste des communes */
    actionTemplate(rowData, column) {
        const items = [
            {
                label: 'Modifier',
                icon: 'pi pi-pencil',
                command: (e) => {
                    this.edit_data(rowData);
                }
            },
            {
                label: 'Supprimer',
                icon: 'pi pi-times',
                command: (e) => {
                    this.conf_delete_data(rowData);
                }
            },
            {
                label: 'Ajouter une quartier',
                icon: 'pi pi-plus',
                command: (e) => {
                    this.state.formQuartier.communeId.id = rowData.id;
                    this.setState({ selectedData: rowData });
                    this.setState({ visible_dlg_form_com: true })
                }
            }
        ]
        return <SplitButton label="Modifier" icon="pi pi-pencil" onClick={(e) => this.edit_data(rowData)} model={items}></SplitButton>
    }

    /* Fonction de suppression de la commune sélectionner */
    delete_data() {
        if (this.state.selectedData == null || this.state.selectedData == undefined) {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner la ligne à supprimer' });
        } else {
            this.appservice.delete_commune(this.state.selectedData).then(reponse => {
                this.resultat(reponse);
                if (reponse.success == true) {
                    this.get_liste_data();
                }
            });
        }
    }


    /* Bloc visuel (propre à React) */
    render() {
        return (
            <div className="p-grid crud-demo">
                <div className="p-col-12">
                    <div className="card" style={{ height: 750 }}>

                        {/* Toast pour l'afficahge des messages de dialogue */}
                        <Toast ref={(el) => this.toast = el} />

                        {/* Toolbar contenant les boutons d'ajout, de suppression et d'exportation */}
                        <Toolbar className="mb-4" left={this.leftToolbarTemplate} right={this.rightToolbarTemplate} style={{paddingTop: 5, paddingBottom: 5}}/>
                        <br />

                        {/* Tableau contenant la liste des communes existantes dans la base */}
                        <DataTable ref={(el) => { this.dt = el; }} value={this.state.liste_data} resizableColumns="scroll"
                            paginator rows={10} alwaysShowPaginator={false} className="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-customers" showGridlines
                            selection={this.state.selectedData} onRowSelect={e => this.setState({ selectedData: e.data })}
                            emptyMessage="No data found" selectionMode="single"
                            onRowClick={e => this.setState({ selectedData: e.data }, () => {
                            })}>
                            <Column header="No" body={(rowData, props) => {
                                return <div>
                                    <span >{props.rowIndex + 1}</span>
                                </div>
                            }} style={{ width: '3%' }} />
                            <Column body={this.actionTemplate.bind(this)} header="Action" style={{ width: '10%' }} />
                            <Column field="libelle" header="Commune" filter={true} filterMatchMode='contains' />
                            <Column field="prefectureId.libelle" header="Préfecture" filter={true} filterMatchMode='contains' />
                            <Column field="prefectureId.regionId.libelle" header="Region" filter={true} filterMatchMode='contains' />

                        </DataTable>

                        {/* Boite de dialogue d'ajout et de modification d'une commune  */}
                        <Dialog visible={this.state.visible_dlg_form} style={{ width: '450px' }} header={this.state.modif_data == true ? "Modification" : "Ajout"} modal className="p-fluid" onHide={(e) => this.setState({ visible_dlg_form: false })}
                            footer={<>
                                <Button label="Fermer" icon="pi pi-times" className="p-button-secondary" style={{ width: 120 }} onClick={(e) => this.annuler()} />
                                <Button label="Valider" loading={this.state.showindicator} icon="pi pi-check" onClick={(e) => this.enreg_data()} style={{ width: 120 }} />
                            </>}>
                            <div className="p-fluid">
                                <div className="field">
                                    <label htmlFor="email1">Commune *</label>
                                    <InputText id="name" value={this.state.formData.libelle} className={classNames({ 'p-invalid': this.state.submit && !this.state.formData.libelle })}
                                        onChange={(e) => this.setState({ formData: this.appservice.onInputChange(e, 'libelle', '', this.state.formData) })}
                                        required autoFocus />
                                    {this.state.submit && !this.state.formData.libelle && <small className="p-invalid" style={{ color: 'red' }}>Le libelle est obligatoire.</small>}
                                </div>
                                <div className="field">
                                    <label htmlFor="email1">Préfecture *</label>
                                    <Dropdown placeholder="Ville" autoWidth={false} value={this.state.formData.prefectureId.id} filter={true} filterBy='label' filterMatchMode='contains'
                                        options={this.state.liste_prefecture.length > 0 ? this.state.liste_prefecture.map(x => { return { value: x.id, label: x.libelle } }) : []}
                                        onChange={(e) => this.setState({ formData: this.appservice.onInputChange(e, 'prefectureId', 'fk', this.state.formData) })}
                                        appendTo={document.body} className={classNames({ 'p-invalid': this.state.submit && !this.state.formData.prefectureId.id })} />
                                    {this.state.submit && !this.state.formData.prefectureId.id && <small className="p-invalid" style={{ color: 'red' }}>La région est obligatoire.</small>}
                                </div>
                            </div>
                        </Dialog>


                        {/* Boite de dialogue d'ajout d'un quartier  */}
                        <Dialog visible={this.state.visible_dlg_form_com} style={{ width: '450px' }} header="Ajout d'un quartier" modal className="p-fluid" onHide={(e) => this.setState({ visible_dlg_form_com: false })}
                            footer={<>
                                <Button label="Fermer" icon="pi pi-times" className="p-button-secondary" style={{ width: 120 }} onClick={(e) => this.annuler()} />
                                <Button label="Valider" loading={this.state.showindicator} icon="pi pi-check" onClick={(e) => this.enreg_data_commune()} style={{ width: 120 }} />
                            </>}>
                            <div className="p-fluid">
                                <div className="field">
                                    <label htmlFor="email1">Quartier *</label>
                                    <InputText id="name" value={this.state.formQuartier.libelle} onChange={(e) => this.setState({ formQuartier: this.appservice.onInputChange(e, 'libelle', '', this.state.formQuartier) })}
                                        className={classNames({ 'p-invalid': this.state.submit && !this.state.formQuartier.libelle })} required />
                                    {this.state.submit && !this.state.formQuartier.libelle && <small className="p-invalid" style={{ color: 'red' }}>Le libelle est obligatoire.</small>}
                                </div>
                                <div className="field">
                                    <label htmlFor="email1">Commune *</label>
                                    <Dropdown placeholder="Commune" autoWidth={false} value={this.state.formQuartier.communeId.id} filter={true} filterBy='label' filterMatchMode='contains'
                                        options={this.state.liste_data.length > 0 ? this.state.liste_data.map(x => { return { value: x.id, label: x.libelle } }) : []}
                                        onChange={(e) => this.setState({ formQuartier: this.appservice.onInputChange(e, 'communeId', 'fk', this.state.formQuartier) })}
                                        appendTo={document.body} className={classNames({ 'p-invalid': this.state.submit && !this.state.formQuartier.communeId.id })} />
                                    {this.state.submit && !this.state.formQuartier.communeId.id && <small className="p-invalid" style={{ color: 'red' }}>Le quartier est obligatoire.</small>}
                                </div>

                            </div>
                        </Dialog>


                        {/* Boite de dialogue de confirmation de suppression */}
                        <Dialog visible={this.state.visible_dlg_delete} style={{ width: '450px' }} header="Confirmation" modal
                            footer={
                                <>
                                    <Button label="Non" icon="pi pi-times" className="p-button-secondary" onClick={() => {
                                        this.setState({ visible_dlg_delete: false });
                                    }} />
                                    <Button label="Oui" icon="pi pi-check" className="p-button-danger" onClick={() => {
                                        this.setState({ visible_dlg_delete: false });
                                        this.delete_data();
                                    }} />
                                </>
                            } onHide={(e) => this.setState({ visible_dlg_delete: false })}>
                            <div className="flex align-items-center justify-content-center">
                                <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                                {this.state.selectedData && <span>Voulez-vous vraiment supprimer <b>{this.state.selectedData.libelle}</b>?</span>}
                            </div>
                        </Dialog>

                    </div>
                </div>
            </div >
        );
    }
}
