import React, { Component } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { classNames } from 'primereact/utils';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Toolbar } from 'primereact/toolbar';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import AppServices from '../service/AppServices';
import { Dropdown } from 'primereact/dropdown';
import { SplitButton } from 'primereact/splitbutton';

/* Page de gestion des régions */
export default class FormRegion extends Component {
    constructor() {
        super();
        this.state = {
            selectedData: null,
            liste_data: [],
            formData: {
                id: 0,
                code: '',
                iduser: "",
                libelle: "",
                suspendu: false
            },
            showindicator: false,
            modif_data: false,
            submit: false,
            visible_dlg_delete: false,
            visible_dlg_form: false,
        };
        this.edit_data = this.edit_data.bind(this);
        this.conf_delete_data = this.conf_delete_data.bind(this);
        this.toogleDlg = this.toogleDlg.bind(this);

        this.appservice = new AppServices();
    }


    /* Natif react js (s'exécute au montage ou chargement de la page )*/
    componentDidMount() {
        this.get_liste_data();
    }

    /* Récupération de la liste globale des régions */
    get_liste_data() {
        this.setState({ showindicator: true });
        this.appservice.get_liste_region().then(data => {
            this.setState({ liste_data: data, showindicator: false });
        });
    }

    /* Annuler l'opération en cours et vider le formulaire */
    annuler() {
        this.setState({
            formData: {
                id: 0,
                code: '',
                libelle: "",
                iduser: "",
                suspendu: false
            }
        });

        this.setState({ showindicator: false });
        this.setState({ modif_data: false });
        this.setState({ visible_dlg_delete: false });
        this.setState({ visible_dlg_form: false });
    }

    /* Remplissage du formulaire avec les informations de la ligne sélectionnée dans le cadre de la modification  */
    edit_data(data) {
        if (data !== null && data !== undefined) {
            this.setState({ formData: data });
            this.setState({ modif_data: true });
            this.setState({ visible_dlg_form: true });
            this.setState({ selectedData: data });
        }
    }

    /* Enregistrer les informations du formulaire region */
    enreg_data() {
        this.setState({ submit: true });
        if (this.state.formData.libelle == "") {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez renseigner tous les champs obligatoires' });
        } else {
            this.appservice.save_region(this.state.formData).then(reponse => {
                if (reponse != undefined && reponse != null) {
                    this.resultat(reponse);
                    if (reponse.success == true) {
                        this.get_liste_data();
                    }
                }
            });
        }
    }

    /* Affichage de la boite de dialogue de confirmation de suppression */
    conf_delete_data(data) {
        this.setState({ visible_dlg_delete: true });
        this.setState({ selectedData: data });
    }

    /* Affichage de la boite de dialogue pour l'ajout d'une nouvelle region */
    toogleDlg() {
        this.annuler();
        this.setState({ visible_dlg_form: !this.state.visible_dlg_form });
    }

    /* Zone gauche de la toolbar */
    leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <Button label="Ajouter" icon="pi pi-plus" className="p-button-success mr-2" onClick={this.toogleDlg} style={{ width: 115 }} />
                    <Button label="Supprimer" disabled={this.state.selectedData == null} icon="pi pi-trash" className="p-button-danger" onClick={(e) => this.conf_delete_data(this.state.selectedData)} style={{ width: 115 }} />
                </div>
            </React.Fragment>
        )

    }

    /* Zone droite de la toolbar */
    rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : <Button label="Export" icon="pi pi-upload" className="p-button-help" onClick={this.exportCSV} />}
            </React.Fragment>
        )
    }

    /* Exportation du tableau contenant la liste des régions en fichier CSV */
    exportCSV = () => {
        this.dt.exportCSV("LISTE_REGION");
    }


    /* Fonction d'affichage des messages retournés suite aux actions serveurs */
    resultat(rep) {
        if (rep.success == true) {
            this.toast.show({ severity: 'success', summary: 'Success', detail: rep.message });
            this.annuler();
        } else {
            this.toast.show({ severity: 'error', summary: 'Error', detail: rep.message });
        }
        this.setState({ submit: false });
    }

    /* Colonne Action du tableau contenant la liste des régions */
    actionTemplate(rowData, column) {
        const items = [
            {
                label: 'Modifier',
                icon: 'pi pi-pencil',
                command: (e) => {
                    this.edit_data(rowData);
                }
            },
            {
                label: 'Supprimer',
                icon: 'pi pi-times',
                command: (e) => {
                    this.conf_delete_data(rowData);
                }
            },
        ]
        return <SplitButton label="Modifier" icon="pi pi-pencil" onClick={(e) => this.edit_data(rowData)} model={items}></SplitButton>
    }

    /* Fonction de suppression de la region sélectionner */
    delete_data() {
        if (this.state.selectedData == null || this.state.selectedData == undefined) {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner la ligne à supprimer' });
        } else {
            this.appservice.delete_region(this.state.selectedData).then(reponse => {
                this.resultat(reponse);
                if (reponse.success == true) {
                    this.get_liste_data();
                }
            });
        }
    }


    /* Bloc visuel (propre à React) */
    render() {
        return (
            <div className="p-grid crud-demo">
                <div className="p-col-12">
                    <div className="card" style={{ height: 650 }}>

                        {/* Toast pour l'afficahge des messages de dialogue */}
                        <Toast ref={(el) => this.toast = el} />

                        {/* Toolbar contenant les boutons d'ajout, de suppression et d'exportation */}
                        <Toolbar className="mb-4" left={this.leftToolbarTemplate} right={this.rightToolbarTemplate} style={{paddingTop: 5, paddingBottom: 5}}/>
                        <br />

                        {/* Tableau contenant la liste des régions existantes dans la base */}
                        <DataTable ref={(el) => { this.dt = el; }} value={this.state.liste_data} resizableColumns="scroll"
                            paginator rows={10} className="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-customers" showGridlines
                            selection={this.state.selectedData} onRowSelect={e => this.setState({ selectedData: e.data })}
                            emptyMessage="No data found" selectionMode="single" alwaysShowPaginator={false}
                            onRowClick={e => this.setState({ selectedData: e.data }, () => {
                            })}>
                            <Column header="No" body={(rowData, props) => {
                                return <div>
                                    <span >{props.rowIndex + 1}</span>
                                </div>
                            }} style={{ width: '3%' }} />
                            <Column body={this.actionTemplate.bind(this)} header="Action" style={{ width: '10%' }} />
                            <Column field="libelle" header="Region" />
                        </DataTable>

                        {/* Boite de dialogue d'ajout et de modification d'une region  */}
                        <Dialog visible={this.state.visible_dlg_form} style={{ width: '450px' }} header={this.state.modif_data == true ? "Modification" : "Ajout"} modal className="p-fluid" onHide={(e) => this.setState({ visible_dlg_form: false })}
                            footer={<>
                                <Button label="Fermer" icon="pi pi-times" className="p-button-secondary" style={{ width: 120 }} onClick={(e) => this.annuler()} />
                                <Button label="Valider" loading={this.state.showindicator} icon="pi pi-check" onClick={(e) => this.enreg_data()} style={{ width: 120 }} />
                            </>}>
                            <div className="p-fluid">
                                <div className="field">
                                    <label htmlFor="email1">Region</label>
                                    <InputText id="name" value={this.state.formData.libelle} className={classNames({ 'p-invalid': this.state.submit && !this.state.formData.libelle })}
                                        onChange={(e) => this.setState({ formData: this.appservice.onInputChange(e, 'libelle', '', this.state.formData) })}
                                        required autoFocus />
                                    {this.state.submit && !this.state.formData.libelle && <small className="p-invalid" style={{ color: 'red' }}>Le libelle est obligatoire.</small>}
                                </div>
                            </div>
                        </Dialog>



                        {/* Boite de dialogue de confirmation de suppression */}
                        <Dialog visible={this.state.visible_dlg_delete} style={{ width: '450px' }} header="Confirmation" modal
                            footer={
                                <>
                                    <Button label="Non" icon="pi pi-times" className="p-button-secondary" onClick={() => {
                                        this.setState({ visible_dlg_delete: false });
                                    }} />
                                    <Button label="Oui" icon="pi pi-check" className="p-button-danger" onClick={() => {
                                        this.setState({ visible_dlg_delete: false });
                                        this.delete_data();
                                    }} />
                                </>
                            } onHide={(e) => this.setState({ visible_dlg_delete: false })}>
                            <div className="flex align-items-center justify-content-center">
                                <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                                {this.state.selectedData && <span>Voulez-vous vraiment supprimer <b>{this.state.selectedData.libelle}</b>?</span>}
                            </div>
                        </Dialog>




                    </div>
                </div>
            </div >
        );
    }
}
