import React, { useState, useEffect, useRef } from 'react';
import AppServices from '../service/AppServices';
import { Panel } from 'primereact/panel';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Chart } from 'primereact/chart';
import { Dialog } from 'primereact/dialog';
import { ProgressSpinner } from 'primereact/progressspinner';

const Dashboard = (props) => {
    const appservice = new AppServices();
    const [visible, setVisible] = useState(false);
    const [chartOptions, setChartOptions] = useState({});
    const [liste_st_effectif_region, setListe_st_effectif_region] = useState([]);
    const [liste_st_effectif_pref, setListe_st_effectif_pref] = useState([]);
    const [liste_st_effectif_ecole, setListe_st_effectif_ecole] = useState([]);
    const [liste_st_effectif_classe, setListe_st_effectif_classe] = useState([]);
    const [nb_g, setNb_g] = useState(0);
    const [nb_ecole, setNb_ecole] = useState(0);
    const [nb_f, setNb_f] = useState(0);
    const [nb, setNb] = useState(0);
    const [showindicator, setShowindicator] = useState(false);


    useEffect(() => {

    }, [props.colorMode]);

    useEffect(() => {
        refreshDashboard();
    }, []);


    const refreshDashboard = (ppd1, ppd2) => {
        setShowindicator(true);
        setVisible(true);
        appservice.get_dashboard().then((data) => {
            setShowindicator(false);
            setVisible(false);
            if (data !== null && data !== undefined) {
                setNb(data.nb);
                setNb_f(data.nb_f);
                setNb_ecole(data.nb_ecole);
                setNb_g(data.nb_g);
                setListe_st_effectif_ecole(data.liste_st_effectif_ecole);
                setListe_st_effectif_region(data.liste_st_effectif_region);
                setListe_st_effectif_pref(data.liste_st_effectif_pref);
                setListe_st_effectif_classe(data.liste_st_effectif_classe);
            }
        });
    }

    const showChartEffectif = () => {
        let liste_label = [];
        let liste_ds = [];

        liste_label = liste_st_effectif_region.map(x => {
            return x.region
        });

        liste_ds = liste_st_effectif_region.map(x => {
            return x.nb
        });


        const chartData = {
            labels: liste_label,
            datasets: [
                {
                    backgroundColor: [
                        "#003366",
                        "#64b2e1",
                        "#f9d247",
                        "#ff7900",
                        "#6cbb8b",
                        "#a288d3",
                        "#f5b8e3",
                        "#eeeeee",
                        "#fcba03",
                        "#a6a5a2",
                        "#9cb090",
                        "#52ba9b",
                        "#06c1cf",
                        "#275996",
                        "#ff7900",
                        "#003366",
                        "#295380",
                        "#d87a3c",
                        "#36A2EB",
                        "#FFCE56",
                        "#293780",

                        "#FADBD8",
                        "#EBDEF0",
                        "#C39BD3",
                        "#D1F2EB",
                        "#5499C7",
                        "#2874A6",
                        "#E74C3C",
                        "#154360",
                        "#F9E79F",
                        "#EDBB99",
                        "#1B2631",
                        "#5D6D7E",
                        "#A6ACAF",
                        "#95A5A6",
                        "#F4D03F",
                        "#28B463",
                        "#D4AC0D",
                        "#A3E4D7",
                        "#5DADE2",
                        "#138D75",
                        "#6C3483",
                        "#16A085",
                        "#82E0AA",
                        "#A2D9CE",
                        "#0B5345",
                        "#1B4F72",
                        "#78281F",
                        "#34495E",
                        "#F6DDCC",
                        "#566573",
                        "#F0B27A",
                        "#7B7D7D",
                        "#935116",
                        "#117A65",
                        "#2C3E50",
                        "#D5F5E3",
                        "#FBEEE6",
                        "#E67E22",
                        "#9B59B6",
                        "#1F618D",
                        "#641E16",
                        "#AAB7B8",
                    ],
                    data: liste_ds
                }
            ]
        };

        const options = {
            cutout: '60%'
        };


        return <div>
            <center>
                <Chart type="pie" data={chartData} options={options} className="w-full md:w-25rem" />
            </center>
        </div>

    }

    const showChartEffectifSexeRegion = () => {
        let liste_label = [];
        let liste_ds_f = [];
        let liste_ds_g = [];

        liste_label = liste_st_effectif_region.map(x => {
            return x.region
        });

        liste_ds_g = liste_st_effectif_region.map(x => {
            return x.nb_g
        });

        liste_ds_f = liste_st_effectif_region.map(x => {
            return x.nb_f
        });

        const options = {
            indexAxis: 'y',
            maintainAspectRatio: false,
            aspectRatio: 0.8,
            plugins: {
                tooltips: {
                    mode: 'index',
                    intersect: false
                },
                legend: {
                    labels: {
                        color: "#000"
                    }
                }
            },
            scales: {
                x: {
                    stacked: true,
                    ticks: {
                        color: '#000'
                    },
                    grid: {
                        color: '#f7f7f7'
                    }
                },
                y: {
                    stacked: true,
                    ticks: {
                        color: '#000'
                    },
                    grid: {
                        color: '#f7f7f7'
                    }
                }
            }
        };

        const chartData = {
            labels: liste_label,
            datasets: [
                {
                    type: 'bar',
                    label: 'Fille',
                    backgroundColor: '#f691b7',
                    data: liste_ds_f
                },
                {
                    type: 'bar',
                    label: 'Garçon',
                    backgroundColor: '#1164a3',
                    data: liste_ds_g
                },
            ]
        };



        return <div>
            <center>
                <Chart type="bar" data={chartData} options={options} height='300w' />
            </center>
        </div>

    }

    const showChartEffectifSexeClasse = () => {
        let liste_label = [];
        let liste_ds_f = [];
        let liste_ds_g = [];

        liste_label = liste_st_effectif_classe.map(x => {
            return x.classe
        });

        liste_ds_g = liste_st_effectif_classe.map(x => {
            return x.nb_g
        });

        liste_ds_f = liste_st_effectif_classe.map(x => {
            return x.nb_f
        });

        const options = {
            indexAxis: 'y',
            maintainAspectRatio: false,
            aspectRatio: 0.8,
            plugins: {
                tooltips: {
                    mode: 'index',
                    intersect: false
                },
                legend: {
                    labels: {
                        color: "#000"
                    }
                }
            },
            scales: {
                x: {
                    stacked: true,
                    ticks: {
                        color: '#000'
                    },
                    grid: {
                        color: '#f7f7f7'
                    }
                },
                y: {
                    stacked: true,
                    ticks: {
                        color: '#000'
                    },
                    grid: {
                        color: '#f7f7f7'
                    }
                }
            }
        };

        const chartData = {
            labels: liste_label,
            datasets: [
                {
                    type: 'bar',
                    label: 'Fille',
                    backgroundColor: '#f691b7',
                    data: liste_ds_f
                },
                {
                    type: 'bar',
                    label: 'Garçon',
                    backgroundColor: '#1164a3',
                    data: liste_ds_g
                },
            ]
        };



        return <div>
            <center>
                <Chart type="bar" data={chartData} options={options} height='300w' />
            </center>
        </div>

    }



    return (


        <div className="grid dashboard">
            <Dialog visible={visible} showHeader={false} modal style={{ width: '70px', height: '70px',borderRadius: 17,opacity: 0.6}} contentStyle={{backgroundColor: '#f7f7f7',borderRadius: 17}} onHide={() => setVisible(false)}>
                <div
                    style={{
                        height: "100%",
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center"
                    }}
                >
                    <div>
                        <ProgressSpinner style={{ width: '40px', height: '40px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" />
                    </div>
                </div>

            </Dialog>
            <div className="col-6 md:col-3">
                <div className="overview-box overview-box-1"><h1>EFFECTIF TOTAL</h1>
                    <div className="overview-value">{appservice.formaterValueSep(nb)}</div>
                    <div className="overview-ratio">
                        <div className="overview-direction">
                            <i className="pi pi-users"></i>
                        </div>

                    </div>
                    <img src="assets/layout/images/dashboard/graph-blue.svg" alt="apollo-layout" />
                </div>
            </div>

            <div className="col-6 md:col-3">
                <div className="overview-box overview-box-2">
                    <h1>TOTAL GARÇON</h1>
                    <div className="overview-value">{appservice.formaterValueSep(nb_g)}</div>
                    <div className="overview-ratio">
                        <div className="overview-direction">
                            <i className="pi pi-user"></i>
                        </div>

                    </div>
                    <img src="assets/layout/images/dashboard/graph-green.svg" alt="apollo-layout" />
                </div>
            </div>

            <div className="col-6 md:col-3">
                <div className="overview-box overview-box-4">
                    <h1>TOTAL FILLE</h1>
                    <div className="overview-value">{appservice.formaterValueSep(nb_f)}</div>
                    <div className="overview-ratio">
                        <div className="overview-direction">
                            <i className="pi pi-user"></i>
                        </div>

                    </div>
                    <img src="assets/layout/images/dashboard/graph-red.svg" alt="apollo-layout" />
                </div>
            </div>

            <div className="col-6 md:col-3">
                <div className="overview-box overview-box-3">
                    <h1>TOTAL ECOLE</h1>
                    <div className="overview-value">{appservice.formaterValueSep(nb_ecole)}</div>
                    <div className="overview-ratio">
                        <div className="overview-direction">
                            <i className="pi pi-home"></i>
                        </div>

                    </div>
                    <img src="assets/layout/images/dashboard/graph-yellow.svg" alt="apollo-layout" />
                </div>
            </div>

            <div className="col-12 card" style={{ height: 1200 }}>
                <div className="grid">
                    <div className="col-12 md:col-6 lg:col-5" style={{}}>
                        <i className="pi pi-chart-line"></i> Statistiques des effectifs par classe
                        <hr style={{ marginTop: 2 }} />
                        <div className="orders-subtable">
                            <DataTable value={liste_st_effectif_classe} dataKey='id'
                                paginator rows={10} className="p-datatable-gridlines" showGridlines
                                emptyMessage="No data found" alwaysShowPaginator={false}>
                                <Column header="No" body={(rowData, props) => {
                                    return <div>
                                        <span >{props.rowIndex + 1}</span>
                                    </div>
                                }} style={{ width: '5%' }} />
                                <Column field="classe" header="Classe" />
                                <Column field="nb_f" header="TTF" body={(rowData, column) => {
                                    return <div>
                                        <span >{appservice.formaterValueSep(rowData.nb_f)}</span>
                                    </div>
                                }} style={{ width: '15%' }} />
                                <Column field="nb_g" header="TTG" body={(rowData, column) => {
                                    return <div>
                                        <span >{appservice.formaterValueSep(rowData.nb_g)}</span>
                                    </div>
                                }} style={{ width: '15%' }} />
                                <Column field="nb_f" header="TOTAL" body={(rowData, column) => {
                                    return <div>
                                        <span >{appservice.formaterValueSep(rowData.nb)}</span>
                                    </div>
                                }} style={{ width: '15%' }} />
                            </DataTable>
                        </div>

                    </div>


                    <div className="col-12 md:col-6 lg:col-7" >
                        {appservice.get_userconnected() !== null && appservice.get_userconnected() !== undefined ?
                            <>
                                {appservice.get_userconnected().profil == 'Consultation' || appservice.get_userconnected().profil == 'Administrateur' ?

                                    <>
                                        <i className="pi pi-chart-line"></i> Statistiques des effectifs par region
                                        <hr style={{ marginTop: 2 }} />

                                        {showChartEffectif()}

                                        <i className="pi pi-chart-line"></i> Statistiques des effectifs par genre
                                        <hr style={{ marginTop: 2 }} />

                                        {showChartEffectifSexeRegion()}
                                    </>
                                    :
                                    <>
                                        <i className="pi pi-chart-line"></i> Statistiques des effectifs par classe
                                        <hr style={{ marginTop: 2 }} />

                                        {showChartEffectifSexeClasse()}
                                    </>
                                }
                            </>
                            : null}

                    </div>


                    {appservice.get_userconnected() !== null && appservice.get_userconnected() !== undefined ?
                        <>
                            {appservice.get_userconnected().profil == 'Consultation' || appservice.get_userconnected().profil == 'Administrateur' ?
                                <div className="col-12 md:col-12 lg:col-12" >
                                    <i className="pi pi-chart-line"></i> Statistiques des effectifs par école
                                    <hr style={{ marginTop: 2 }} />
                                    <DataTable value={liste_st_effectif_ecole} resizableColumns="scroll"
                                        paginator rows={10} className="p-datatable-gridlines" showGridlines
                                        emptyMessage="No data found" responsiveLayout='scroll' alwaysShowPaginator={false}>
                                        <Column header="No" body={(rowData, props) => {
                                            return <div>
                                                <span >{props.rowIndex + 1}</span>
                                            </div>
                                        }} style={{ width: '4%' }} />
                                        <Column field="nom" header="Nom" />
                                        <Column field="sigle" header="Sigle" style={{ width: '10%' }} />
                                        <Column field="quartier" header="Quartier" style={{ width: '15%' }} />
                                        <Column field="commune" header="CRD" style={{ width: '10%' }} />
                                        <Column field="prefecture" header="Préfecture" style={{ width: '10%' }} />
                                        <Column field="region" header="Region" style={{ width: '8%' }} />
                                        <Column field="nb_f" header="TTF" body={(rowData, column) => {
                                            return <div>
                                                <span >{appservice.formaterValueSep(rowData.nb_f)}</span>
                                            </div>
                                        }} style={{ width: '7%' }} />
                                        <Column field="nb_g" header="TTG" body={(rowData, column) => {
                                            return <div>
                                                <span >{appservice.formaterValueSep(rowData.nb_g)}</span>
                                            </div>
                                        }} style={{ width: '7%' }} />
                                        <Column field="nb_f" header="TOTAL" body={(rowData, column) => {
                                            return <div>
                                                <span >{appservice.formaterValueSep(rowData.nb)}</span>
                                            </div>
                                        }} style={{ width: '7%' }} />
                                    </DataTable>
                                </div>
                                : null}
                        </>
                        : null}

                </div>
            </div>




        </div >
    )

}

const comparisonFn = function (prevProps, nextProps) {
    return (prevProps.location.pathname === nextProps.location.pathname) && (prevProps.colorMode === nextProps.colorMode);
};

export default React.memo(Dashboard, comparisonFn);
