import React, { useState, useEffect, useRef } from 'react';
import { classNames } from 'primereact/utils';
import { InputText } from 'primereact/inputtext';
import { CSSTransition } from 'react-transition-group';
import AppServices from './service/AppServices';
import { Dropdown } from 'primereact/dropdown';

const AppTopbar = (props) => {
    const appservice = new AppServices();
    const [annee, setAnnee] = useState(localStorage.getItem('annee'));
    const sessionChange = (e) => {
        setAnnee(e.value);
        localStorage.setItem('annee', e.value);
        window.location = "#/dashboard";
    };

    var userconnected = appservice.get_userconnected();
    var nom_user = '';
    var profil = '';
    var login = '';

    if (userconnected !== null && userconnected !== undefined) {
        nom_user = userconnected.prenom + " " + userconnected.nom;
        profil = userconnected.profil;
        login = userconnected.login;
    }

    const onTopbarItemClick = (event, item) => {
        if (props.onTopbarItemClick) {
            props.onTopbarItemClick({
                originalEvent: event,
                item: item
            });
        }
    };
 
    let topbarItemsClassName = classNames('topbar-menu fadeInDown', { 'topbar-menu-visible': props.topbarMenuActive });

    return (
        <div className="topbar clearfix">

            <div className="logo-link">
                <img className="logo" alt="apollo-layout" src="assets/layout/images/logoTopbar.png" />
            </div>

            <button type="button" className="p-link menu-button" id="menu-button" onClick={props.onMenuButtonClick}>
                <i className="pi pi-align-left"></i>
            </button>

            <button type="button" className="p-link profile" onClick={props.onTopbarMenuButtonClick}>
                <span className="username">{nom_user}</span>
                <img src="assets/layout/images/avatar/avatar.png" alt="apollo-layout" />
                <i className="pi pi-angle-down"></i> 
            </button> 

            <button type="button" className="p-link profile" style={{marginRight: 20,marginTop: 0}}>
                <Dropdown  options={appservice.get_liste_session()} value={annee} onChange={sessionChange} autoWidth={false} filter={true}  style={{ fontWeight: 'bold', fontSize: 22, color: 'red', cursor: 'pointer',width: 150 }} filterBy='label' filterMatchMode='contains'  />
            </button>

            <ul className={topbarItemsClassName}>

                <li className={classNames({ 'menuitem-active': props.activeTopbarItem === 'profile' })}
                    onClick={(e) => onTopbarItemClick(e, 'profile')}>
                    <button type="button" className="p-link">
                        <i className="topbar-icon pi pi-fw pi-user"></i>
                        <span className="topbar-item-name">Mon profile</span>
                    </button>
                    <CSSTransition classNames="layout-submenu-container" timeout={{ enter: 400, exit: 400 }} in={props.activeTopbarItem === 'profile'} unmountOnExit>
                        <ul>
                            <li role="menuitem">
                                <button type="button" className="p-link">
                                    <i className="pi pi-fw pi-user-edit"></i>
                                    <span>{profil}</span>
                                </button>
                            </li>

                        </ul>
                    </CSSTransition>
                </li>

                <li className={classNames({ 'menuitem-active': props.activeTopbarItem === 'settings' })}
                    onClick={(e) => onTopbarItemClick(e, 'settings')}>
                    <button type="button" className="p-link" onClick={(e) => {
                        window.location = "#/changepwd";
                        window.location.reload();
                    }}>
                        <i className="topbar-icon pi pi-fw pi-cog"></i>
                        <span className="topbar-item-name">Changer mon mot de passe</span>
                    </button>
                </li>
                <li className={classNames({ 'menuitem-active': props.activeTopbarItem === 'messages' })}
                    onClick={(e) => onTopbarItemClick(e, 'messages')}>
                    <button type="button" className="p-link" onClick={(e) => {
                        localStorage.removeItem('userconnected');
                        window.location = "#/login";
                    }}>
                        <i className="topbar-icon pi pi-fw pi-sign-out"></i>
                        <span className="topbar-item-name">Déconnexion</span>
                    </button>
                </li>
            </ul>
        </div>
    );

}

export default AppTopbar;
