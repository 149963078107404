import Numeral from 'numeral'; //Composant de Formatage des nombre en montant
import 'moment';    //Composant de manipulation des dates
import 'moment/locale/fr';
import moment from 'moment';
import jsPDF from 'jspdf'
import $ from 'jquery';
import QRCode from 'qrcode';

export default class AppServices {
    constructor() {
        this.state = {
            //URL de base
            wsurl: 'https://backend-sigma.optionplustechnologie.com/api/v1',
            //wsurl: 'http://localhost:5000/api/v1',
            //imgBaseUrl: 'http://localhost:5000/images',
            imgBaseUrl: 'https://backend-sigma.optionplustechnologie.com/api/v1/images',


            annee: localStorage.getItem('annee'),

            //'https://backend.bnsp-sn.com/api_bnsp/images
            //app.use('/api_bnsp/images', express.static('resources'));

            //Utilisateur connecté
            token: localStorage.getItem('token'),
            userconnected: JSON.parse(localStorage.getItem('userconnected')),
        };
    }

    get_liste_session_exam() {
        let liste = [];
        let deb = 2023;
        let fin = parseInt(moment().format('YYYY'));
        while (deb <= fin) {
            liste.push({
                value: deb,
                label: deb
            })
            deb += 1;
        }
        return liste;
    }

    get_liste_session() {
        let liste = [];
        let deb = 2023;
        let fin = parseInt(moment().format('MM')) > 9 ? parseInt(moment().format('YYYY')) : parseInt(moment().format('YYYY')) - 1;
        while (deb <= fin) {
            liste.push({
                value: deb + '-' + (deb + 1), label: deb + '-' + (deb + 1)
            })
            deb += 1;
        }
        return liste;
    }

    get_selected_session() {
        return this.state.annee;
    }


    disconnect() {
        localStorage.removeItem('userconnected');
        localStorage.removeItem('token');
        window.location = "#/login";
    }

    // Retourne une liste de couleurs
    get_liste_colors() {
        return [
            "#0673c7",
            "#f07d82",
            "#0d5943",
            "#2c87b8",
            "#FF6384",
            "#36A2EB",
            "#4071a7",
            "#fcba03",
            "#a6a5a2",
            "#9cb090",
            "#52ba9b",
            "#06c1cf",
            "#275996",
            "#ff7900",
            "#003366",
            "#295380",
            "#d87a3c",
            "#36A2Eh",
            "#FFCE56",
            "#293780",
            "#086b54",
            "#FFC107",
            "#2ECC71",
        ];
    }

    // Permet de vérifier si une adresse email est correcte
    validateEmail(value) {
        return String(value)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            ) != null;
    };

    // Permet de vérifier si un numéro de téléphone est correcte
    validatePhoneNumber(value) {
        return !isNaN(value) && value.length == 9 && value[0] == '6' && (function (x) { return (x | 0) === x; })(parseFloat(value))
    };


    //Retourne l'url de base du serveur
    wsurl() {
        return this.state.wsurl;
    }

    //Permet de mettre des séparateur pour les montants
    formaterValueSep(value) {
        return Numeral(parseFloat(value)).format('0,0');
    }

    //Retourne l'utilisateur connecté
    get_userconnected() {
        return this.state.userconnected;
    }

    //Permet de retourner le tocken en cours
    getToken() {
        return this.state.token;
    }

    //Retourne le profil de l'utilisateur connecté
    getProfil() {
        let profil = 'Administrateur';
        if (this.state.userconnected !== null && this.state.userconnected !== undefined && this.state.userconnected !== '') {
            profil = this.state.userconnected.profil;
        }
        return profil;
    }

    // Affectation des variables de state
    onInputChange = (e, name, typeInput, data) => {
        const val = (e.target && e.target.value || e.value) || '';
        if (typeInput == 'fk') {
            data[`${name}`]['id'] = val;
        } else {
            data[`${name}`] = val;
        }
        return data;
    }

    // Affectation des variables de state
    onCalendarChange = (e, name, data) => {
        const val = (e.target.value) || '';
        data[`${name}`] = val;
        return data;
    }

    // Permet de retourner un objet dans une liste d'objet en fonction de son identifiant
    getSelectedData(liste, id) {
        return liste.filter(function (el) {
            return el.value === id;
        })[0];
    }

    capitalize(s) {
        return s[0].toUpperCase() + s.slice(1);
    }

    calculer_moyenne(liste_note) {
        let ttmoy = 0;
        let ttcoef = 0;
        let mg = 0;
        if(liste_note.length>0){
            for (let i = 0; i < liste_note.length; i++) {
                let x = liste_note[i];
                ttmoy += x.moy * x.matiereId.coef;
                ttcoef += x.matiereId.coef;
            }
        }
        if (ttcoef > 0) {
            mg = Math.round((ttmoy / ttcoef) * 100) / 100;
        }
        return mg;
    }

    generer_attestation(data, liste_note) {
        let id = data.id + "";
        let ldate = data.eleveId.date_naiss.split("-");
        let date_naiss = ldate[2] + '/' + ldate[1] + '/' + ldate[0];
        let liste_td = [];
        let ttmoy = 0;
        let ttcoef = 0;
        let mg = 0;
        let code = JSON.stringify({
            Nom: data.eleveId.nom,
            Prenom: data.eleveId.prenom,
            Matricule: data.eleveId.matricule,
            DateNaissance: data.eleveId.date_naiss,
            LieuNaissance: data.eleveId.lieu_naiss,
            Pere: data.eleveId.pere,
            Mere: data.eleveId.mere
        });
        for (let i = 0; i < liste_note.length; i++) {
            let x = liste_note[i];
            ttmoy += x.moy * x.matiereId.coef;
            ttcoef += x.matiereId.coef;
            liste_td.push(
                { no: i + 1, matiere: x.matiereId.libelle, coef: x.matiereId.coef, moy: x.moy }
            );
        }
        if (ttcoef > 0) {
            mg = Math.round((ttmoy / ttcoef) * 100) / 100;
        }

        QRCode.toDataURL(code)
            .then(url => {
                if (data != null && data != undefined) {
                    import('jspdf-autotable').then(() => {

                        var doc = new jsPDF("p", "pt");
                        var img = new Image()
                        img.src = 'assets/layout/images/entete.jpg';
                        doc.addImage(img, 'jpg', 20, 20, 555, 80)

                        var imgFond = new Image()
                        imgFond.src = 'assets/layout/images/fond_att.jpg';
                        doc.addImage(imgFond, 'jpg', 20, 180, 550, 550);

                        doc.rect(469, 234, 93, 93);



                        let src = this.download_file(data.eleveId.photo) + '?not-from-cache-please';
                        var photo = new Image();
                        photo.setAttribute('crossOrigin', 'anonymous');
                        photo.src = src;
                        doc.addImage(photo, 'jpg', 470, 235, 91, 91);

                        doc.setFontSize(30);
                        doc.text(40, 150, "ATTESTATION D’ADMISSION");

                        var imgQr = new Image()
                        imgQr.src = url;
                        doc.addImage(imgQr, 'png', 470, 105, 90, 90)

                        doc.setFontSize(20);
                        doc.setTextColor(255, 0, 0);
                        doc.text(250, 180, "Matricule: " + data.eleveId.matricule, { align: "center" });

                        doc.setTextColor(0, 0, 0);
                        doc.setFontSize(12);


                        doc.text(40, 208, "Vue le procès verbal de délibération, Monsieur/Madame le Directeur(trice) communale/préfecture de l'éducation de " + data.ecoleId.quartierId.communeId.libelle + ", soussigné, certifie que: ", { maxWidth: 550 });


                        doc.text(40, 245, data.eleveId.sexe == 'Masculin' ? "Monsieur: " : "Madame/Madémoiselle: ");
                        doc.text(40, 265, data.eleveId.sexe == 'Masculin' ? "Né le: " : "Né(e) le: ");
                        doc.text(40, 285, data.eleveId.sexe == 'Masculin' ? "Fils de: " : "Fille de: ");
                        doc.text(40, 305, "Classe:");

                        doc.text(120, 245, "").setFont(undefined, 'bold');
                        doc.text(120, 245, data.eleveId.prenom + " " + data.eleveId.nom).setFont(undefined, 'bold');
                        doc.text(120, 265, date_naiss + " à " + data.eleveId.lieu_naiss).setFont(undefined, 'bold');
                        doc.text(120, 285, data.eleveId.pere + " et de " + data.eleveId.mere).setFont(undefined, 'bold');
                        doc.text(120, 305, data.classeId.libelle).setFont(undefined, 'bold');


                        doc.text(40, 325, "").setFont(undefined, 'normal');
                        doc.text(40, 325, data.eleveId.sexe == 'Feminin' ? "Est admise aux évaluations finales de l'année scolaire: " + data.annee_insc : "Est admis  aux évaluations finales de l'année scolaire: " + data.annee_insc);
                        
                        doc.text(120, 245, "").setFont(undefined, 'bold');
                        doc.roundedRect(40, 337, 515, 20,7,7);
                        doc.setFontSize(15);
                        doc.text(180, 352, "Avec les notes suivantes: ").setFont(undefined, 'bold');


                        doc.autoTable({
                            startY: 370,
                            columnStyles: { no: { halign: 'center' }, moy: { halign: 'center' }, coef: { halign: 'center' } },
                            body: liste_td,
                            theme: 'plain',
                            columns: [
                                { header: 'N°', dataKey: 'no' },
                                { header: 'MATIERE', dataKey: 'matiere' },
                                { header: 'COEFFICIENTS', dataKey: 'coef' },
                                { header: 'MOYENNES', dataKey: 'moy' },
                            ],
                            styles: { cellPadding: 2, fontSize: 12, fontStyle: 'normal', minCellHeight: 15, lineWidth: 0.5, lineColor: "#3F3F40" },
                            bodyStyles: { valign: 'middle' },
                            headStyles: { halign: 'center', textColor: "#000", fontStyle: 'bold' },
                            footStyles: { halign: 'center', textColor: "#000", fontStyle: 'bold' },

                            foot: [
                                [{ content: 'TOTAL', colSpan: 2, rowSpan: 1, styles: { halign: 'left' } }, { content: ttcoef, colSpan: 1, rowSpan: 1, styles: { halign: 'center' } }, { content: ttmoy, colSpan: 1, rowSpan: 1, styles: { halign: 'center' } }],
                                [{ content: 'MOYENNE', colSpan: 3, rowSpan: 1, styles: { halign: 'left' } }, { content: mg, colSpan: 1, rowSpan: 1, styles: { halign: 'center' } }],
                            ]
                        })

                        doc.setFontSize(12);
                        doc.text(40, 620, "En foi de quoi, le présent document lui a été délivré pour servir et valloir ce que de droit.");



                        doc.text(200, 690, data.ecoleId.quartierId.communeId.libelle + ", LE " + moment().format('DD/MM/YYYY'));
                        //doc.text(200, 690, "Directeur Général/DGECS");

                        var sign = new Image();
                        sign.setAttribute('crossOrigin', 'anonymous');
                        sign.src = this.download_file('sign.jpg') + '?not-from-cache-please';;
                        doc.addImage(sign, 'jpg', 170, 700, 210, 100);

                        // doc.text(200, 770, "Mamady 49 KEITA");



                        doc.save("ATTESTATION_ADMISSION_" + data.id + '_' + moment(new Date()).format('DDMMYYYYHHmmss') + ".pdf");
                    })

                }

            })
            .catch(err => {
                console.error(err)
            })

    }
    /*###################  ENDPOINTS DE GESTION DES ETATS            ################### */

    find_eleve(session, pv, dce) {
        if (session == "") {
            session = "NA"
        };
        if (dce == "") {
            dce = "NA"
        }
        var lien = this.wsurl() + '/etats/find_eleve/' + session + '/' + pv + '/' + dce;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                'Authorization': `${this.getToken()}`,
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
            if (response.status == 401) {
                this.disconnect();
            }
        }).then(data => {
            return data.data;
        }).catch((error) => {
            return error
        })
    }


    get_liste_dce(session) {
        var lien = this.wsurl() + '/etats/get_liste_dce/' + session;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                'Authorization': `${this.getToken()}`,
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
            if (response.status == 401) {
                this.disconnect();
            }
        }).then(data => {
            return data.data;
        }).catch((error) => {
            return error
        })
    }

    get_liste_dpe(session) {
        var lien = this.wsurl() + '/etats/get_liste_dpe/' + session;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                'Authorization': `${this.getToken()}`,
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
            if (response.status == 401) {
                this.disconnect();
            }
        }).then(data => {
            return data.data;
        }).catch((error) => {
            return error
        })
    }

    get_liste_ecoles_filtre(pidCrd, pidQuartier, pidUser) {
        var lien = this.wsurl() + '/etats/get_liste_ecoles_filtre/' + pidCrd + '/' + pidQuartier + '/' + pidUser;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                'Authorization': `${this.getToken()}`,
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
            if (response.status == 401) {
                this.disconnect();
            }
        }).then(data => {
            return data.data;
        }).catch((error) => {
            return error
        })
    }

    // CHARGER LE DASHBOARD
    get_dashboard(pidRegion, pidPref, pidCrd) {
        var lien = this.wsurl() + '/etats/get_dashboard';
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                'Authorization': `${this.getToken()}`,
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
            if (response.status == 401) {
                this.disconnect();
            }
        }).then(data => {
            return data.data;
        }).catch((error) => {
            return error
        })
    }


    // CHARGER LES STATS DES ECOLES PAR PREFECTURE
    get_liste_stat_ecole(pidRegion) {
        var lien = this.wsurl() + '/etats/get_liste_stat_ecole/' + pidRegion;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                'Authorization': `${this.getToken()}`,
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
            if (response.status == 401) {
                this.disconnect();
            }
        }).then(data => {
            return data.data;
        }).catch((error) => {
            return error
        })
    }

    // CHARGER LES STATS DES ECOLES PAR ECOLE
    get_liste_stat_eff_ecole(pidRegion, pidPref, pidCrd) {
        var lien = this.wsurl() + '/etats/get_liste_stat_eff_ecole/' + pidRegion + '/' + pidPref + '/' + pidCrd;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                'Authorization': `${this.getToken()}`,
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
            if (response.status == 401) {
                this.disconnect();
            }
        }).then(data => {
            return data.data;
        }).catch((error) => {
            return error
        })
    }

    // CHARGER LES STATS DES ECOLES PAR CLASSE
    get_liste_stat_eff_classe(pidRegion, pidPref, pidCrd) {
        var lien = this.wsurl() + '/etats/get_liste_stat_eff_classe/' + pidRegion + '/' + pidPref + '/' + pidCrd;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                'Authorization': `${this.getToken()}`,
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
            if (response.status == 401) {
                this.disconnect();
            }
        }).then(data => {
            return data.data;
        }).catch((error) => {
            return error
        })
    }

    /*###################  FIN ENDPOINTS DE GESTION DES ETATS         ################### */


    /*###################  ENDPOINTS DE GESTION DES TRANSFERTS            ################### */

    //Ajouter et modifier un transfert
    save_transfert(data) {
        var lien = "";
        let methode = 'POST';
        if (data.id == 0) {
            lien = this.wsurl() + '/transferts';
        } else {
            lien = this.wsurl() + '/transferts/' + data.id;
            methode = 'PUT';
        }
        data.iduser = this.get_userconnected().id;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${this.getToken()}`,
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
            if (response.status == 401) {
                this.disconnect();
            }
        }).then(data => {

            return data;
        }).catch((error) => {
            return error
        })
    }

    // Retourne la liste générale des transferts
    get_liste_transferts(communeId1, communeId2, matricule) {
        var lien = this.wsurl() + '/transferts/' + communeId1 + '/' + communeId2 + '/' + matricule;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                'Authorization': `${this.getToken()}`,
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
            if (response.status == 401) {
                this.disconnect();
            }
        }).then(data => {
            return data.data;
        }).catch((error) => {
            return error
        })
    }

    //Suppression d'un transfert
    delete_transferts(data) {
        var lien = this.wsurl() + '/transferts/' + data.id;
        return fetch(lien, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${this.getToken()}`,
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
            if (response.status == 401) {
                this.disconnect();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    /*###################  FIN ENDPOINTS DE GESTION DES TRANSFERTS         ################### */




    /*###################  ENDPOINTS DE GESTION DES REGIONS            ################### */

    //Ajouter et modifier une region
    save_region(data) {
        var lien = "";
        let methode = 'POST';
        if (data.id == 0) {
            lien = this.wsurl() + '/regions';
        } else {
            lien = this.wsurl() + '/regions/' + data.id;
            methode = 'PUT';
        }
        data.iduser = this.get_userconnected().id;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${this.getToken()}`,
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
            if (response.status == 401) {
                this.disconnect();
            }
        }).then(data => {

            return data;
        }).catch((error) => {
            return error
        })
    }

    // Retourne la liste générale des regions
    get_liste_region() {
        var lien = this.wsurl() + '/regions/';
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                'Authorization': `${this.getToken()}`,
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
            if (response.status == 401) {
                this.disconnect();
            }
        }).then(data => {
            return data.data;
        }).catch((error) => {
            return error
        })
    }

    //Suppression d'une region
    delete_region(data) {
        var lien = this.wsurl() + '/regions/' + data.id;
        return fetch(lien, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${this.getToken()}`,
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
            if (response.status == 401) {
                this.disconnect();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    /*###################  FIN ENDPOINTS DE GESTION DES REGIONS         ################### */


    /*###################  ENDPOINTS DE GESTION DES ETABLISSEMENT            ################### */

    //Ajouter et modifier un rattachement
    save_gerer_etabl(data) {
        var lien = "";
        let methode = 'POST';
        if (data.id == 0) {
            lien = this.wsurl() + '/gerer_etabl/add';
        } else {
            lien = this.wsurl() + '/gerer_etabl/update/' + data.id;
            methode = 'PUT';
        }
        data.iduser = this.get_userconnected().id;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${this.getToken()}`,
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
            if (response.status == 401) {
                this.disconnect();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }


    //Ajouter plusieurs rattachements
    add_gerer_etabl_batch(data) {
        var lien = this.wsurl() + '/gerer_etabl/add_batch';
        let methode = 'POST';
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${this.getToken()}`,
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
            if (response.status == 401) {
                this.disconnect();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }
    // Retourne la liste générale par CRD
    get_liste_gerer_etabl_by_crd(id) {
        var lien = this.wsurl() + '/gerer_etabl/find_by_crd/' + id;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                'Authorization': `${this.getToken()}`,
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
            if (response.status == 401) {
                this.disconnect();
            }
        }).then(data => {
            return data.data;
        }).catch((error) => {
            return error
        })
    }

    // Retourne la liste générale par user
    get_liste_gerer_etabl_by_user(id) {
        var lien = this.wsurl() + '/gerer_etabl/find_by_user/' + id;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                'Authorization': `${this.getToken()}`,
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
            if (response.status == 401) {
                this.disconnect();
            }
        }).then(data => {
            return data.data;
        }).catch((error) => {
            return error
        })
    }

    //Suppression d'une region
    delete_gerer_etabl(data) {
        var lien = this.wsurl() + '/gerer_etabl/delete/' + data.id;
        data.iduser = this.get_userconnected().id;
        return fetch(lien, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${this.getToken()}`,
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
            if (response.status == 401) {
                this.disconnect();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    /*###################  FIN ENDPOINTS DE GESTION DES GERER ETABLISSEMENT         ################### */


    /*###################  ENDPOINTS DE GESTION DES GERER            ################### */

    //Ajouter et modifier un rattachement
    save_gerer_ecole(data) {
        var lien = "";
        let methode = 'POST';
        if (data.id == 0) {
            lien = this.wsurl() + '/gerer_ecole/add';
        } else {
            lien = this.wsurl() + '/gerer_ecole/update/' + data.id;
            methode = 'PUT';
        }
        data.iduser = this.get_userconnected().id;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${this.getToken()}`,
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
            if (response.status == 401) {
                this.disconnect();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }


    //Ajouter plusieurs rattachements
    add_gerer_ecole_batch(data) {
        var lien = this.wsurl() + '/gerer_ecole/add_batch';
        let methode = 'POST';

        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${this.getToken()}`,
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
            if (response.status == 401) {
                this.disconnect();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }
    // Retourne la liste générale par école
    get_liste_gerer_ecole_by_ecole(id) {
        var lien = this.wsurl() + '/gerer_ecole/find_by_ecole/' + id;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                'Authorization': `${this.getToken()}`,
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
            if (response.status == 401) {
                this.disconnect();
            }
        }).then(data => {
            return data.data;
        }).catch((error) => {
            return error
        })
    }

    // Retourne la liste générale par user
    get_liste_gerer_ecole_by_user(id) {
        var lien = this.wsurl() + '/gerer_ecole/find_by_user/' + id;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                'Authorization': `${this.getToken()}`,
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
            if (response.status == 401) {
                this.disconnect();
            }
        }).then(data => {
            return data.data;
        }).catch((error) => {
            return error
        })
    }

    //Suppression d'une region
    delete_gerer_ecole(data) {
        var lien = this.wsurl() + '/gerer_ecole/delete/' + data.id;
        data.iduser = this.get_userconnected().id;
        return fetch(lien, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${this.getToken()}`,
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
            if (response.status == 401) {
                this.disconnect();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    /*###################  FIN ENDPOINTS DE GESTION DES GERER ECOLE         ################### */



    /*###################  ENDPOINTS DE GESTION DES PREFECTURES            ################### */

    //Ajouter et modifier une region
    save_prefecture(data) {
        var lien = "";
        let methode = 'POST';
        if (data.id == 0) {
            lien = this.wsurl() + '/prefectures';
        } else {
            lien = this.wsurl() + '/prefectures/' + data.id;
            methode = 'PUT';
        }
        data.iduser = this.get_userconnected().id;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${this.getToken()}`,
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
            if (response.status == 401) {
                this.disconnect();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    // Retourne la liste générale des préfectures
    get_liste_prefecture() {
        var lien = this.wsurl() + '/prefectures';
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                'Authorization': `${this.getToken()}`,
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
            if (response.status == 401) {
                this.disconnect();
            }
        }).then(data => {
            return data.data;
        }).catch((error) => {
            return error
        })
    }

    //Suppression d'une region
    delete_prefecture(data) {
        var lien = this.wsurl() + '/prefectures/' + data.id;
        data.iduser = this.get_userconnected().id;
        return fetch(lien, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${this.getToken()}`,
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
            if (response.status == 401) {
                this.disconnect();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    /*###################   FIN   ENDPOINT DE GESTION DES PREFECTURES            ################### */



    /*        ENDPOINT DE GESTION DES COMMUNES            */

    //Ajouter et modifier une commune
    save_commune(data) {
        var lien = "";
        let methode = 'POST';
        if (data.id == 0) {
            lien = this.wsurl() + '/crds';
        } else {
            lien = this.wsurl() + '/crds/' + data.id;
            methode = 'PUT';
        }
        data.iduser = this.get_userconnected().id;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${this.getToken()}`,
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
            if (response.status == 401) {
                this.disconnect();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    // Retourne la liste générale des communes
    get_liste_commune() {
        var lien = this.wsurl() + '/crds';
        return fetch(lien, {
            method: 'GET', mode: "cors",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=UTF-8',
                'Authorization': `${this.getToken()}`,
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
            if (response.status == 401) {
                this.disconnect();
            }
        }).then(data => {
            return data.data;
        }).catch((error) => {
            return error
        })
    }

    get_liste_crds_filtre(pidPref, pidUser) {
        var lien = this.wsurl() + '/etats/get_liste_crds_filtre/' + pidPref + '/' + pidUser;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                'Authorization': `${this.getToken()}`,
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
            if (response.status == 401) {
                this.disconnect();
            }
        }).then(data => {
            return data.data;
        }).catch((error) => {
            return error
        })
    }

    //Suppression d'une commune
    delete_commune(data) {
        var lien = this.wsurl() + '/crds/' + data.id;
        data.iduser = this.get_userconnected().id;
        return fetch(lien, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${this.getToken()}`,
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
            if (response.status == 401) {
                this.disconnect();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    /*     FIN   ENDPOINT DE GESTION DES COMMUNES            */



    /*###################  ENDPOINTS DE GESTION DES QUARTIERS            ################### */

    //Ajouter et modifier un quartier
    save_quartier(data) {
        var lien = "";
        let methode = 'POST';
        if (data.id == 0) {
            lien = this.wsurl() + '/quartiers/add';
        } else {
            lien = this.wsurl() + '/quartiers/update/' + data.id;
            methode = 'PUT';
        }
        data.iduser = this.get_userconnected().id;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${this.getToken()}`,
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
            if (response.status == 401) {
                this.disconnect();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    // Retourne la liste générale des quartiers
    get_liste_quartier() {
        var lien = this.wsurl() + '/quartiers/get_all';
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                'Authorization': `${this.getToken()}`,
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
            if (response.status == 401) {
                this.disconnect();
            }
        }).then(data => {
            return data.data;
        }).catch((error) => {
            return error
        })
    }

    // Retourne la liste  des quartiers par commune
    get_liste_quartier_by_commune(pidCommune) {
        var lien = this.wsurl() + '/quartiers/findByCommune/' + pidCommune;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                'Authorization': `${this.getToken()}`,
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
            if (response.status == 401) {
                this.disconnect();
            }
        }).then(data => {
            return data.data;
        }).catch((error) => {
            return error
        })
    }

    //Suppression d'un quartier
    delete_quartier(data) {
        var lien = this.wsurl() + '/quartiers/delete/' + data.id;
        data.iduser = this.get_userconnected().id;
        return fetch(lien, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${this.getToken()}`,
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
            if (response.status == 401) {
                this.disconnect();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    /*###################  FIN ENDPOINTS DE GESTION DES QUARTIERS         ################### */




    /*        ENDPOINT DE GESTION DES ECOLES            */

    //Ajouter et modifier une école
    save_ecoles(data) {
        var lien = "";
        let methode = 'POST';
        if (data.id == 0) {
            lien = this.wsurl() + '/ecoles';
        } else {
            lien = this.wsurl() + '/ecoles/' + data.id;
            methode = 'PUT';
        }
        data.iduser = this.get_userconnected().id;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${this.getToken()}`,
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
            if (response.status == 401) {
                this.disconnect();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    // Retourne la liste générale des écoles
    get_liste_ecole(communeId) {
        var lien = this.wsurl() + '/ecoles/' + communeId;
        return fetch(lien, {
            method: 'GET', mode: "cors",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=UTF-8',
                'Authorization': `${this.getToken()}`,
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
            if (response.status == 401) {
                this.disconnect();
            }
        }).then(data => {
            return data.data;
        }).catch((error) => {
            return error
        })
    }

    //Suppression d'une école
    delete_ecole(data) {
        var lien = this.wsurl() + '/ecoles/' + data.id;
        data.iduser = this.get_userconnected().id;
        return fetch(lien, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${this.getToken()}`,
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
            if (response.status == 401) {
                this.disconnect();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    /*     FIN   ENDPOINT DE GESTION DES ECOLES            */



    /*        ENDPOINT DE GESTION DES CLASSES            */

    //Ajouter et modifier une classe
    save_classe(data) {
        var lien = "";
        let methode = 'POST';
        if (data.id == 0) {
            lien = this.wsurl() + '/classe';
        } else {
            lien = this.wsurl() + '/classe/' + data.id;
            methode = 'PUT';
        }
        data.iduser = this.get_userconnected().id;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${this.getToken()}`,
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
            if (response.status == 401) {
                this.disconnect();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    // Retourne la liste générale des classes
    get_liste_classe() {
        var lien = this.wsurl() + '/classe';
        return fetch(lien, {
            method: 'GET', mode: "cors",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=UTF-8',
                'Authorization': `${this.getToken()}`,
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
            if (response.status == 401) {
                this.disconnect();
            }
        }).then(data => {
            return data.data;
        }).catch((error) => {
            return error
        })
    }

    //Suppression d'une classe
    delete_classe(data) {
        var lien = this.wsurl() + '/classe/' + data.id;
        data.iduser = this.get_userconnected().id;
        return fetch(lien, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${this.getToken()}`,
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
            if (response.status == 401) {
                this.disconnect();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    /*     FIN   ENDPOINT DE GESTION DES CLASSES            */



    /*     FIN   ENDPOINT DE GESTION DES ECOLES            */



    /*        ENDPOINT DE GESTION DES INSCRIPTIONS            */

    //Ajouter et modifier une classe
    update_inscription_bulk(data) {
        var lien = this.wsurl() + '/inscription/update_bulk';
        let methode = 'PUT';
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${this.getToken()}`,
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
            if (response.status == 401) {
                this.disconnect();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    //Ajouter les inscriptions en bloc
    add_inscription_bulk(data) {
        var lien = this.wsurl() + '/inscription/add_bulk';
        let methode = 'PUT';
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${this.getToken()}`,
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
            if (response.status == 401) {
                this.disconnect();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    //Ajouter et modifier une classe
    save_inscription(data) {
        var lien = "";
        let methode = 'POST';
        if (data.id == 0) {
            lien = this.wsurl() + '/inscription/add';
        } else {
            lien = this.wsurl() + '/inscription/update/' + data.id;
            methode = 'PUT';
        }
        data.iduser = this.get_userconnected().id;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${this.getToken()}`,
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
            if (response.status == 401) {
                this.disconnect();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    // Retourne la liste générale des inscriptions
    get_liste_inscription(pidCrd, pidEcole, pidClasse) {
        var lien = this.wsurl() + '/inscription/get_all/' + pidCrd + '/' + pidEcole + '/' + pidClasse;
        return fetch(lien, {
            method: 'GET', mode: "cors",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=UTF-8',
                'Authorization': `${this.getToken()}`,
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
            if (response.status == 401) {
                this.disconnect();
            }
        }).then(data => {
            return data.data;
        }).catch((error) => {
            return error
        })
    }

    // Retourne la liste générale par classe et session
    get_liste_inscription_classe_session(classeId, session) {
        var lien = this.wsurl() + '/inscription/find_by_classe_and_session/' + classeId + '/' + session;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                'Authorization': `${this.getToken()}`,
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
            if (response.status == 401) {
                this.disconnect();
            }
        }).then(data => {
            return data.data;
        }).catch((error) => {
            return error
        })
    }

    // Retourne la liste générale par élève
    get_liste_inscription_eleve(id) {
        var lien = this.wsurl() + '/inscription/find_by_eleve/' + id;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                'Authorization': `${this.getToken()}`,
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
            if (response.status == 401) {
                this.disconnect();
            }
        }).then(data => {
            return data.data;
        }).catch((error) => {
            return error
        })
    }

    // Retourne la liste générale par élève
    get_liste_inscription_matricule(matricule) {
        var lien = this.wsurl() + '/inscription/find_by_matricule/' + matricule;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                'Authorization': `${this.getToken()}`,
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
            if (response.status == 401) {
                this.disconnect();
            }
        }).then(data => {
            return data.data;
        }).catch((error) => {
            return error
        })
    }

    // Retourne la liste générale par élève
    get_liste_inscription_matricule_session(matricule, session, dce) {
        var lien = this.wsurl() + '/inscription/find_by_matricule_session/' + matricule + '/' + session + '/' + dce;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                'Authorization': `${this.getToken()}`,
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
            if (response.status == 401) {
                this.disconnect();
            }
        }).then(data => {
            return data.data;
        }).catch((error) => {
            return error
        })
    }


    //Suppression d'une inscription
    delete_inscription(data) {
        var lien = this.wsurl() + '/inscription/delete/' + data.id;
        data.iduser = this.get_userconnected().id;
        return fetch(lien, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${this.getToken()}`,
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
            if (response.status == 401) {
                this.disconnect();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    delete_inscription_bloc(data) {
        var lien = this.wsurl() + '/inscription/delete_bulk';
        return fetch(lien, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${this.getToken()}`,
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
            if (response.status == 401) {
                this.disconnect();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    /*     FIN   ENDPOINT DE GESTION DES CLASSES            */



    /*        ENDPOINT DE GESTION DES ELEVES            */

    //Ajouter et modifier un élève
    save_eleve(data) {
        var lien = "";
        let methode = 'POST';
        if (data.id == 0) {
            lien = this.wsurl() + '/eleves';
        } else {
            lien = this.wsurl() + '/eleves/' + data.id;
            methode = 'PUT';
        }
        data.iduser = this.get_userconnected().id;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${this.getToken()}`,
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
            if (response.status == 401) {
                this.disconnect();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    // Retourne la liste générale des élèves
    get_liste_eleve(pd1, pd2, pidClasse, pidEcole) {
        var lien = this.wsurl() + '/eleves';
        return fetch(lien, {
            method: 'GET', mode: "cors",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=UTF-8',
                'Authorization': `${this.getToken()}`,
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
            if (response.status == 401) {
                this.disconnect();
            }
        }).then(data => {
            return data.data;
        }).catch((error) => {
            return error
        })
    }

    //Suppression d'un élève
    delete_eleve(data) {
        var lien = this.wsurl() + '/eleves/' + data.id;
        data.iduser = this.get_userconnected().id;
        return fetch(lien, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${this.getToken()}`,
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
            if (response.status == 401) {
                this.disconnect();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    /*     FIN   ENDPOINT DE GESTION DES ELEVES            */



    /*###################  ENDPOINTS DE GESTION DES REGIONS            ################### */

    //Update password
    update_password(data, token) {
        var lien = this.wsurl() + '/users/update/' + data.id;
        let methode = 'PUT';
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${token}`,
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
            if (response.status == 401) {
                this.disconnect();
            }
        }).then(data => {

            return data;
        }).catch((error) => {
            return error
        })
    }

    //Ajouter et modifier une region
    save_utilisateur(data) {
        var lien = "";
        let methode = 'POST';
        if (data.id == 0) {
            lien = this.wsurl() + '/users/add';
        } else {
            lien = this.wsurl() + '/users/update/' + data.id;
            methode = 'PUT';
        }
        data.iduser = this.get_userconnected().id;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${this.getToken()}`,
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
            if (response.status == 401) {
                this.disconnect();
            }
        }).then(data => {

            return data;
        }).catch((error) => {
            return error
        })
    }

    // Retourne la liste générale des users
    get_liste_utilisateur() {
        var lien = this.wsurl() + '/users/get_all';
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                'Authorization': `${this.getToken()}`,
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
            if (response.status == 401) {
                this.disconnect();
            }
        }).then(data => {
            return data.data;
        }).catch((error) => {
            return error
        })
    }

    // Retourne l'utilisateur par login
    get_user_by_login(login) {
        var lien = this.wsurl() + '/users/getByLogin/' + login;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                'Authorization': `${this.getToken()}`,
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
            if (response.status == 401) {
                this.disconnect();
            }
        }).then(data => {
            return data.data;
        }).catch((error) => {
            return error
        })
    }

    //Suppression d'un user
    delete_utilisateur(data) {
        var lien = this.wsurl() + '/users/delete/' + data.id;
        data.iduser = this.get_userconnected().id;
        return fetch(lien, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${this.getToken()}`,
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
            if (response.status == 401) {
                this.disconnect();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }



    //Authentification d'un utilisateur
    get_identification(login, pwd) {
        var lien = this.wsurl() + '/users/get_identification';
        return fetch(lien, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ login: login, pwd: pwd })
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
            if (response.status == 401) {
                this.disconnect();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    /*###################  FIN ENDPOINTS DE GESTION DES USERS         ################### */



    /*###################  ENDPOINTS DE GESTION DES UTILS            ################### */


    download_file(namefile) {
        var lien = this.state.imgBaseUrl + '/' + namefile;
        return lien;
    }

    download_fichier_joint(namefile) {
        var lien = this.wsurl() + '/utils/files/' + namefile;
        window.location = lien;
    }

    upload_file(file, namefile) {
        var methode = 'POST';
        var lien = this.wsurl() + '/utils/upload/' + namefile;
        const formData = new FormData();
        formData.append('file', file);
        return fetch(lien, {
            method: methode,
            body: formData
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
            if (response.status == 401) {
                this.disconnect();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    /*###################  FIN ENDPOINTS DE GESTION DES UTILS         ################### */


    /*        ENDPOINT DE GESTION DES NOTES            */

    //Ajouter et modifier les notes
    update_note_bulk(data) {
        var lien = this.wsurl() + '/notes/update_bulk';
        let methode = 'PUT';
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${this.getToken()}`,
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
            if (response.status == 401) {
                this.disconnect();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    //Ajouter les notes en bloc
    add_note_bulk(data) {
        var lien = this.wsurl() + '/notes/add_bulk';
        let methode = 'PUT';
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${this.getToken()}`,
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
            if (response.status == 401) {
                this.disconnect();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }


    // Retourne la liste générale des inscriptions
    get_liste_note(classeId, matiereId, ecoleId) {
        var lien = this.wsurl() + '/notes/get_all/' + classeId + '/' + matiereId + '/' + ecoleId + '/' + this.get_selected_session();
        return fetch(lien, {
            method: 'GET', mode: "cors",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=UTF-8',
                'Authorization': `${this.getToken()}`,
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
            if (response.status == 401) {
                this.disconnect();
            }
        }).then(data => {
            return data.data;
        }).catch((error) => {
            return error
        })
    }

    // Retourne la liste générale par élève et par classe
    get_liste_note_eleve(inscriptionId) {
        var lien = this.wsurl() + '/notes/find_by_inscription/' + inscriptionId;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                'Authorization': `${this.getToken()}`,
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
            if (response.status == 401) {
                this.disconnect();
            }
        }).then(data => {
            return data.data;
        }).catch((error) => {
            return error
        })
    }


    //Suppression d'une note
    delete_note(data) {
        var lien = this.wsurl() + '/notes/delete/' + data.id;
        data.iduser = this.get_userconnected().id;
        return fetch(lien, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${this.getToken()}`,
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
            if (response.status == 401) {
                this.disconnect();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    delete_note_bloc(data) {
        var lien = this.wsurl() + '/notes/delete_bulk';
        return fetch(lien, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${this.getToken()}`,
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
            if (response.status == 401) {
                this.disconnect();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    /*     FIN   ENDPOINT DE GESTION DES NOTES            */


    /*###################  ENDPOINTS DE GESTION DES MATIERES            ################### */

    //Ajouter et modifier une matière
    save_matiere(data) {
        var lien = "";
        let methode = 'POST';
        if (data.id == 0) {
            lien = this.wsurl() + '/matieres';
        } else {
            lien = this.wsurl() + '/matieres/' + data.id;
            methode = 'PUT';
        }
        data.iduser = this.get_userconnected().id;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${this.getToken()}`,
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
            if (response.status == 401) {
                this.disconnect();
            }
        }).then(data => {

            return data;
        }).catch((error) => {
            return error
        })
    }

    // Retourne la liste générale des classes
    get_liste_matieres(classeId) {
        var lien = this.wsurl() + '/matieres/' + classeId;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                'Authorization': `${this.getToken()}`,
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
            if (response.status == 401) {
                this.disconnect();
            }
        }).then(data => {
            return data.data;
        }).catch((error) => {
            return error
        })
    }

    //Suppression d'une matière
    delete_matiere(data) {
        var lien = this.wsurl() + '/matieres/' + data.id;
        return fetch(lien, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${this.getToken()}`,
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
            if (response.status == 401) {
                this.disconnect();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    /*###################  FIN ENDPOINTS DE GESTION DES MATIERES         ################### */




    // Retourne la liste générale des traces par référence
    get_liste_trace_by_ref(pidPref, plibRef) {
        var lien = this.wsurl() + '/traces/find_by_ref/' + pidPref + '/' + plibRef;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                'Authorization': `${this.getToken()}`,
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
            if (response.status == 401) {
                this.disconnect();
            }
        }).then(data => {
            return data.data;
        }).catch((error) => {
            return error
        })
    }

}