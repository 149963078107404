import React, { Component } from 'react';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Toolbar } from 'primereact/toolbar';
import { classNames } from 'primereact/utils';
import AppServices from '../service/AppServices';
import { InputMask } from 'primereact/inputmask';
import 'moment';
import 'moment/locale/fr';
import moment from 'moment';
import { Steps } from 'primereact/steps';
import { SplitButton } from 'primereact/splitbutton';

export default class FormEcole extends Component {
    constructor() {
        super();
        this.state = {
            liste_data: [],
            liste_quartiers: [],
            liste_gerer: [],
            liste_commune: [],
            selectedData: null,
            selectedGerer: null,
            submitLogin: true,
            msgLogin: '',
            liste_profil: [
                { label: 'Inspecteur', value: 'Inspecteur' },
                { label: 'Gestionnaire', value: 'Gestionnaire' },
                { label: 'Agent de saisie', value: 'Agent de saisie' },
            ],
            liste_statut: [
                { value: 'Actif', label: 'Actif' },
                { value: 'Confirmé', label: 'Confirmé' },
                { value: 'Suspendu', label: 'Suspendu' },
                { value: 'En attente', label: 'En attente' },
                { value: 'Fermé', label: 'Fermé' },
            ],
            liste_categorie: [
                { value: 'Publique', label: 'Publique' },
                { value: 'Privée', label: 'Privée' }
            ],
            formData: {
                id: 0,
                sigle: '',
                iduser: "",
                nom: "",
                categorie: "",
                longitude: null,
                latitude: null,
                precision: null,
                num_arrete: "",
                telephone1: "",
                email: "",
                adresse: "",
                statut: "Actif",
                quartierId: { id: '' },
            },
            formDataUser: {
                id: 0,
                changer_pwd: true,
                login: "",
                pwd: "0000",
                nom: "",
                prenom: "",
                profil: "Gestionnaire",
                photo: "",
                telephone: "",
                email: "",
                iduser: "",
                statut: "Actif"
            },
            stepItems: [
                {
                    label: 'Adresse',
                    index: 0,
                    command: (event) => {

                    }
                },
                {
                    label: 'Responsable',
                    index: 1,
                    command: (event) => {

                    }
                }
            ],
            position: null,
            activeIndex: 0,
            file: null,
            urlfile: null,
            pCommuneId: 'Tous',
            visible_dlg_form_gerer: false,
            ////////////////////////////
            visible_dlg_detail: false,
            modif: false, // pour vérifier s'il s'agit d'une modification ou un ajout
            modif_gerer: false,
            visible: false, // pour afficher la boite de confirmation de suppression
            visible_dlg_form: false, // pour afficher la boite de dialogue du formation de saisie
            showindicator: false, // pour afficher l'état de chargement
            submit: false, // pour indiquer si le bouton Valider a été cliqueé et vérifier les champs obligatoires qui seront surlignés en rouge
            submitUser: false,
            //////////////////////////// 
        };
        this.itemTemplate = this.itemTemplate.bind(this);
        this.edit_data = this.edit_data.bind(this);
        this.edit_gerer = this.edit_gerer.bind(this);
        this.conf_delete_data = this.conf_delete_data.bind(this);
        this.toogleDlg = this.toogleDlg.bind(this);
        this.toogleDlgGerer = this.toogleDlgGerer.bind(this);
        this.appservice = new AppServices();
    }

    componentDidMount() {
        this.get_liste_data();
        this.get_liste_commune();

    }

    /* Récupération de la liste globale des quartiers */
    get_liste_commune() {
        this.setState({ showindicator: true });
        this.appservice.get_liste_commune().then(data => {
            this.setState({ showindicator: false });
            if (data != undefined) {
                let quartier = data.map(x => {
                    return { value: x.id, label: x.libelle, prefecture: x.prefectureId.libelle }
                });
                this.setState({ liste_commune: quartier });
            }
        });
    }

    get_location() {
        const success = position => {
            const latitude = position.coords.latitude;
            const longitude = position.coords.longitude;
            const precision = position.coords.accuracy;
            let form = this.state.formData;
            form.latitude = latitude;
            form.longitude = longitude;
            form.precision = precision;
            this.setState({
                position: { latitude: latitude, longitude: longitude, precision: precision },
                formData: form
            });
        };

        const error = () => {
            console.log("Unable to retrieve your location");
        };

        navigator.geolocation.getCurrentPosition(success, error);
    }

    get_liste_data() {
        this.setState({ showindicator: true });
        this.appservice.get_liste_ecole(this.state.pCommuneId).then(data => this.setState({ liste_data: data, showindicator: false }));
    }

    get_liste_gerer_by_ecole() {
        this.setState({ showindicator: true });
        this.appservice.get_liste_gerer_ecole_by_ecole(this.state.selectedData.id).then(data => {
            this.setState({ liste_gerer: data, showindicator: false });
        });
    }

    //Liste Des Quartiers
    get_liste_quartier() {
        this.setState({ showindicator: true, liste_quartiers: [] });
        this.appservice.get_liste_quartier_by_commune(this.state.pCommuneId).then(data => {
            this.setState({ showindicator: false });
            if (data.length > 0) {
                let quartier = data.map(x => {
                    return { value: x.id, label: x.libelle, commune: x.communeId.libelle }
                });
                this.setState({ liste_quartiers: quartier });
            }
        });
    }

    /* Annuler l'opération en cours et vider le formulaire */
    annuler() {
        this.get_location();
        this.setState({
            formData: {
                id: 0,
                sigle: '',
                iduser: "",
                nom: "",
                categorie: "",
                longitude: null,
                latitude: null,
                precision: null,
                num_arrete: "",
                telephone1: "",
                email: "",
                adresse: "",
                statut: "Actif",
                quartierId: { id: '' }
            },
            formDataUser: {
                id: 0,
                changer_pwd: true,
                login: "",
                pwd: "0000",
                nom: "",
                prenom: "",
                profil: "Gestionnaire",
                photo: "",
                telephone: "",
                email: "",
                iduser: "",
                statut: "Actif"
            },
        });

        this.setState({
            showindicator: false,
            visible_dlg_form_gerer: false,
            modif_gerer: false,
            file: null,
            urlfile: null
        });

        this.setState({ modif: false });
        this.setState({ visible_dlg_delete: false });
        this.setState({ visible_dlg_form: false });
    }

    /* Remplissage du formulaire avec les informations de la ligne sélectionnée dans le cadre de la modification  */
    edit_data(data) {
        if (data !== null && data !== undefined) {
            if (data.logo !== null && data.logo !== '') {
                this.setState({ urlfile: this.appservice.download_file(data.logo) })
            } else {
                this.setState({ urlfile: null })
            }
            this.setState({ formData: data });
            this.setState({ modif: true });
            this.setState({ visible_dlg_form: true });
            this.setState({ selectedData: data, pCommuneId: data.quartierId.communeId.id }, () => {
                this.get_liste_quartier();
            });
        }
    }

    /* Remplissage du formulaire avec les informations de la ligne sélectionnée dans le cadre de la modification  */
    edit_gerer(data) {
        if (data !== null && data !== undefined) {
            this.setState({ formDataUser: data.utilisateurId });
            this.setState({ modif_gerer: true });
            this.setState({ visible_dlg_form_gerer: true });
            this.setState({ selectedGerer: data });
        }
    }

    /* Enregistrer les informations du formulaire region */
    enreg_data() {
        this.setState({ submit: true });
        if (this.state.formData.nom == "" || this.state.formData.sigle == "" || this.state.formData.telephone1 == "" || this.state.formData.quartierId.id == 0) {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez renseigner tous les champs obligatoires' });
        } else {
            if (this.state.modif == false) {
                if (this.state.formDataUser.login == "" || this.state.formDataUser.nom == "" || this.state.formDataUser.prenom == "" || this.state.formDataUser.email == "" || this.state.formDataUser.telephone == "") {
                    this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez renseigner tous les champs obligatoires' });
                    return;
                }
            }
            let upload = false;
            let nomfic = this.state.formData.logo;
            if (this.state.file != null && this.state.file != undefined) {
                let name = this.state.file.name;
                nomfic = 'LOGO_U' + this.appservice.get_userconnected().id + moment(new Date()).format('DDMMYYYYHHmmss') + '.' + name.slice((name.lastIndexOf(".") - 1 >>> 0) + 2);
                upload = true;
            }
            let form = this.state.formData;
            form.logo = nomfic;
            form.utilisateurId = this.state.formDataUser;
            this.appservice.save_ecoles(form).then(reponse => {
                if (reponse != undefined && reponse != null) {
                    if (reponse.success == true) {
                        if (upload === true) {
                            this.fileUpload(this.state.file, nomfic);
                        }
                        this.get_liste_data();
                    }
                    this.resultat(reponse);
                }
            });
        }
    }


    /* Enregistrer les informations du formulaire utilisateur */
    enreg_data_gerer() {
        this.setState({ submit: true });
        if (this.state.formDataUser.login == "" || this.state.formDataUser.nom == "" || this.state.formDataUser.prenom == "" || this.state.formDataUser.telephone == "") {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez renseigner tous les champs obligatoires' });
        } else {
            this.setState({ showindicator: true });
            if (this.state.modif_gerer == false) {
                this.appservice.save_gerer_ecole(
                    {
                        id: 0,
                        utilisateurId: this.state.formDataUser,
                        ecoleId: this.state.selectedData
                    }
                ).then(reponse => {
                    if (reponse != undefined && reponse != null) {
                        this.resultat(reponse);
                        if (reponse.success == true) {
                            this.get_liste_gerer_by_ecole();
                        }
                    }
                });
            } else {
                this.appservice.save_utilisateur(this.state.formDataUser).then(reponse => {
                    if (reponse != undefined && reponse != null) {
                        this.resultat(reponse);
                        if (reponse.success == true) {
                            this.get_liste_gerer_by_ecole();
                        }
                    }
                });
            }

        }
    }

    /* Fonction d'affichage des messages retournés suite aux actions serveurs */
    resultat(rep) {
        if (rep.success == true) {
            this.toast.show({ severity: 'success', summary: 'Success', detail: rep.message });
            this.annuler();
        } else {
            this.toast.show({ severity: 'error', summary: 'Error', detail: rep.message });
        }
        this.setState({ submit: false });
    }

    actionTemplateGerer(rowData) {
        return <div className="grid" style={{ width: 90 }}>
            <div className="col"><Button onClick={(e) => this.edit_gerer(rowData)} style={{ width: 25, height: 25 }} icon="pi pi-pencil" /></div>
            <div className="col"><Button onClick={(e) => this.conf_delete_data(rowData)} className="p-button-danger" style={{ width: 25, height: 25 }} icon="pi pi-times" /></div>
        </div>
    }

    actionTemplate(rowData) {
        const items = [
            {
                label: 'Modifier',
                icon: 'pi pi-pencil',
                command: (e) => {
                    this.edit_data(rowData);
                }
            },
            {
                label: 'Supprimer',
                icon: 'pi pi-times',
                command: (e) => {
                    this.conf_delete_data(rowData);
                }
            },
            {
                label: 'Gestionnaires',
                icon: 'pi pi-eye',
                command: (e) => {
                    this.setState({ selectedData: rowData }, () => {
                        this.get_liste_gerer_by_ecole();
                    });
                    this.setState({ visible_dlg_detail: true })
                }
            }
        ]
        return <SplitButton label="Modifier" icon="pi pi-pencil" onClick={(e) => this.edit_data(rowData)} model={items}></SplitButton>

    }

    /* Fonction de suppression de la ligne sélectionnée */
    delete_data() {
        if (this.state.visible_dlg_detail == true) {
            if (this.state.selectedGerer == null || this.state.selectedGerer == undefined) {
                this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner la ligne à supprimer' });
            } else {
                this.appservice.delete_gerer_ecole(this.state.selectedGerer).then(reponse => {
                    this.resultat(reponse);
                    if (reponse.success == true) {
                        this.get_liste_gerer_by_ecole();
                    }
                });
            }
        } else {
            if (this.state.selectedData == null || this.state.selectedData == undefined) {
                this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner la ligne à supprimer' });
            } else {
                this.appservice.delete_ecole(this.state.selectedData).then(reponse => {
                    this.resultat(reponse);
                    if (reponse.success == true) {
                        this.get_liste_data();
                    }
                });
            }
        }

    }


    conf_delete_data(data) {
        if (data != null && data != undefined) {
            if (this.state.visible_dlg_detail == true) {
                this.setState({ selectedGerer: data });
                this.setState({ msg: data.utilisateurId.login });
                this.setState({ visible: true });
            } else {
                this.setState({ selectedData: data });
                this.setState({ msg: data.nom });
                this.setState({ visible: true });
            }
        }
    }
    /* Affichage de la boite de dialogue pour l'ajout Ecole */
    toogleDlgGerer() {
        this.annuler();
        this.setState({ visible_dlg_form_gerer: !this.state.visible_dlg_form_gerer });
    }


    /* Affichage de la boite de dialogue pour l'ajout Ecole */
    toogleDlg() {
        this.annuler();
        this.setState({ visible_dlg_form: !this.state.visible_dlg_form });
    }

    // Permet d'afficher la liste des salariés sous forme de tableau lors de la sélection dans le formulaire
    itemTemplate(option) {
        if (!option.value) {
            return option.label;
        }
        else {
            return (
                <div className="grid" style={{ padding: 10 }}>
                    <div className="col-8">
                        <label>{option.label}</label>
                    </div>
                    <div className="col-4">
                        <label>{option.prefecture}</label>
                    </div>
                </div>
            );
        }
    }

    /* Zone gauche de la toolbar */
    leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <Button label="Ajouter" icon="pi pi-plus" className="p-button-success mr-2" onClick={this.toogleDlg} style={{ width: 115 }} />
                    <Button label="Supprimer" disabled={this.state.selectedData == null} icon="pi pi-trash" className="p-button-danger" onClick={(e) => this.conf_delete_data(this.state.selectedData)} style={{ width: 115 }} />
                </div>
            </React.Fragment>
        )
    }

    /* Zone droite de la toolbar */
    rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : <Button label="Export" icon="pi pi-download" className="p-button-help" onClick={this.exportCSV} />}
            </React.Fragment>
        )
    }

    /* Exportation du tableau contenant la liste des agences en fichier CSV */
    exportCSV = () => {
        //ref={(el) => { this.dt = el; }}
        this.dt.exportCSV("Liste_ecole");
    }

    /* Uploader sur le serveur le logo */
    fileUpload(file, namefile) {
        this.appservice.upload_file(file, namefile).then(data => {
            if (data.success == true) {
                this.toast.show({ severity: 'success', summary: 'Success', detail: 'Chargement de la photo sur le serveur effectué avec succès' });
            } else {
                this.toast.show({ severity: 'error', summary: 'Error', detail: 'Echec upload de la photo sur le serveur' });
            }
        });
    }
    openFileUpload() {
        document.getElementById("hiddenFile").click();
    }

    checkForm() {
        if (this.state.activeIndex == 0) {
            this.setState({ submit: true });
            if (this.state.formData.nom == "" || this.state.formData.prenom == "" || this.state.formData.telephone1 == "" || this.state.formData.quartierId.id == 0) {
                this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez renseigner tous les champs obligatoires' });
                return;
            }
        } else if (this.state.activeIndex == 1) {
            this.setState({ submitUser: true });
            if (this.state.formDataUser.login == "" || this.state.formDataUser.telephone == "" || this.state.formDataUser.prenom == "" || this.state.formDataUser.nom == "") {
                this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez renseigner tous les champs obligatoires' });
                return;
            }
        } else {
            return;
        }
        this.setState({ activeIndex: this.state.activeIndex + 1 });
    }


    /* Fonction de validation du login */
    checkLogin() {
        this.setState({ submitLogin: true });
        this.appservice.get_user_by_login(this.state.formDataUser.login).then(user => {
            if (user !== null && user !== undefined) {
                this.setState({ submitLogin: false, msgLogin: 'Ce login existe déja' });
                this.setState({ formDataUser: user });
            } else {
                this.setState({ submitLogin: true, msgLogin: '' });
            }
        })
    }

    getInfoItem() {
        return <div className="p-fluid">
            <div className="formgrid grid">
                <div className="field col-12 md:col-4">
                    <label htmlFor="libelleStruct">Nom *</label>
                    <InputText id="name" value={this.state.formData.nom} className={classNames({ 'p-invalid': this.state.submit && !this.state.formData.nom })}
                        onChange={(e) => this.setState({ formData: this.appservice.onInputChange(e, 'nom', '', this.state.formData) })}
                        required autoFocus />
                    {this.state.submit && !this.state.formData.nom && <small className="p-invalid" style={{ color: 'red' }}>Le nom est obligatoire.</small>}
                </div>
                <div className="field col-12 md:col-4">
                    <label htmlFor="codeStruct">Sigle *</label>
                    <InputText id="name" value={this.state.formData.sigle} className={classNames({ 'p-invalid': this.state.submit && !this.state.formData.sigle })}
                        onChange={(e) => this.setState({ formData: this.appservice.onInputChange(e, 'sigle', '', this.state.formData) })}
                        required />
                    {this.state.submit && !this.state.formData.sigle && <small className="p-invalid" style={{ color: 'red' }}>Le sigle est obligatoire.</small>}
                </div>
                <div className="field col-12 md:col-4">
                    <label htmlFor="idCom">CRD *</label>
                    <Dropdown options={this.state.liste_commune} value={this.state.pCommuneId} optionLabel='label' filter={true} filterBy='label' filterMatchMode='contains' onChange={(e) => this.setState({ pCommuneId: e.value }, () => {
                        this.get_liste_quartier();
                    })} itemTemplate={this.itemTemplate} autoWidth={false} style={{ width: '100%' }} />
                </div>
                <div className="field col-12 md:col-4">
                    <label htmlFor="idQuartier">Quartier *</label>
                    <Dropdown autoWidth={false} value={this.state.formData.quartierId.id} filter={true} filterBy='label' filterMatchMode='contains'
                        options={this.state.liste_quartiers} panelStyle={{ width: '450px' }}
                        onChange={(e) => this.setState({ formData: this.appservice.onInputChange(e, 'quartierId', 'fk', this.state.formData) })}
                        appendTo={document.body} className={classNames({ 'p-invalid': this.state.submit && !this.state.formData.quartierId.id })} />
                    {this.state.submit && !this.state.formData.quartierId.id && <small className="p-invalid" style={{ color: 'red' }}>Le quartier est obligatoire.</small>}
                </div>

                <div className="field col-12 md:col-4">
                    <label htmlFor="name1">Statut *</label>
                    <Dropdown autoWidth={false} value={this.state.formData.statut} filter={true} filterBy='label' filterMatchMode='contains'
                        options={this.state.liste_statut}
                        onChange={(e) => this.setState({ formData: this.appservice.onInputChange(e, 'statut', '', this.state.formData) })}
                        appendTo={document.body} className={classNames({ 'p-invalid': this.state.submit && this.state.formData.statut == null })} />
                    {this.state.submit && !this.state.formData.statut && <small className="p-invalid" style={{ color: 'red' }}>Le statut est obligatoire.</small>}
                </div>

                <div className="field col-12 md:col-4">
                    <label htmlFor="precisionStruct">Téléphone * </label>
                    <InputMask id="tel" mask="999999999" value={this.state.formData.telephone1} className={classNames({ 'p-invalid': this.state.submit && !this.state.formData.telephone1 })}
                        onChange={(e) => this.setState({ formData: this.appservice.onInputChange(e, 'telephone1', '', this.state.formData) })}
                        required />
                    {this.state.submit && !this.state.formData.telephone1 && <small className="p-invalid" style={{ color: 'red' }}>Le téléphone est obligatoire.</small>}
                </div>

                <div className="field col-12 md:col-4">
                    <label htmlFor="longitudeStruct">N° arrêté </label>
                    <InputText id="name" value={this.state.formData.num_arrete} onChange={(e) => this.setState({ formData: this.appservice.onInputChange(e, 'num_arrete', '', this.state.formData) })} />
                </div>

                <div className="field col-12 md:col-4">
                    <label htmlFor="name1">Catégorie * </label>
                    <Dropdown autoWidth={false} value={this.state.formData.categorie} filter={true} filterBy='label' filterMatchMode='contains'
                        options={this.state.liste_categorie}
                        onChange={(e) => this.setState({ formData: this.appservice.onInputChange(e, 'categorie', '', this.state.formData) })}
                        appendTo={document.body} className={classNames({ 'p-invalid': this.state.submit && !this.state.formData.categorie })} />
                    {this.state.submit && !this.state.formData.categorie && <small className="p-invalid" style={{ color: 'red' }}>La categorie est obligatoire</small>}
                </div>

                <div className="field col-12 md:col-4">
                    <label htmlFor="longitudeStruct">Email </label>
                    <InputText id="name" value={this.state.formData.email} onChange={(e) => this.setState({ formData: this.appservice.onInputChange(e, 'email', '', this.state.formData) })} />
                </div>
                <div className="field col-12 md:col-4">
                    <label htmlFor="longitudeStruct">Longitude </label>
                    <div className="p-inputgroup">
                        <InputText id="name" value={this.state.formData.longitude} onChange={(e) => this.setState({ formData: this.appservice.onInputChange(e, 'longitude', '', this.state.formData) })} />
                        <Button onClick={() => this.get_location()} icon="pi pi-map-marker" className="p-button-rounded p-button-text p-button-plain" style={{ color: 'red' }} aria-label="Filter" />
                    </div>
                </div>
                <div className="field col-12 md:col-4">
                    <label htmlFor="latitudeStruct">Latitude </label>
                    <div className="p-inputgroup">
                        <InputText id="name" value={this.state.formData.latitude} onChange={(e) => this.setState({ formData: this.appservice.onInputChange(e, 'latitude', '', this.state.formData) })} />
                        <Button onClick={() => this.get_location()} icon="pi pi-map-marker" className="p-button-rounded p-button-text p-button-plain" style={{ color: 'red' }} aria-label="Filter" />
                    </div>
                </div>
                <div className="field col-12 md:col-4">
                    <label htmlFor="precisionStruct">Précision </label>
                    <div className="p-inputgroup">
                        <InputText id="name" value={this.state.formData.precision} onChange={(e) => this.setState({ formData: this.appservice.onInputChange(e, 'precision', '', this.state.formData) })} />
                        <Button onClick={() => this.get_location()} icon="pi pi-map-marker" className="p-button-rounded p-button-text p-button-plain" style={{ color: 'red' }} aria-label="Filter" />
                    </div>
                </div>


            </div>
        </div>;
    }

    getItem() {
        if (this.state.activeIndex == 0) {
            return this.getInfoItem()
        } else if (this.state.activeIndex == 1) {
            return <div className="p-fluid">
                <div className="p-fluid formgrid grid">
                    <div className="field col-12 md:col-6">
                        <label htmlFor="name1">Login * </label>
                        <InputText id="name1" value={this.state.formDataUser.login} className={classNames({ 'p-invalid': this.state.submitUser && !this.state.formDataUser.login })}
                            onChange={(e) => this.setState({ formDataUser: this.appservice.onInputChange(e, 'login', '', this.state.formDataUser) })}
                            required onBlur={(e) => {
                                if (this.state.formDataUser.login !== "") {
                                    this.checkLogin();
                                }
                            }} autoFocus />
                        {this.state.submitUser && !this.state.formDataUser.login && <small className="p-invalid" style={{ color: 'red' }}>Le login est obligatoire</small>}
                        {this.state.submitLogin == false && this.state.modif == false && <small className="p-invalid" style={{ color: 'red' }}>{this.state.msgLogin}.</small>}
                    </div>
                    <div className="field col-12 md:col-6">
                        <label htmlFor="email1">Nom *</label>
                        <InputText id="name" value={this.state.formDataUser.nom} className={classNames({ 'p-invalid': this.state.submitUser && !this.state.formDataUser.nom })}
                            onChange={(e) => this.setState({ formDataUser: this.appservice.onInputChange(e, 'nom', '', this.state.formDataUser) })}
                            required />
                        {this.state.submitUser && !this.state.formDataUser.nom && <small className="p-invalid" style={{ color: 'red' }}>Le nom est obligatoire.</small>}
                    </div>
                    <div className="field col-12 md:col-6">
                        <label htmlFor="email1">Prénom *</label>
                        <InputText id="name" value={this.state.formDataUser.prenom} className={classNames({ 'p-invalid': this.state.submitUser && !this.state.formDataUser.prenom })}
                            onChange={(e) => this.setState({ formDataUser: this.appservice.onInputChange(e, 'prenom', '', this.state.formDataUser) })}
                            required />
                        {this.state.submitUser && !this.state.formDataUser.prenom && <small className="p-invalid" style={{ color: 'red' }}>Le prénom est obligatoire.</small>}
                    </div>

                    <div className="field col-12 md:col-6">
                        <label htmlFor="email1">Téléphone *</label>
                        <InputMask id="tel" mask="999999999" value={this.state.formDataUser.telephone} className={classNames({ 'p-invalid': this.state.submitUser && !this.state.formDataUser.telephone })}
                            onChange={(e) => this.setState({ formDataUser: this.appservice.onInputChange(e, 'telephone', '', this.state.formDataUser) })}
                            required keyfilter='pint' />
                        {this.state.submitUser && !this.state.formDataUser.telephone && <small className="p-invalid" style={{ color: 'red' }}>Le téléphone est obligatoire.</small>}

                    </div>
                    <div className="field col-12 md:col-6">
                        <label htmlFor="email1">Email *</label>
                        <InputText id="name" value={this.state.formDataUser.email} className={classNames({ 'p-invalid': this.state.submitUser && !this.state.formDataUser.email })}
                            onChange={(e) => this.setState({ formDataUser: this.appservice.onInputChange(e, 'email', '', this.state.formDataUser) })}
                            required />
                        {this.state.submitUser && !this.state.formDataUser.email && <small className="p-invalid" style={{ color: 'red' }}>L'email est obligatoire.</small>}
                    </div>

                </div>
            </div>
        }

    }


    render() {

        const dialogFooter = (
            <div>
                <Button icon="pi pi-check" onClick={() => {
                    this.setState({ visible: false });
                    this.delete_data();
                }} label="Oui" />
                <Button icon="pi pi-times" onClick={() => this.setState({ visible: false })} label="Annuler" className="p-button-secondary" />
            </div>
        );
        return (
            <div className="card p-fluid col-12" style={{ height: 1250 }}>
                <Toast ref={(el) => this.toast = el} />
                <Dialog header="Confirmation" visible={this.state.visible} style={{ width: '250px' }} modal={true} footer={dialogFooter} onHide={() => this.setState({ visible: false })}>
                    <div>
                        <i className="fa fa-warning" style={{ color: '#FFD133', fontSize: 25 }}></i>
                        Voulez vous vraiment supprimer <b>{this.state.msg} </b>?
                    </div>
                </Dialog>
                {/* Toolbar contenant les boutons d'ajout, de suppression et d'exportation */}
                <Toolbar className="mb-4" left={this.leftToolbarTemplate} right={this.rightToolbarTemplate} style={{ paddingTop: 5, paddingBottom: 5 }} />

                {/* Tableau contenant la liste des Ecoles*/}
                <DataTable ref={(el) => { this.dt = el; }} value={this.state.liste_data} paginatorPosition="bottom" selectionMode="single" paginator={true} rows={20}
                    header={
                        <Dropdown id='pidCrd' options={[{ value: 'Tous', label: 'Toutes les CRDs' }].concat(this.state.liste_commune)} placeholder="Sélectionner la CRD" value={this.state.pCommuneId} onChange={(e) => this.setState({ pCommuneId: e.value }, () => {
                            this.get_liste_data();
                        })} autoWidth={false} style={{ width: 300 }} filter={true} filterBy='label' filterMatchMode='contains' />
                    }
                    alwaysShowPaginator={false} onRowClick={e => this.setState({ selectedData: e.data })} onRowSelect={e => this.setState({ selectedData: e.data })}
                    dataKey='id' responsiveLayout='scroll' selection={this.state.selectedData} className="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-customers" >
                    <Column header="No" body={(rowData, props) => {
                        return <div>
                            <span >{props.rowIndex + 1}</span>
                        </div>
                    }} style={{ width: '3%' }} />
                    <Column header="Action" body={this.actionTemplate.bind(this)} style={{ width: '8%' }} />
                    <Column field="nom" filter={true} filterMatchMode='contains' header="Nom" />
                    <Column field="sigle" filter={true} filterMatchMode='contains' header="Sigle" style={{ width: '10%' }} />
                    <Column field="telephone1" filter={true} filterMatchMode='contains' header="Téléphone" style={{ width: '10%' }} />
                    <Column field="statut" filter={true} filterMatchMode='contains' header="Statut" style={{ width: '10%' }} />
                    <Column field="quartierId.libelle" filter={true} filterMatchMode='contains' header="Quartier" style={{ width: '15%' }} />
                    <Column field="quartierId.communeId.libelle" filter={true} filterMatchMode='contains' header="Commune" style={{ width: '12%' }} />
                    <Column field="quartierId.communeId.prefectureId.libelle" filter={true} filterMatchMode='contains' header="Préfecture" style={{ width: '10%' }} />
                </DataTable>


                {/* Boite de dialogue d'ajout et de modification Des Ecoles */}
                <Dialog visible={this.state.visible_dlg_form} style={{ width: '70%' }} header={this.state.modif == true ? "Modification d'une école" : "Ajout d'une école"} modal className="p-fluid" onHide={(e) => this.setState({ visible_dlg_form: false })}
                    footer={
                        this.state.modif == false ?
                            <>

                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <Button label="RETOUR" disabled={this.state.activeIndex <= 0} icon="pi pi-angle-left" onClick={() => {
                                        this.setState({ activeIndex: this.state.activeIndex - 1 });
                                    }} style={{ width: 130 }} />
                                    {this.state.activeIndex == 1 ?
                                        <Button label="ENREGISTRER" loading={this.state.showindicator} icon="pi pi-check" onClick={(e) => this.enreg_data()} style={{ width: 130 }} className="p-button-success" />
                                        :
                                        <Button label="SUIVANT" disabled={this.state.activeIndex >= 1} icon="pi pi-angle-right" onClick={() => {
                                            this.checkForm();
                                        }} iconPos='right' style={{ width: 130 }} />
                                    }
                                </div>

                            </> :
                            <>
                                <Button label="Fermer" icon="pi pi-times" className="p-button-secondary" style={{ width: 120 }} onClick={(e) => this.annuler()} />
                                <Button label="Valider" icon="pi pi-check" onClick={(e) => this.enreg_data()} style={{ width: 120 }} />
                            </>

                    }>
                    <div className="col-12">
                        {this.state.modif == false ?
                            <>
                                <div className="grid" >
                                    <div className="col-10" style={{ height: 92 }}>
                                        <Steps model={this.state.stepItems} activeIndex={this.state.activeIndex} onSelect={(e) => this.setState({ activeIndex: e.index })} readOnly={true} style={{ marginTop: 20 }} />
                                    </div>
                                    <div className="col-2" style={{ height: 92 }}>
                                        <>
                                            <center>
                                                <a onClick={() => this.openFileUpload()} style={{ cursor: 'pointer' }}>
                                                    <div style={{ height: 80, width: 100, borderRadius: 10 }} >
                                                        <center>
                                                            {this.state.urlfile == null ?
                                                                <div style={{ width: '100px', height: '80px', borderRadius: 10, backgroundColor: '#f7f7f7', display: 'flex', justifyContent: 'center', padding: 30 }} ><label style={{ color: '#003366', fontWeight: 'bold', cursor: 'pointer' }}
                                                                >
                                                                    <i style={{ fontSize: 30, color: '#000', marginTop: -13 }} className="pi pi-camera"></i>  <label>LOGO</label>
                                                                </label></div> :
                                                                <img src={this.state.urlfile} style={{ width: '100px', height: '80px', borderRadius: 10, backgroundColor: '#f3f7fb', border: '1px solid #CCC' }} />}
                                                        </center>
                                                    </div>
                                                </a>
                                                <input type="file" id="hiddenFile" style={{ visibility: 'hidden' }} onChange={(e) => {
                                                    if (e.target.files && e.target.files[0]) {
                                                        this.setState({ file: e.target.files[0] }, () => {
                                                            let url = URL.createObjectURL(this.state.file);
                                                            this.setState({ urlfile: url });
                                                        })
                                                    }
                                                }} />
                                            </center>
                                        </>
                                    </div>
                                </div>
                                <div style={{ padding: 8, border: '1px solid #eee' }}>
                                    {this.getItem()}
                                </div>
                            </>
                            :
                            <>
                                {this.getInfoItem()}
                                <center>
                                    <a onClick={() => this.openFileUpload()} style={{ cursor: 'pointer' }}>
                                        <div style={{ height: 80, width: 100, borderRadius: 10 }} >
                                            <center>
                                                {this.state.urlfile == null ?
                                                    <div style={{ width: '100px', height: '80px', borderRadius: 10, backgroundColor: '#f7f7f7', display: 'flex', justifyContent: 'center', padding: 30 }} ><label style={{ color: '#003366', fontWeight: 'bold', cursor: 'pointer' }}
                                                    >
                                                        <i style={{ fontSize: 30, color: '#000', marginTop: -13 }} className="pi pi-camera"></i>  <label>LOGO</label>
                                                    </label></div> :
                                                    <img src={this.state.urlfile} style={{ width: '100px', height: '80px', borderRadius: 10, backgroundColor: '#f3f7fb', border: '1px solid #CCC' }} />}
                                            </center>
                                        </div>
                                    </a>
                                    <input type="file" id="hiddenFile" style={{ visibility: 'hidden' }} onChange={(e) => {
                                        if (e.target.files && e.target.files[0]) {
                                            this.setState({ file: e.target.files[0] }, () => {
                                                let url = URL.createObjectURL(this.state.file);
                                                this.setState({ urlfile: url });
                                            })
                                        }
                                    }} />
                                </center>
                            </>
                        }

                    </div>


                </Dialog>


                {/* Boite de dialogue contenant l'historique des traitements  */}
                <Dialog visible={this.state.visible_dlg_detail} style={{ width: '90%' }} header='Gestionnaires' modal className="p-fluid" onHide={(e) => this.setState({ visible_dlg_detail: false })}
                    footer={<>
                        <Button label="Fermer" loading={this.state.showindicator} icon="pi pi-times" className="p-button-secondary" style={{ width: 120 }} onClick={(e) => this.setState({ visible_dlg_detail: false })} />
                    </>}>
                    {/* Toolbar contenant les boutons d'ajout, de suppression et d'exportation */}
                    <Toolbar className="mb-4" left={() => {
                        return (
                            <React.Fragment>
                                <div className="my-2">
                                    <Button label="Ajouter" icon="pi pi-plus" className="p-button-success mr-2" onClick={this.toogleDlgGerer} style={{ width: 115 }} />
                                </div>
                            </React.Fragment>
                        )
                    }} right={() => {
                        return (
                            <React.Fragment>
                                {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                            </React.Fragment>
                        )
                    }}></Toolbar>
                    <DataTable value={this.state.liste_gerer} paginatorPosition="bottom" selectionMode="single" paginator={true} rows={5}
                        alwaysShowPaginator={false} onRowClick={e => this.setState({ selectedGerer: e.data })} onRowSelect={e => this.setState({ selectedGerer: e.data })}
                        responsive={true} selection={this.state.selectedGerer} className="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-customers" >
                        <Column header="Action" body={this.actionTemplateGerer.bind(this)} style={{ width: '8%' }} />
                        <Column field="utilisateurId.login" header="Login" style={{ width: '15%' }} filter={true} filterMatchMode='contains' />
                        <Column field="utilisateurId.nom" header="Nom" style={{ width: '15%' }} filter={true} filterMatchMode='contains' />
                        <Column field="utilisateurId.prenom" header="Prénom" filter={true} filterMatchMode='contains' />
                        <Column field="utilisateurId.telephone" header="Téléphone" style={{ width: '10%' }} filter={true} filterMatchMode='contains' />
                        <Column field="utilisateurId.profil" header="Profil" style={{ width: '20%' }} filter={true} filterMatchMode='contains' />
                    </DataTable>
                </Dialog>



                {/* Boite de dialogue d'ajout et de modification d'un utilisateur  */}
                <Dialog visible={this.state.visible_dlg_form_gerer} style={{ width: '70%' }} header={this.state.modif_gerer == true ? "Modification de compte utilisateur" : "Ajout de compte utilisateur"} modal className="p-fluid" onHide={(e) => this.setState({ visible_dlg_form_gerer: false })}
                    footer={<>

                        <Button label="Fermer" icon="pi pi-times" className="p-button-secondary" style={{ width: 120 }} onClick={(e) => this.setState({ visible_dlg_form_gerer: false, modif_gerer: false })} />
                        <Button label="Valider" icon="pi pi-check" onClick={(e) => this.enreg_data_gerer()} style={{ width: 120 }} />
                    </>}>

                    <div className="p-fluid formgrid grid">

                        <div className="field col-12 md:col-6">
                            <label htmlFor="name1">Login * </label>
                            <InputText id="name1" value={this.state.formDataUser.login} className={classNames({ 'p-invalid': this.state.submit && !this.state.formDataUser.login })}
                                onChange={(e) => this.setState({ formDataUser: this.appservice.onInputChange(e, 'login', '', this.state.formDataUser) })}
                                required autoFocus onBlur={(e) => {
                                    if (this.state.formDataUser.login !== "") {
                                        this.checkLogin();
                                    }
                                }} />
                            {this.state.submit && !this.state.formDataUser.login && <small className="p-invalid" style={{ color: 'red' }}>Le login est obligatoire</small>}
                            {this.state.submitLogin == false && this.state.modif_gerer == false && <small className="p-invalid" style={{ color: 'red' }}>{this.state.msgLogin}.</small>}
                        </div>

                        <div className="field col-12 md:col-6">
                            <label htmlFor="name1">Profil * </label>
                            <Dropdown autoWidth={false} value={this.state.formDataUser.profil} filter={true} filterBy='label' filterMatchMode='contains'
                                options={this.state.liste_profil}
                                onChange={(e) => this.setState({ formDataUser: this.appservice.onInputChange(e, 'profil', '', this.state.formDataUser) })}
                                appendTo={document.body} className={classNames({ 'p-invalid': this.state.submit && !this.state.formDataUser.profil })} />
                            {this.state.submit && !this.state.formDataUser.profil && <small className="p-invalid" style={{ color: 'red' }}>Le profil est obligatoire</small>}
                        </div>

                        <div className="field col-12 md:col-6">
                            <label htmlFor="email1">Nom *</label>
                            <InputText id="name" value={this.state.formDataUser.nom} className={classNames({ 'p-invalid': this.state.submit && !this.state.formDataUser.nom })}
                                onChange={(e) => this.setState({ formDataUser: this.appservice.onInputChange(e, 'nom', '', this.state.formDataUser) })}
                                required />
                            {this.state.submit && !this.state.formDataUser.nom && <small className="p-invalid" style={{ color: 'red' }}>Le nom est obligatoire.</small>}
                        </div>
                        <div className="field col-12 md:col-6">
                            <label htmlFor="email1">Prénom *</label>
                            <InputText id="name" value={this.state.formDataUser.prenom} className={classNames({ 'p-invalid': this.state.submit && !this.state.formDataUser.prenom })}
                                onChange={(e) => this.setState({ formDataUser: this.appservice.onInputChange(e, 'prenom', '', this.state.formDataUser) })}
                                required />
                            {this.state.submit && !this.state.formDataUser.prenom && <small className="p-invalid" style={{ color: 'red' }}>Le prénom est obligatoire.</small>}
                        </div>

                        <div className="field col-12 md:col-6">
                            <label htmlFor="email1">Téléphone *</label>
                            <InputMask id="tel" mask="999999999" value={this.state.formDataUser.telephone} className={classNames({ 'p-invalid': this.state.submit && !this.state.formDataUser.telephone })}
                                onChange={(e) => this.setState({ formDataUser: this.appservice.onInputChange(e, 'telephone', '', this.state.formDataUser) })}
                                required keyfilter='pint' />
                            {this.state.submit && !this.state.formDataUser.telephone && <small className="p-invalid" style={{ color: 'red' }}>Le téléphone est obligatoire.</small>}

                        </div>
                        <div className="field col-12 md:col-6">
                            <label htmlFor="email1">Email *</label>
                            <InputText id="name" value={this.state.formDataUser.email} className={classNames({ 'p-invalid': this.state.submit && !this.state.formDataUser.email })}
                                onChange={(e) => this.setState({ formDataUser: this.appservice.onInputChange(e, 'email', '', this.state.formDataUser) })}
                                required />
                            {this.state.submit && !this.state.formDataUser.email && <small className="p-invalid" style={{ color: 'red' }}>L'email est obligatoire.</small>}
                        </div>

                    </div>
                </Dialog>


            </div>
        );
    }
}