import React, { Component } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from "primereact/button";
import AppServices from '../service/AppServices';
import { ProgressSpinner } from 'primereact/progressspinner';
import 'moment';
import 'moment/locale/fr';
import moment from 'moment';

export class Login extends Component {
	constructor() {
		super();
		this.state = {
			showindicator: false,
			login: '',
			pwd: '',
			new_pwd: '',
			msg: '',
			changer_pwd: false,
			user: null
		};
		this.appservice = new AppServices();
	}

	handleKeyPress = e => {
		if (e.keyCode === 13) {
			this.identification();
		}
	}
  

	identification() {
		if (this.state.login == '' || this.state.pwd == '') {
			this.setState({ msg: 'Veuilllez saisir le login et le mot de passe' });
		} else {
			this.setState({ showindicator: true });
			this.appservice.get_identification(this.state.login, this.state.pwd).then(rep => {
				this.setState({ showindicator: false });
				if (rep !== null && rep != undefined && rep !== '') {
					if(rep.success==false){
						this.setState({ msg: "Login ou mot de passe incorrect !" });
					}else{
						let data=rep.data;
						if (data.statut === 'Actif') {
							if (data.changer_pwd == false) {
								let annee=parseInt(moment().format('MM'))>9?parseInt(moment().format('YYYY')):parseInt(moment().format('YYYY'))-1;
								localStorage.setItem('annee',annee+'-'+(annee+1));
								localStorage.setItem('userconnected', JSON.stringify(data));
								localStorage.setItem('token', rep.token);
								window.location = "#/dashboard";
							} else {
								localStorage.setItem('token', rep.token);
								this.setState({
									user: data,
									changer_pwd: true,
									pwd: '',
									new_pwd: '',
									msg: 'Vous devez changer ce mot de passe'
								})
							}
						} else {
							this.setState({ showindicator: false });
							this.setState({ msg: "Vous n'etes pas autorisés à vous connecter, merci de contacter l'administrateur " });
						}
					}
				} else {
					console.log(rep)
					this.setState({ showindicator: false });
					this.setState({ msg: "Login ou mot de passe incorrect !" });
				}
			});
		}
	}

	changer_pwd() {
		if (this.state.pwd == '') {
			this.setState({ msg: 'Veuilllez saisir le nouveau mot de passe' });
		} else if (this.state.new_pwd == '') {
			this.setState({ msg: 'Veuilllez confirmer le nouveau mot de passe' });
		} else if (this.state.new_pwd !== this.state.pwd) {
			this.setState({
				msg: "Les 2 mots de passe ne correspondent pas",
				pwd: '',
				new_pwd: ''
			});
		} else if (this.state.new_pwd == this.state.user.password) {
			this.setState({ msg: "Le nouveau mot de passe ne peut être identique à l'ancien" });
		} else {
			let data = this.state.user;
			data.changer_pwd = false;
			data.pwd = this.state.new_pwd;
			this.appservice.update_password(data,localStorage.getItem('token')).then(reponse => {
				if (reponse.success == true) {
					this.identification();
				}
			});
		}
	}

	render() {
		return (
			<div className="login-body">
				<div className="body-container">
					<div className="grid">
						<div className="col-12 lg:col-6 left-side">
							<img src="assets/layout/images/logoLogin.png" style={{ width: '300px', height: '270px' }} alt="apollo-layout" className='logo' />
							<h1>DGECS</h1>

							<p>
								Plateforme de gestion des inscriptions en ligne
							</p>
						</div>
						<div className="col-12 lg:col-6 right-side">
							<div className="login-wrapper">
								<div className="login-container">
									<span className="title">{this.state.changer_pwd == false ? 'Identifiez-vous' : 'Changement de mot de passe'}</span>

									<div className="grid p-fluid">
										<div className="col-12">
											<InputText placeholder="Login" disabled={this.state.changer_pwd} onKeyDown={this.handleKeyPress} onChange={(e) => this.setState({ login: e.target.value })} value={this.state.login} />
										</div>
										<div className="col-12">
											<InputText type="password" placeholder={this.state.changer_pwd == false ? "Mot de passe" : "Nouveau mot de passe"} onKeyDown={this.handleKeyPress} onChange={(e) => this.setState({ pwd: e.target.value })} value={this.state.pwd} />
										</div>
										{this.state.changer_pwd == true ?
											<div className="col-12">
												<InputText type="password" placeholder="Confirmez le nouveau mot de passe"  onChange={(e) => this.setState({ new_pwd: e.target.value })} value={this.state.new_pwd} />
											</div>
											: null}

										<div className="col-6">
											{this.state.changer_pwd == false ?
												<Button label="Se connecter" icon="pi pi-check" onClick={(e) => this.identification()} />
												:
												<>
													<div className="grid" >
														<div className="col-6"><Button label="Retour" icon="pi pi-ban" onClick={(e) => { this.setState({ changer_pwd: false }) }} className="p-button-secondary" style={{ width: 120 }} /></div>
														<div className="col-6"><Button label="Valider" icon="pi pi-check" onClick={(e) => this.changer_pwd()} className="p-button-success" style={{ width: 120, marginLeft: 60 }} /></div>
													</div>
												</>

											}
											{this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px', marginTop: '10px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
											<label style={{ color: 'red', marginTop: 8 }} >{this.state.msg}</label>
										</div>


									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}