import React, { Component } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { ProgressSpinner } from 'primereact/progressspinner';
import AppServices from '../service/AppServices';
import { Row } from 'primereact/row';
import { ColumnGroup } from 'primereact/columngroup';
import { Ripple } from 'primereact/ripple';
import { Panel } from 'primereact/panel';
import { Dropdown } from 'primereact/dropdown';
import { Chart } from 'primereact/chart';

/* Page d'édition des statistiques des écoles */
export default class EditionStatEcole extends Component {
    constructor() {
        super();
        this.state = {
            liste_data: [],
            liste_region: [],
            nbTotal: 0,
            showindicator: false,
            pRegion: 'Tous',
        };
        this.appservice = new AppServices();
    }



    /* Natif react js (s'exécute au montage ou chargement de la page )*/
    componentDidMount() {
        this.get_liste_data();
        this.get_liste_region();
    }


    /* Récupération de la liste globale des regions  */
    get_liste_region() {
        this.setState({ showindicator: true });
        this.appservice.get_liste_region().then(data => { 
            this.setState({showindicator: false });
            if (data != undefined) {
                let liste = data.map(x => {
                    return { value: x.id, label: x.libelle }
                });
                this.setState({ liste_region: liste });
            }
        });
    } 

    /* Récupération de la liste */
    get_liste_data() {
        this.setState({ showindicator: true, nbTotal: 0, liste_data: [] });
        this.appservice.get_liste_stat_ecole(this.state.pRegion).then(data => {
            if (data.length > 0) {
                let initialValue = 0;
                let nbTotal = data.reduce(function (accumulator, currentValue) {
                    return accumulator + currentValue.nb
                }, initialValue);
                this.setState({
                    nbTotal: nbTotal
                });
            }
            this.setState({
                liste_data: data,
                showindicator: false
            });
        });
    }
  

    /* Exportation du tableau  en fichier XLSX */
    exportExcel = () => {
        import('xlsx').then(xlsx => {
            const worksheet = xlsx.utils.json_to_sheet(this.state.liste_data);
            const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
            const excelBuffer = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
            this.saveAsExcelFile(excelBuffer, 'STAT_ECOLE');
        });
    }

    /* Enregistrer le fichier sur le client */
    saveAsExcelFile = (buffer, fileName) => {
        import('file-saver').then(module => {
            if (module && module.default) {
                let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                let EXCEL_EXTENSION = '.xlsx';
                const data = new Blob([buffer], {
                    type: EXCEL_TYPE
                });

                module.default.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
            }
        });
    }



    /* Bloc visuel (propre à React) */
    render() {

        let liste_label = [];
        let liste_ds = [];

        if(this.state.liste_data.length>0){
            liste_label = this.state.liste_data.map(x => {
                return x.libelle
            });
    
            liste_ds = this.state.liste_data.map(x => {
                return x.ivalue
            });
        }
      

        const options = {
            indexAxis: 'y',
            maintainAspectRatio: false,
            aspectRatio: 0.8,
            plugins: {
                tooltips: {
                    mode: 'index',
                    intersect: false
                },
                legend: {
                    labels: {
                        color: "#000"
                    }
                }
            },
            scales: {
                x: {
                    stacked: true,
                    ticks: {
                        color: '#000'
                    },
                    grid: {
                        color: '#f7f7f7'
                    }
                },
                y: {
                    stacked: true,
                    ticks: {
                        color: '#000'
                    },
                    grid: {
                        color: '#f7f7f7'
                    }
                }
            }
        };

        const chartData = {
            labels: liste_label,
            datasets: [
                {
                    type: 'bar',
                    label: 'Total',
                    backgroundColor: '#1164a3',
                    data: liste_ds
                },
            ]
        };



        const template = (options) => {
            const toggleIcon = options.collapsed ? 'pi pi-filter-fill' : 'pi pi-chevron-up';
            const className = `${options.className} justify-content-start`;
            const titleClassName = `${options.titleClassName} pl-1`;

            return (
                <a onClick={options.onTogglerClick} style={{ cursor: 'pointer' }}>
                    <div className={className}>
                        <button className={options.togglerClassName} onClick={options.onTogglerClick}>
                            <span className={toggleIcon}></span>
                            <Ripple />
                        </button>
                        <span className={titleClassName}>
                            Filtre avancé
                        </span>

                    </div>
                </a>
            )
        }

        let footerGroup = <ColumnGroup>
            <Row>
                <Column footer="TOTAL" colSpan={2} footerStyle={{ textAlign: 'center', fontWeight: 'bold' }} /> :
                <Column footer={this.state.nbTotal} style={{ color: '#000000', fontWeight: 'bold' }} />
            </Row>
        </ColumnGroup>;
 
        return (
            <div className="p-grid crud-demo">
                <div className="p-col-12">
                    <div className="card" style={{ height: 1200 }}>

                        {/* Toast pour l'afficahge des messages de dialogue */}
                        <Toast ref={(el) => this.toast = el} />
                        {/* Options de recherche */}
                        <Panel headerTemplate={template} collapsed={true} toggleable style={{ marginBottom: 10 }}>
                            <div className="grid">
                                <div className="col-6 md:col-3" >
                                    <span className="p-float-label" style={{ marginTop: 8 }}>
                                        <Dropdown options={[{value: 'Tous',label: 'Tous'}].concat(this.state.liste_region)} placeholder="Sélectionner" value={this.state.pRegion} onChange={(e) => this.setState({ pRegion: e.value },()=>{
                                            this.get_liste_data();
                                        })} autoWidth={false} style={{width: '100%'}} filter={true} filterBy='label' filterMatchMode='contains'/>
                                        <label htmlFor="inputtext" >Region</label>
                                    </span>
                                </div>
                                <div className="col-6 md:col-2" style={{ marginTop: 8 }}>
                                    <Button label="RECHERCER" style={{ width: '130px' }} onClick={(e) => this.get_liste_data()} icon="pi pi-search" />
                                </div>
                                <div className="col-6 md:col-1" style={{ marginTop: 8 }}>
                                    {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                                </div>
                            </div>
                        </Panel>

                        <div className="grid">
 
                        <div className="col-12 md:col-6 lg:col-6" >
                            {/* Tableau contenant la liste générale du filtre */}
                            <DataTable ref={(el) => { this.dt = el; }} value={this.state.liste_data}
                                paginator rows={20} resizableColumns="scroll" className="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-customers"
                                showGridlines
                                emptyMessage="No data found" paginatorPosition='top'
                                paginatorRight={<Button label="Exporter XLS" icon="pi pi-file-excel" className="p-button-help mr-2" onClick={this.exportExcel} style={{ width: 150 }} />}
                                /* footerColumnGroup={footerGroup}  */
                                alwaysShowPaginator={true} >
                                <Column header="No" body={(rowData, props) => {
                                    return <div>
                                        <span >{props.rowIndex + 1}</span>
                                    </div>
                                }} style={{ width: '3%' }} />
                                <Column field="libelle" header="Préfecture" filter={true} filterMatchMode='contains' sortable />
                                <Column field="ivalue" header="Nb" sortable style={{ width: '15%' }} />
                                <Column field="nb_g" header="TTG" sortable style={{ width: '15%' }} />
                                <Column field="nb_g" header="TTG" sortable style={{ width: '15%' }} />
                                <Column field="nb" header="Effectif" sortable style={{ width: '15%' }} />
                            </DataTable>
                        </div>

                        <div className="col-12 md:col-6 lg:col-6" >
                            <Chart type="bar" data={chartData} options={options} height='700w' />
                        </div>

                        </div>
                    </div>
                </div>
            </div >
        );
    }
}
