import React, { Component } from 'react';
import AppServices from '../service/AppServices';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Toolbar } from 'primereact/toolbar';
import 'moment';
import 'moment/locale/fr';
import moment from 'moment';
import { classNames } from 'primereact/utils';
import { TabView, TabPanel } from 'primereact/tabview';
import { SplitButton } from 'primereact/splitbutton';
import { Panel } from 'primereact/panel';
import { Ripple } from 'primereact/ripple';
import { InputTextarea } from 'primereact/inputtextarea';
import jsPDF from 'jspdf'
import $ from 'jquery';
import QRCode from 'qrcode';



export default class FormTransfert extends Component {
    constructor() {
        super();
        this.state = {
            liste_trace: [],
            liste_data: [],
            liste_ecole: [],
            liste_parcours: [],
            liste_crd_srce: [],
            liste_crd_dest: [],
            liste_classe: [],
            liste_dce: [],
            pidCrd2: 'Tous',
            pidCrd1: 'Tous',
            pidCrd: 'Tous',
            //pMatricule: '23O-026-24-57',
            rsession: '',
            rdce: '',
            pMatricule: '',
            prefTransfert: '',
            liste_item_export: [
                {
                    label: 'Exporter en CVS',
                    icon: 'pi pi-download',
                    command: (e) => {
                        this.exportCSV();
                    }
                },
                {
                    label: 'Exporter en XLS',
                    icon: 'pi pi-file-excel',
                    command: (e) => {
                        this.exportExcel();
                    }
                },
                {
                    label: 'Exporter en PDF',
                    icon: 'pi pi-file-pdf',
                    command: (e) => {
                        this.exportPdf();
                    }
                },
            ],
            selectedInsc: null,
            selectedData: null,
            formData: {
                id: 0,
                statut: 'En attente',
                id_insc: 0,
                id_crd_dest: 0,
                id_insc_dest: 0,
                id_ecole_dest: 0,
                ecoleId: { id: 0 },
                inscriptionId: { id: 0 },
                communeId: { id: 0 },
                inscriptionIdDest: {id: 0},
                iduser: '',
                date_statut: null,
                motif: '',
            },
            formInsc: {
                id: 0,
                date_insc: '',
                photo: '',
                statut: 'P',
                iduser: '',
                id_classe: 0,
                classeId: { id: 0 },
                eleveId: { id: 0 },
                ecoleId: { id: 0 },
                statut_academique: "Normal",
                annee_insc: '2023-2024',
                moy: 0,
            },
            nom: "",
            prenom: "",
            date_naiss: "",
            lieu_naiss: "",
            pere: "",
            mere: "",
            file: null,
            urlfile: null,

            ////////////////////////////
            modif_data: false, // pour vérifier s'il s'agit d'une modification ou un ajout
            visible: false, // pour afficher la boite de confirmation de suppression
            visible_dlg_form: false, // pour afficher la boite de dialogue du formation de saisie
            visible_dlg_insc: false, // pour afficher la boite de dialogue du formation de saisie
            showindicator: false, // pour afficher l'état de chargement
            visible_dlg_detail: false,
            visible_dlg_validation: false,
            submit: false // pour indiquer si le bouton Valider a été cliqueé et vérifier les champs obligatoires qui seront surlignés en rouge
            ////////////////////////////
        };
        this.edit_data = this.edit_data.bind(this);
        this.conf_delete_data = this.conf_delete_data.bind(this);
        this.conf_print_data = this.conf_print_data.bind(this);

        this.itemTemplate = this.itemTemplate.bind(this);
        this.toogleDlg = this.toogleDlg.bind(this);
        this.appservice = new AppServices();

    }

    componentDidMount() {
        this.get_liste_data();
        this.get_liste_crd();
        this.get_liste_classe();
    }

    get_liste_crd() {
        this.appservice.get_liste_commune().then(data => {
            if (data != null && data != undefined) {
                let liste = data.map(x => {
                    return { value: x.id, label: x.libelle, prefecture: x.prefectureId.libelle }
                });
                this.setState({ liste_crd_srce: liste, liste_crd_dest: liste });
            }
        });
    }

    get_liste_dce() {
        this.appservice.get_liste_dce(this.state.rsession).then(data => this.setState({ liste_dce: data }));
    }

    conf_print_data = (data, document) => {
        if (document == 'Orientation') {
            let code = JSON.stringify({
                idTransfert: data.id,
                Nom: data.inscriptionId.eleveId.nom,
                Prenom: data.inscriptionId.eleveId.prenom,
                Matricule: data.inscriptionId.eleveId.matricule,
                DateNaissance: data.inscriptionId.eleveId.date_naiss,
                LieuNaissance: data.inscriptionId.eleveId.lieu_naiss,
                Pere: data.inscriptionId.eleveId.pere,
                Mere: data.inscriptionId.eleveId.mere,
                DceOrigine: data.inscriptionId.ecoleId.quartierId.communeId.libelle,
                EcoleOrigine: data.inscriptionId.ecoleId.nom,
                DceDestination: data.ecoleIdDest.quartierId.communeId.libelle,
                EcoleDestination: data.ecoleIdDest.nom,
                DateTransfert: moment(data.createdAt).format("DD/MM/YYYY"),
                Motif: data.motif
            });

            QRCode.toDataURL(code)
                .then(url => {
                    if (data != null && data != undefined) {
                        this.setState({ selectedData: data });
                        import('jspdf-autotable').then(() => {
                            var doc = new jsPDF("p", "pt");
                            var img = new Image()
                            img.src = 'assets/layout/images/entete.jpg';
                            doc.addImage(img, 'jpg', 20, 20, 555, 80)

                            doc.setFontSize(30);
                            doc.text(40, 150, "RECEPISSE D'ORIENTATION");

                            var imgQr = new Image()
                            imgQr.src = url;
                            doc.addImage(imgQr, 'png', 470, 105, 90, 90)

                            doc.setFontSize(20);
                            doc.setTextColor(255, 0, 0);
                            doc.text(220, 180, "No " + data.id, { align: "center" });

                            doc.setTextColor(0, 0, 0);
                            doc.setFontSize(12);

                            doc.autoTable({
                                startY: 210,
                                body: [
                                    ['Prénom et Nom', data.inscriptionId.eleveId.prenom + " " + data.inscriptionId.eleveId.nom],
                                    ['Matricule', data.inscriptionId.eleveId.matricule],
                                    ['Classe', data.inscriptionId.classeId.libelle],
                                    ['Date de naissance', data.inscriptionId.eleveId.date_naiss],
                                    ['Lieu de naissance', data.inscriptionId.eleveId.lieu_naiss],
                                    ['Père', data.inscriptionId.eleveId.pere],
                                    ['Mère', data.inscriptionId.eleveId.mere],
                                    ["DPE/DCE d'origine", data.inscriptionId.ecoleId.quartierId.communeId.libelle],
                                    ["Ecole d'origine", data.inscriptionId.ecoleId.nom],
                                    ["DPE/DCE de destination", data.ecoleIdDest.quartierId.communeId.libelle],
                                    ["Ecole de destination", data.ecoleIdDest.nom],
                                    ['Date de transfert', moment(data.createdAt).format("DD/MM/YYYY")],
                                    ['Motif', data.motif]
                                ],
                                styles: { cellPadding: 2, fontSize: 12, fontStyle: 'bold', minCellHeight: 30 },
                                bodyStyles: { valign: 'middle' },
                            })

                            doc.text(200, 650, "VISA DE LA DPE/DCE");

                            doc.save("RECEPISSE_TRANSFERT_" + data.id + '_' + moment(new Date()).format('DDMMYYYYHHmmss') + ".pdf");
                        })

                    }

                })
                .catch(err => {
                    console.error(err)
                })
        } else {
            let code = JSON.stringify({
                idTransfert: data.id,
                Nom: data.inscriptionId.eleveId.nom,
                Prenom: data.inscriptionId.eleveId.prenom,
                Matricule: data.inscriptionId.eleveId.matricule,
                DateNaissance: data.inscriptionId.eleveId.date_naiss,
                LieuNaissance: data.inscriptionId.eleveId.lieu_naiss,
                Pere: data.inscriptionId.eleveId.pere,
                Mere: data.inscriptionId.eleveId.mere,
                Ecole: data.inscriptionId.ecoleId.nom,
                DCE: data.inscriptionId.ecoleId.quartierId.communeId.libelle,
                DateTransfert: moment(data.createdAt).format("DD/MM/YYYY"),
                Motif: data.motif
            });
 
            QRCode.toDataURL(code)
                .then(url => {
                    if (data != null && data != undefined) {
                        this.setState({ selectedData: data });
                        import('jspdf-autotable').then(() => {
                            var doc = new jsPDF("p", "pt");
                            var img = new Image()
                            img.src = 'assets/layout/images/entete.jpg';
                            doc.addImage(img, 'jpg', 20, 20, 555, 80)

                            doc.setFontSize(30);
                            doc.text(40, 150, "RECEPISSE DE TRANSFERT");

                            var imgQr = new Image()
                            imgQr.src = url;
                            doc.addImage(imgQr, 'png', 470, 105, 90, 90)

                            doc.setFontSize(20);
                            doc.setTextColor(255, 0, 0);
                            doc.text(220, 180, "No " + data.id, { align: "center" });

                            doc.setTextColor(0, 0, 0);
                            doc.setFontSize(12);

                            doc.autoTable({
                                startY: 210,
                                body: [
                                    ['Prénom et Nom', data.inscriptionId.eleveId.prenom + " " + data.inscriptionId.eleveId.nom],
                                    ['Matricule', data.inscriptionId.eleveId.matricule],
                                    ['Classe', data.inscriptionId.classeId.libelle],
                                    ['Date de naissance', data.inscriptionId.eleveId.date_naiss],
                                    ['Lieu de naissance', data.inscriptionId.eleveId.lieu_naiss],
                                    ['Père', data.inscriptionId.eleveId.pere],
                                    ['Mère', data.inscriptionId.eleveId.mere],
                                    ['DPE/DCE', data.inscriptionId.ecoleId.quartierId.communeId.libelle],
                                    ["Ecole d'origine", data.inscriptionId.ecoleId.nom],
                                    ['Date de transfert', moment(data.createdAt).format("DD/MM/YYYY")],
                                    ['Motif', data.motif]
                                ],
                                styles: { cellPadding: 2, fontSize: 12, fontStyle: 'bold', minCellHeight: 30 },
                                bodyStyles: { valign: 'middle' },
                            })

                            doc.text(200, 600, "VISA DE LA DPE/DCE");

                            doc.save("RECEPISSE_TRANSFERT_" + data.id + '_' + moment(new Date()).format('DDMMYYYYHHmmss') + ".pdf");
                        })

                    }

                })
                .catch(err => {
                    console.error(err)
                })
        }
        /*  
            doc.addFont("helvetica", "normal");
            doc.text(70, 220, "Nom:");
            doc.text(70, 255, "Prénom:");
            doc.text(70, 290, "Matricule:");
            doc.text(70, 325, "Ecole d'origine:");
            doc.text(70, 360, "DCE:");
            doc.text(70, 395, "Date:");
            doc.text(70, 430, "Motif:");

            //doc.text(200, 210, data.inscriptionId.eleveId.nom).setFont(undefined, 'bold');
            doc.text(70, 220, "").setFont(undefined, 'bold');
            doc.text(200, 220, data.inscriptionId.eleveId.nom).setFont(undefined, 'bold');
            doc.text(200, 255, data.inscriptionId.eleveId.prenom).setFont(undefined, 'bold');
            doc.text(200, 290, data.inscriptionId.eleveId.matricule).setFont(undefined, 'bold');
            doc.text(200, 325, data.inscriptionId.classeId.ecoleId.nom).setFont(undefined, 'bold');
            doc.text(200, 360, data.inscriptionId.classeId.ecoleId.quartierId.communeId.libelle);
            doc.text(200, 395, moment(data.createdAt).format("DD/MM/YYYY")).setFont(undefined, 'bold');
            doc.text(200, 430, data.motif).setFont(undefined, 'bold'); 
        */
    }

    //Liste des classes
    get_liste_classe() {
        this.setState({ showindicator: true, liste_classe: [] });
        this.appservice.get_liste_classe().then(data => {
            this.setState({ showindicator: false });
            if (data != undefined) {
                if (data.length > 0) {
                    let liste = data.map(x => {
                        return { value: x.id, label: x.libelle }
                    });
                    this.setState({ liste_classe: liste });
                }
            }
        });
    }

    //Liste des écoles
    get_liste_ecole() {
        this.setState({ showindicator: true, liste_ecole: [] });
        this.appservice.get_liste_ecole(this.state.pidCrd).then(data => {
            this.setState({ showindicator: false });
            if (data != undefined) {
                if (data.length > 0) {
                    let liste = data.map(x => {
                        return { value: x.id, label: x.nom }
                    });
                    this.setState({ liste_ecole: liste });
                }
            }
        });
    }

    search() {
        if (this.state.pMatricule == "") {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez renseigner le matricule ou (le PV et la session)' });
            return;
        }
        this.setState({ showindicator: true });
        this.appservice.get_liste_inscription_matricule_session(this.state.pMatricule, this.state.rsession == "" ? "NA" : this.state.rsession, this.state.rdce == "" ? "NA" : this.state.rdce).then(data => {
            this.setState({
                showindicator: false,
                liste_parcours: data
            });
            if (data.length > 0) {
                let val = data[data.length - 1];
                let formData = this.state.formData;
                formData.inscriptionId = val;
                formData.id_insc = val.id;
                this.setState({
                    nom: val.eleveId.nom,
                    prenom: val.eleveId.prenom,
                    date_naiss: val.eleveId.date_naiss,
                    lieu_naiss: val.eleveId.lieu_naiss,
                    pere: val.eleveId.pere,
                    mere: val.eleveId.mere,
                    selectedInsc: val,
                    formData: formData
                });
                if (val.photo !== null && val.photo !== '') {
                    this.setState({ urlfile: this.appservice.download_file(val.photo) })
                } else {
                    this.setState({ urlfile: null })
                }
            } else {
                this.toast.show({ severity: 'warn', summary: 'Attention', detail: "Aucun résultat pour les critères fournis" });
                this.setState({ urlfile: null });
                this.setState({ file: null, selectedInsc: null });
                this.setState({
                    nom: "",
                    prenom: "",
                    date_naiss: "",
                    lieu_naiss: "",
                    pere: "",
                    mere: "",
                    selectedInsc: null,
                    //formData: null
                });
            }
        });
    }

    get_liste_data() {
        this.setState({ showindicator: true });
        this.appservice.get_liste_transferts(this.state.pidCrd1, this.state.pidCrd2, this.state.pMatricule == '' ? 'Tous' : this.state.pMatricule).then(data => {
            this.setState({ liste_data: data, showindicator: false });
            console.log(data);
        });
    }

    get_liste_trace() {
        this.setState({ showindicator: true });
        this.appservice.get_liste_trace_by_ref(this.state.selectedData.id, 'Transferts').then(data => this.setState({ liste_trace: data, showindicator: false }));
    }

    /* Annuler l'opération en cours et vider le formulaire */
    annuler() {
        this.setState({
            formData: {
                id: 0,
                statut: 'En attente',
                id_insc: 0,
                id_crd_dest: 0,
                id_ecole_dest: 0,
                inscriptionId: { id: 0 },
                communeId: { id: 0 },
                ecoleId: { id: 0 },
                id_insc_dest: 0,
                inscriptionIdDest: {id: 0},
                iduser: '',
                date_statut: null,
                motif: '',
            },
            nom: "",
            prenom: "",
            date_naiss: "",
            lieu_naiss: "",
            pere: "",
            mere: "",
            selectedInsc: null,
            liste_parcours: []
        });
        if (this.state.modif_data == true) {
            this.setState({ visible_dlg_form: false });
        } else {
            //this.inputNom.focus();
        }
        this.setState({ showindicator: false });
        this.setState({
            modif_data: false,
            file: null,
            urlfile: null,
            pMatricule: '',
            visible_dlg_validation: false,
            visible_dlg_insc: false,
        });
    }

    /* Remplissage du formulaire avec les informations de la ligne sélectionnée dans le cadre de la modification  */
    edit_data(data) {
        if (data !== null && data !== undefined) {
            this.setState({ formData: data });
            this.setState({ modif_data: true });
            this.setState({ visible_dlg_form: true });
            this.setState({ selectedData: data }, () => {
                this.get_liste_trace();
            });
            this.setState({
                nom: data.inscriptionId.eleveId.nom,
                prenom: data.inscriptionId.eleveId.prenom,
                pMatricule: data.inscriptionId.eleveId.matricule,
                date_naiss: data.inscriptionId.eleveId.date_naiss,
                lieu_naiss: data.inscriptionId.eleveId.lieu_naiss,
                pere: data.inscriptionId.eleveId.pere,
                mere: data.inscriptionId.eleveId.mere,
            }, () => {
                this.appservice.get_liste_inscription_matricule(this.state.pMatricule).then(data_insc => {
                    this.setState({
                        showindicator: false,
                        liste_parcours: data_insc
                    });
                });
            })
            if (data.inscriptionId.eleveId.photo !== null && data.inscriptionId.eleveId.photo !== '') {
                this.setState({ urlfile: this.appservice.download_file(data.inscriptionId.eleveId.photo) })
            } else {
                this.setState({ urlfile: null })
            }

        }
    }

    accepter_transfert() {
        this.setState({ submit: true });
        if (this.state.formInsc.classeId.id == 0) {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez choisir la classe' });
        } else {
            let form=this.state.selectedData;
            form.inscriptionIdDest=this.state.formInsc;
            this.appservice.save_transfert(form).then(reponse => {
                if (reponse != undefined && reponse != null) {
                    if (reponse.success == true) {
                        this.get_liste_data();
                    }
                    this.resultat(reponse);
                }
            });
        }
    }

    /* Enregistrer les informations du formulaire region */
    enreg_data() {
        this.setState({ submit: true });
        if (this.state.formData.motif == "" || this.state.formData.inscriptionId.id == 0 || this.state.formData.communeId.id == 0) {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez renseigner tous les champs obligatoires' });
        } else {
            this.appservice.save_transfert(this.state.formData).then(reponse => {
                if (reponse != undefined && reponse != null) {
                    if (reponse.success == true) {
                        this.get_liste_data();
                    }
                    this.resultat(reponse);
                }
            });
        }
    }

    /* Fonction d'affichage des messages retournés suite aux actions serveurs */
    resultat(rep) {
        if (rep.success == true) {
            this.toast.show({ severity: 'success', summary: 'Success', detail: rep.message });
            this.annuler();
        } else {
            this.toast.show({ severity: 'error', summary: 'Error', detail: rep.message });
        }
        this.setState({ submit: false });
    }



    actionTemplate(rowData, props) {
        let items = [
            {
                label: 'Récépissé transfert',
                icon: 'pi pi-print',
                command: (e) => {
                    this.conf_print_data(rowData, 'Transfert');
                }
            },
        ]
        if (rowData.statut === "En attente") {
            if (this.appservice.getProfil() == 'Administrateur' || this.appservice.getProfil() == 'Inspecteur') {
                items.push(
                    {
                        label: 'Modifier',
                        icon: 'pi pi-pencil',
                        command: (e) => {
                            this.edit_data(rowData);
                        }
                    },
                    {
                        label: 'Supprimer',
                        icon: 'pi pi-times',
                        command: (e) => {
                            this.conf_delete_data(rowData);
                        }
                    },
                    {
                        label: 'Valider',
                        icon: 'pi pi-check',
                        command: (e) => {
                            this.setState({ selectedData: rowData, formData: rowData, visible_dlg_validation: true,pidCrd: rowData.id_crd_dest },()=>{
                                this.get_liste_ecole();
                            });
                        }
                    },
                    {
                        label: 'Rejeter',
                        icon: 'pi pi-ban',
                        command: (e) => {
                            this.setState({ selectedData: rowData });
                            var form = rowData;
                            form.statut = 'Rejeté';
                            form.date_statut = moment().format('YYYY-MM-DD');
                            this.setState({ formData: form }, () => {
                                this.enreg_data();
                            })
                        }
                    },
                )
            }
        } else if (rowData.statut === "Validé") {
            if (this.appservice.getProfil() == 'Gestionnaire' && ( rowData.id_insc_dest==null ||  rowData.id_insc_dest==0 ||  rowData.id_insc_dest=="")) {
                items.push(
                    {
                        label: 'Accepter',
                        icon: 'pi pi-pencil',
                        command: (e) => {
                            let form = this.state.formInsc;
                            form.eleveId = rowData.inscriptionId.eleveId;
                            form.ecoleId= {id: rowData.id_ecole_dest}
                            form.statut = 'P';
                            form.statut_academique="Normal";
                            form.annee_insc = this.appservice.get_selected_session();
                            form.iduser = this.appservice.get_userconnected().id;
                            form.photo = rowData.inscriptionId.photo;
                            this.setState({ selectedData: rowData,formInsc: form });
                            this.setState({ visible_dlg_insc: true });
                        }
                    },
                )
            }
            items.push(
                {
                    label: 'Récépissé orientation',
                    icon: 'pi pi-print',
                    command: (e) => {
                        this.conf_print_data(rowData, 'Orientation');
                    }
                },
            )
        }
        return <SplitButton label='Voir' icon="pi pi-eye" onClick={(e) => { this.edit_data(rowData); }} model={items} style={{ width: 115 }} />
    }

    // Permet d'afficher la liste des salariés sous forme de tableau lors de la sélection dans le formulaire
    itemTemplate(option) {
        if (!option.value) {
            return option.label;
        }
        else {
            return (
                <div className="grid" style={{ padding: 10 }}>
                    <div className="col-8">
                        <label>{option.label}</label>
                    </div>
                    <div className="col-4">
                        <label>{option.ecole}</label>
                    </div>
                </div>
            );
        }
    }

    /* Fonction de suppression de la ligne sélectionnée */
    delete_data() {
        if (this.state.selectedData == null || this.state.selectedData == undefined) {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner la ligne à supprimer' });
        } else {
            this.appservice.delete_transferts(this.state.selectedData).then(reponse => {
                this.resultat(reponse);
                if (reponse.success == true) {
                    this.get_liste_data();
                }
            });
        }
    }

    conf_delete_data(data) {
        if (data != null && data != undefined) {
            this.setState({ selectedData: data });
            this.setState({ visible: true });
        }
    }

    /* Affichage de la boite de dialogue pour l'ajout */
    toogleDlg() {
        this.annuler();
        this.setState({ visible_dlg_form: !this.state.visible_dlg_form });
    }

    /* Zone gauche de la toolbar */
    leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <Button label="Nouveau transfert" icon="pi pi-plus" className="p-button-success mr-2" onClick={this.toogleDlg} style={{ width: 170 }} />
                    <Button label="Supprimer" disabled={this.state.selectedData == null} icon="pi pi-trash" className="p-button-danger" onClick={(e) => this.conf_delete_data(this.state.selectedData)} style={{ width: 115 }} />
                </div>
            </React.Fragment>
        )

    }

    /* Zone droite de la toolbar */
    rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : <Button label="Export" icon="pi pi-download" className="p-button-help" onClick={this.exportCSV} />}
            </React.Fragment>
        )
    }

    /* Exportation du tableau contenant la liste des inscriptions en fichier CSV */
    exportCSV = () => {
        //ref={(el) => { this.dt = el; }}
        this.dt.exportCSV("Liste_transfert");
    }

    /* Exportation du tableau  en fichier PDF */
    exportPdf = () => {
        const cols = [
            { field: 'REFERENCE', header: 'REFERENCE' },
            { field: 'DATE', header: 'DATE' },
            { field: 'MOTIF', header: 'MOTIF' },
            { field: 'STATUT', header: 'STATUT' },
            { field: 'MATRICULE', header: 'MATRICULE' },
            { field: 'NOM', header: 'NOM' },
            { field: 'PRENOM', header: 'PRENOM' },
            { field: 'SEXE', header: 'SEXE' },
            { field: 'TELEPHONE', header: 'TELEPHONE' },
            { field: 'DATE_NAISS', header: 'DATE_NAISS' },
            { field: 'LIEU_NAISS', header: 'LIEU_NAISS' },
            { field: 'PERE', header: 'PERE' },
            { field: 'MERE', header: 'MERE' },
            { field: 'CLASSE', header: 'CLASSE' },
            { field: 'ECOLE', header: 'ECOLE' },
            { field: 'QUARTIER', header: 'QUARTIER' },
            { field: 'CRD', header: 'CRD' },
            { field: 'PREFECTURE', header: 'PREFECTURE' },
            { field: 'REGION', header: 'REGION' },
        ];
        let liste_data = this.state.liste_data.length > 0 ? this.state.liste_data.map(x => {
            return { 'REFERENCE': x.id, 'DATE': x.createdAt, 'MOTIF': x.motif, 'STATUT': x.statut, 'MATRICULE': x.inscriptionId.eleveId.matricule, 'NOM': x.inscriptionId.eleveId.nom, 'PRENOM': x.inscriptionId.eleveId.prenom, 'SEXE': x.inscriptionId.eleveId.sexe, 'TELEPHONE': x.inscriptionId.eleveId.telephone, 'DATE_NAISS': x.inscriptionId.eleveId.date_naiss, 'LIEU_NAISS': x.inscriptionId.eleveId.lieu_naiss, 'PERE': x.inscriptionId.eleveId.pere, 'MERE': x.inscriptionId.eleveId.mere, 'CLASSE': x.inscriptionId.classeId.libelle, 'ECOLE': x.inscriptionId.classeId.ecoleId.nom, 'QUARTIER': x.inscriptionId.classeId.ecoleId.quartierId.libelle, 'CRD': x.inscriptionId.classeId.ecoleId.quartierId.communeId.libelle, 'PREFECTURE': x.inscriptionId.classeId.ecoleId.quartierId.communeId.prefectureId.libelle, 'CRD': x.inscriptionId.classeId.ecoleId.quartierId.communeId.prefectureId.regionId.libelle }
        }) : [];
        const exportColumns = cols.map(col => ({ title: col.header, dataKey: col.field }));
        import('jspdf').then(jsPDF => {
            import('jspdf-autotable').then(() => {
                var doc = new jsPDF.default(0, 0);
                doc.autoTable(exportColumns, liste_data);
                doc.save('LISTE_TRANSFERT_.pdf');
            })
        })
    }

    /* Exportation du tableau  en fichier XLSX */
    exportExcel = () => {
        let liste_data = this.state.liste_data.length > 0 ? this.state.liste_data.map(x => {
            return { 'REFERENCE': x.id, 'DATE': x.createdAt, 'MOTIF': x.motif, 'STATUT': x.statut, 'MATRICULE': x.inscriptionId.eleveId.matricule, 'NOM': x.inscriptionId.eleveId.nom, 'PRENOM': x.inscriptionId.eleveId.prenom, 'SEXE': x.inscriptionId.eleveId.sexe, 'TELEPHONE': x.inscriptionId.eleveId.telephone, 'DATE_NAISS': x.inscriptionId.eleveId.date_naiss, 'LIEU_NAISS': x.inscriptionId.eleveId.lieu_naiss, 'PERE': x.inscriptionId.eleveId.pere, 'MERE': x.inscriptionId.eleveId.mere, 'CLASSE': x.inscriptionId.classeId.libelle, 'ECOLE': x.inscriptionId.classeId.ecoleId.nom, 'QUARTIER': x.inscriptionId.classeId.ecoleId.quartierId.libelle, 'CRD': x.inscriptionId.classeId.ecoleId.quartierId.communeId.libelle, 'PREFECTURE': x.inscriptionId.classeId.ecoleId.quartierId.communeId.prefectureId.libelle, 'CRD': x.inscriptionId.classeId.ecoleId.quartierId.communeId.prefectureId.regionId.libelle }
        }) : [];
        import('xlsx').then(xlsx => {
            const worksheet = xlsx.utils.json_to_sheet(liste_data);
            const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
            const excelBuffer = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
            this.saveAsExcelFile(excelBuffer, 'LISTE_TRANSFERT_');
        });
    }

    /* Enregistrer le fichier sur le client */
    saveAsExcelFile = (buffer, fileName) => {
        import('file-saver').then(module => {
            if (module && module.default) {
                let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                let EXCEL_EXTENSION = '.xlsx';
                const data = new Blob([buffer], {
                    type: EXCEL_TYPE
                });

                module.default.saveAs(data, fileName + new Date().getTime() + EXCEL_EXTENSION);
            }
        });
    }

    render() {
        const template = (options) => {
            const toggleIcon = options.collapsed ? 'pi pi-filter-fill' : 'pi pi-chevron-up';
            const className = `${options.className} justify-content-start`;
            const titleClassName = `${options.titleClassName} pl-1`;

            return (
                <a onClick={options.onTogglerClick} style={{ cursor: 'pointer' }}>
                    <div className={className}>
                        <button className={options.togglerClassName} onClick={options.onTogglerClick}>
                            <span className={toggleIcon}></span>
                            <Ripple />
                        </button>
                        <span className={titleClassName}>
                            Filtre avancé
                        </span>

                    </div>
                </a>
            )
        }
        const dialogFooter = (
            <div>
                <Button icon="pi pi-check" onClick={() => {
                    this.setState({ visible: false });
                    this.delete_data();
                }} label="Oui" />
                <Button icon="pi pi-times" onClick={() => this.setState({ visible: false })} label="Annuler" className="p-button-secondary" />
            </div>
        );
        return (
            <div className="card p-fluid col-12" style={{ height: 1200 }}>
                <div id="qr_code">
                </div>
                <Toast ref={(el) => this.toast = el} />
                <Dialog header="Confirmation" visible={this.state.visible} style={{ width: '250px' }} modal={true} footer={dialogFooter} onHide={() => this.setState({ visible: false })}>
                    <div>
                        <i className="fa fa-warning" style={{ color: '#FFD133', fontSize: 25 }}></i>
                        Voulez vous vraiment supprimer la ligne sélectionnée ?
                    </div>
                </Dialog>

                {/* Toolbar contenant les boutons d'ajout, de suppression et d'exportation */}
                {this.appservice.getProfil() == 'Administrateur' || this.appservice.getProfil() == 'Inspecteur'
                    ?
                    <Toolbar className="mb-4" left={this.leftToolbarTemplate} right={this.rightToolbarTemplate} style={{ paddingTop: 5, paddingBottom: 5 }} />
                    : null}


                <Panel headerTemplate={template} collapsed={true} toggleable style={{ marginBottom: 10 }}>
                    <div className="grid">
                        {this.appservice.get_userconnected().profil == 'Gestionnaire' ?
                            <>
                                <div className="col-6 md:col-3" >
                                    <span className="p-float-label" style={{ marginTop: 8 }}>
                                        <InputText onChange={(e) => this.setState({ pMatricule: e.target.value })} value={this.state.pMatricule} />
                                        <label htmlFor="pidCrd1" >Matricule ou Référence du transfert</label>
                                    </span>
                                </div>
                            </> :
                            <>
                                <div className="col-6 md:col-3" >
                                    <span className="p-float-label" style={{ marginTop: 8 }}>
                                        <Dropdown id='pidCrd1' options={[{ value: 'Tous', label: 'Tous' }].concat(this.state.liste_crd_srce)} placeholder="Sélectionner" value={this.state.pidCrd1} onChange={(e) => this.setState({ pidCrd1: e.value }, () => {
                                            //this.get_liste_ecole();
                                        })} autoWidth={false} style={{ width: '100%' }} filter={true} filterBy='label' filterMatchMode='contains' />
                                        <label htmlFor="pidCrd1" >DCE/DPE source</label>
                                    </span>
                                </div>
                                <div className="col-6 md:col-3" >
                                    <span className="p-float-label" style={{ marginTop: 8 }}>
                                        <Dropdown id='pidCrd1' options={[{ value: 'Tous', label: 'Tous' }].concat(this.state.liste_crd_dest)} placeholder="Sélectionner" value={this.state.pidCrd2} onChange={(e) => this.setState({ pidCrd2: e.value }, () => {
                                            //this.get_liste_ecole();
                                        })} autoWidth={false} style={{ width: '100%' }} filter={true} filterBy='label' filterMatchMode='contains' />
                                        <label htmlFor="pidCrd1" >DCE/DPE destination</label>
                                    </span>
                                </div>
                                <div className="col-6 md:col-3" >
                                    <span className="p-float-label" style={{ marginTop: 8 }}>
                                        <InputText onChange={(e) => this.setState({ pMatricule: e.target.value })} value={this.state.pMatricule} />
                                        <label htmlFor="pidCrd1" >Matricule ou réf. transfert</label>
                                    </span>
                                </div>
                            </>}

                        <div className="col-6 md:col-2" style={{ marginTop: 8 }}>
                            <SplitButton label="Rechercher" icon="pi pi-search" onClick={(e) => this.get_liste_data()} model={this.state.liste_item_export} />
                        </div>
                        <div className="col-6 md:col-1" style={{ marginTop: 8 }}>
                            {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                        </div>
                    </div>
                </Panel>

                {/* Tableau contenant la liste des inscriptions */}

                <DataTable ref={(el) => { this.dt = el; }} value={this.state.liste_data} paginatorPosition="bottom" selectionMode="single" paginator={true} rows={15}
                    alwaysShowPaginator={false} onRowClick={e => this.setState({ selectedData: e.data })} onRowSelect={e => this.setState({ selectedData: e.data })}
                    dataKey='id' responsiveLayout='scroll' style={{ width: '100%' }}
                    responsive={true} selection={this.state.selectedData} className="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-customers" >
                    <Column header="No" body={(rowData, props) => {
                        return <div>
                            <span >{props.rowIndex + 1}</span>
                        </div>
                    }} style={{ width: '3%' }} />
                    <Column header="Action" body={this.actionTemplate.bind(this)} style={{ width: '7%' }} />
                    <Column field="id" body={(rowData, props) => {
                        return <div>
                            <span style={{ color: '#D35400', fontWeight: 'bold' }}>No {rowData.id}</span><br />
                            <span >{moment(rowData.createdAt).format("DD/MM/YYYY")}</span>
                        </div>
                    }} header="Référence" style={{ width: '7%' }} filter={true} filterMatchMode='contains' />
                    <Column field="statut" header="Statut" style={{ width: '7%' }} filter={true} filterMatchMode='contains' />
                    <Column field="motif" header="Motif" filter={true} filterMatchMode='contains' />
                    <Column field="inscriptionId.eleveId.matricule" filter={true} filterMatchMode='contains' header="Matricule" style={{ width: '9%' }} />
                    <Column field="inscriptionId.eleveId.nom" filter={true} filterMatchMode='contains' header="Nom" style={{ width: '8%' }} />
                    <Column field="inscriptionId.eleveId.prenom" filter={true} filterMatchMode='contains' header="Prenom" style={{ width: '12%' }} />
                    <Column field="inscriptionId.classeId.libelle" filter={true} filterMatchMode='contains' header="Classe" style={{ width: '10%' }} />
                    <Column field="inscriptionId.ecoleId.nom" filter={true} filterMatchMode='contains' header="Ecole" style={{ width: '10%' }} />
                    <Column field="inscriptionId.ecoleId.quartierId.communeId.libelle" filter={true} filterMatchMode='contains' header="CRD" style={{ width: '10%' }} />
                </DataTable>


                {/* Boite de dialogue contenant le formulaire inscription  */}
                <Dialog visible={this.state.visible_dlg_insc} style={{ width: 450 }} header='Inscription' modal className="p-fluid" onHide={(e) => this.setState({ visible_dlg_insc: false })}
                    footer={<>
                        <Button label="Fermer" loading={this.state.showindicator} icon="pi pi-times" className="p-button-secondary" style={{ width: 120 }} onClick={(e) => this.setState({ visible_dlg_insc: false })} />
                        <Button label="Valider" loading={this.state.showindicator} icon="pi pi-check" onClick={(e) => this.accepter_transfert()} style={{ width: 120 }} />
                    </>}>
                    <div className="p-fluid">
                        <div className="field card" style={{ margin: 10, paddingBlock: 15, backgroundColor: '#e3edf8' }}>
                            <label htmlFor="email1">Classe *</label>
                            <Dropdown autoWidth={false} value={this.state.formInsc.classeId.id} filter={true} filterBy='label' filterMatchMode='contains'
                                options={this.state.liste_classe}
                                onChange={(e) => this.setState({ formInsc: this.appservice.onInputChange(e, 'classeId', 'fk', this.state.formInsc) })}
                                appendTo={document.body} className={classNames({ 'p-invalid': this.state.submit && !this.state.formInsc.classeId.id })} />
                            {this.state.submit && !this.state.formInsc.classeId.id && <small className="p-invalid" style={{ color: 'red' }}>La classe est obligatoire </small>}
                        </div>
                    </div>
                </Dialog>

                {/* Boite de dialogue contenant le formulaire inscription  */}
                <Dialog visible={this.state.visible_dlg_validation} style={{ width: 450 }} header='Validation' modal className="p-fluid" onHide={(e) => this.setState({ visible_dlg_validation: false })}
                    footer={<>
                        <Button label="Fermer" loading={this.state.showindicator} icon="pi pi-times" className="p-button-secondary" style={{ width: 120 }} onClick={(e) => this.setState({ visible_dlg_validation: false })} />
                        <Button label="Valider" loading={this.state.showindicator} icon="pi pi-check" onClick={(e) => {
                            if (this.state.formData.id_ecole_dest == 0) {
                                this.toast.show({ severity: 'warn', summary: 'Attention', detail: "Veuillez choisir l'école" });
                            } else {
                                var form = this.state.formData;
                                form.statut = 'Validé';
                                form.date_statut = moment().format('YYYY-MM-DD');
                                this.setState({ formData: form }, () => {
                                    this.enreg_data();
                                });
                            }
                        }} style={{ width: 120 }} />
                    </>}>
                    <div className='p-fluid formgrid grid card' style={{ margin: 10, paddingBlock: 15, backgroundColor: '#e3edf8' }}>
                        <div className="field col-3" style={{ margin: -2 }}>
                            <label>DCE/DPE *</label>
                        </div>
                        <div className="field col-9" >
                            <Dropdown id='pidCrd' disabled options={this.state.liste_crd_srce} value={this.state.pidCrd} onChange={(e) => this.setState({ pidCrd: e.value, liste_ecole: [] }, () => {
                                this.get_liste_ecole();
                            })} autoWidth={false} style={{ width: '100%' }} filter={true} filterBy='label' filterMatchMode='contains' />
                        </div>
                        <div className="field col-3" style={{ margin: -2 }}>
                            <label htmlFor="email1">Ecole *</label>
                        </div>
                        <div className="field col-9" >
                            <Dropdown autoWidth={false} value={this.state.formData.id_ecole_dest} filter={true} filterBy='label' filterMatchMode='contains'
                                options={this.state.liste_ecole}
                                onChange={(e) => this.setState({ formData: this.appservice.onInputChange(e, 'id_ecole_dest', '', this.state.formData) })}
                                appendTo={document.body} className={classNames({ 'p-invalid': this.state.submit && !this.state.formData.id_ecole_dest })} />
                            {this.state.submit && !this.state.formData.id_ecole_dest && <small className="p-invalid" style={{ color: 'red' }}>L'école est obligatoire </small>}
                        </div>

                    </div>
                </Dialog>

                {/* Boite de dialogue contenant l'historique  */}
                <Dialog visible={this.state.visible_dlg_detail} style={{ width: '70%' }} header='Parcours' modal className="p-fluid" onHide={(e) => this.setState({ visible_dlg_detail: false })}
                    footer={<>
                        <Button label="Fermer" loading={this.state.showindicator} icon="pi pi-times" className="p-button-secondary" style={{ width: 120 }} onClick={(e) => this.setState({ visible_dlg_detail: false })} />
                    </>}>
                    <div className="p-fluid" style={{ marginTop: -10, height: 400 }}>

                        <DataTable value={this.state.liste_parcours} paginatorPosition="bottom" paginator={true} rows={10}
                            alwaysShowPaginator={false} responsive={true} className="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-customers" >
                            <Column field="annee_insc" header='Session' style={{ width: '10%' }} />
                            <Column field="inscriptionId.classeId.libelle" filter={true} filterMatchMode='contains' header="Classe" style={{ width: '10%' }} />
                            <Column field="ecoleId.libelle" filter={true} filterMatchMode='contains' header="Ecole" />
                            <Column field="moy" filter={true} filterMatchMode='contains' header="Moy" style={{ width: '7%' }} />
                        </DataTable>
                    </div>
                </Dialog>


                {/* Boite de dialogue d'ajout et de modification d'un abonné  */}
                <Dialog visible={this.state.visible_dlg_form} style={{ width: '85%' }} header={this.state.modif_data == true ? "Modification" : "Ajout"} modal className="p-fluid" onHide={(e) => this.setState({ visible_dlg_form: false })}
                    footer={<>
                        <Button label="Fermer" loading={this.state.showindicator} icon="pi pi-times" className="p-button-secondary" style={{ width: 120 }} onClick={(e) => this.setState({ visible_dlg_form: false, pMatricule: '' })} />
                        {this.state.formData.statut == "En attente" ?
                            <Button label="Valider" loading={this.state.showindicator} icon="pi pi-check" onClick={(e) => this.enreg_data()} style={{ width: 120 }} /> : null}

                    </>}>
                    <div className="p-fluid">
                        <div className="grid ">
                            <div className="col-12 md:col-6" >
                                <TabView style={{ marginTop: -15 }}>
                                    <TabPanel header="Recherche">
                                        <div className='formgrid grid' style={{ backgroundColor: '#e3edf8', padding: 5, marginBottom: -10, borderBottom: "2px solid #142d5e", width: '105%', paddingRight: 10 }}>
                                            <div className="field col-3">
                                                <Dropdown autoWidth={false} value={this.state.rsession} filter={true} filterBy='label' filterMatchMode='contains' placeholder='Session'
                                                    options={this.appservice.get_liste_session_exam()} onChange={(e) => this.setState({ rsession: e.value }, () => this.get_liste_dce())} appendTo={document.body} />
                                            </div>
                                            <div className="field col-3">
                                                <Dropdown autoWidth={false} value={this.state.rdce} placeholder='DCE/DPE' filter={true} filterBy='label' filterMatchMode='contains'
                                                    options={this.state.liste_dce} onChange={(e) => this.setState({ rdce: e.value })} appendTo={document.body} />
                                            </div>
                                            <div className="field col-3">
                                                <InputText id="rpv" placeholder='PV ou matricule' value={this.state.pMatricule} onChange={(e) => this.setState({ pMatricule: e.target.value })} />
                                            </div>
                                            <div className="field col-3">
                                                <Button label="Rechercher" loading={this.state.showindicator} icon="pi pi-search" onClick={(e) => this.search()} style={{ width: 120 }} />
                                            </div>
                                        </div>


                                        {/*  <div className="p-inputgroup" style={{ marginTop: 5 }}>
                                            <span className="p-float-label">
                                                <InputMask autoFocus id="matricule" mask="99aaaa9999" value={this.state.pMatricule} onChange={(e) => this.setState({ pMatricule: e.target.value })} />
                                                <label htmlFor="matricule">Matricule *</label>
                                            </span>
                                            <Button icon="pi pi-search" label='Rechercher' loading={this.state.showindicator} onClick={(e) => this.search()} style={{ width: 200 }} />
                                        </div> */}


                                        <br />
                                        <div className="field grid">
                                            <div className="col-12 md:col-3">
                                                <div >
                                                    <a style={{ cursor: 'pointer' }}>
                                                        <div style={{ height: 110, width: 92, borderRadius: 10, marginTop: 4 }} >
                                                            <center>
                                                                {this.state.urlfile == null ?
                                                                    <div style={{ width: '92px', height: '110px', borderRadius: 10, backgroundColor: '#f7f7f7', display: 'flex', justifyContent: 'center', padding: 30, border: '1px solid #ccc' }} ><label style={{ color: '#003366', fontWeight: 'bold', cursor: 'pointer' }}>PHOTO</label></div> :
                                                                    <img src={this.state.urlfile} style={{ width: '92px', height: '110px', borderRadius: 10, backgroundColor: '#f7f7f7', border: '1px solid #003366' }} />}
                                                            </center>
                                                        </div>
                                                    </a>
                                                    <input type="file" id="hiddenFile" style={{ visibility: 'hidden' }} onChange={(e) => {
                                                        if (e.target.files && e.target.files[0]) {
                                                            this.setState({ file: e.target.files[0] }, () => {
                                                                let url = URL.createObjectURL(this.state.file);
                                                                this.setState({ urlfile: url });
                                                            })
                                                        }
                                                    }} />
                                                </div>
                                            </div>
                                            <div className="col-12 md:col-9 formgrid grid">
                                                <div className="field col-3" style={{ margin: -2 }}>
                                                    <label>Nom *</label>
                                                </div>
                                                <div className="field col-9" style={{ margin: -2 }}>
                                                    <label htmlFor="name3" style={{ color: '#A40C68', fontSize: 16, fontWeight: 'bold', backgroundColor: '#f7f7f7', padding: 3, minWidth: 300, minHeight: 33, borderRadius: 10 }}>{this.state.nom + " " + this.state.prenom}</label>
                                                </div>
                                                <div className="field col-3" style={{ margin: -2 }}>
                                                    <label>Naissance *</label>
                                                </div>
                                                <div className="field col-9" style={{ margin: -2 }}>
                                                    <label htmlFor="name3" style={{ color: '#A40C68', fontSize: 16, fontWeight: 'bold', backgroundColor: '#f7f7f7', padding: 3, minWidth: 300, minHeight: 33, borderRadius: 10 }}>{this.state.date_naiss + " " + this.state.lieu_naiss}</label>
                                                </div>
                                                <div className="field col-3" style={{ margin: -2 }}>
                                                    <label>Père *</label>
                                                </div>
                                                <div className="field col-9" style={{ margin: -2 }}>
                                                    <label htmlFor="name3" style={{ color: '#A40C68', fontSize: 16, fontWeight: 'bold', backgroundColor: '#f7f7f7', padding: 3, minWidth: 300, minHeight: 33, borderRadius: 10 }}>{this.state.pere}</label>
                                                </div>
                                                <div className="field col-3" style={{ margin: -2 }}>
                                                    <label>Mère *</label>
                                                </div>
                                                <div className="field col-9" style={{ margin: -2 }}>
                                                    <label htmlFor="name3" style={{ color: '#A40C68', fontSize: 16, fontWeight: 'bold', backgroundColor: '#f7f7f7', padding: 3, minWidth: 300, minHeight: 33, borderRadius: 10 }}>{this.state.mere}</label>
                                                </div>
                                            </div>

                                            <div className="col-12 md:col-3"></div>

                                            <div className="col-12 md:col-9 formgrid grid">
                                                <div className="field col-12" >
                                                    <hr style={{ margin: -3 }} />
                                                </div>
                                                <div className="field col-3" >
                                                    <label>Motif *</label>
                                                </div>
                                                <div className="field col-9" >
                                                    <InputTextarea rows={2} id="name" value={this.state.formData.motif} className={classNames({ 'p-invalid': this.state.submit && !this.state.formData.motif })}
                                                        onChange={(e) => this.setState({ formData: this.appservice.onInputChange(e, 'motif', '', this.state.formData) })}
                                                        required ref={(el) => { this.inputNom = el; }} disabled={this.state.formData.statut !== "En attente"} />
                                                    {this.state.submit && !this.state.formData.motif && <small className="p-invalid" style={{ color: 'red' }}>Le motif est obligatoire</small>}
                                                </div>
                                                <div className="field col-3" >
                                                    <label>Destination *</label>
                                                </div>
                                                <div className="field col-9" >
                                                    <Dropdown autoWidth={false} value={this.state.formData.communeId.id} filter={true} filterBy='label' filterMatchMode='contains'
                                                        options={this.state.liste_crd_dest} disabled={this.state.formData.statut !== "En attente"}
                                                        onChange={(e) => this.setState({ formData: this.appservice.onInputChange(e, 'communeId', 'fk', this.state.formData) })}
                                                        appendTo={document.body} className={classNames({ 'p-invalid': this.state.submit && !this.state.formData.communeId.id })} />
                                                    {this.state.submit && !this.state.formData.communeId.id && <small className="p-invalid" style={{ color: 'red' }}>La destination est obligatoire</small>}
                                                </div>
                                            </div>

                                        </div>
                                    </TabPanel>
                                </TabView>

                            </div>
                            <div className="col-12 md:col-6">
                                <TabView style={{ marginTop: -15 }}>
                                    <TabPanel header="Parcours">
                                        <DataTable value={this.state.liste_parcours} paginatorPosition="bottom" paginator={true} rows={3}
                                            alwaysShowPaginator={false} responsive={true} className="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-customers" >
                                            <Column field="annee_insc" header='Session' style={{ width: '18%' }} />
                                            <Column field="classeId.libelle" header="Classe" style={{ width: '30%' }} />
                                            <Column field="ecoleId.nom" header="Ecole" />
                                        </DataTable>
                                    </TabPanel>
                                </TabView>
                                {this.state.modif_data == true ?
                                    <TabView style={{ marginTop: 0 }}>
                                        <TabPanel header="Historique" style={{ marginTop: 20 }}>
                                            <DataTable value={this.state.liste_trace} paginatorPosition="bottom" paginator={true} rows={3}
                                                responsive={true} style={{ marginTop: -30 }} className="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-customers"
                                                alwaysShowPaginator={false} >
                                                <Column field="createdAt" body={(rowData, column) => {
                                                    return <div>
                                                        <label style={{ color: '#D35400', fontWeight: 'bold' }}>
                                                            {rowData.action}
                                                        </label><br />
                                                        <span >
                                                            {moment(rowData.createdAt).format("DD/MM/YYYY HH:mm:ss")}
                                                        </span>
                                                    </div>
                                                }} header="Date" style={{ width: '28%' }} />
                                                <Column field="detail" header="Détail" />
                                                <Column field="utilisateurId.login" header="Login" style={{ width: '20%' }} />

                                            </DataTable>
                                        </TabPanel>
                                    </TabView>
                                    : null}

                            </div>
                        </div>

                    </div>
                </Dialog>
            </div>
        );
    }
}